
import Dashboard from "views/Dashboard.js";
import Adder from "views/Adder.js";
import AdderAdmin from "views/AdderAdmin.js";
import AddRemove from "views/AddRemove.js";
import ChannelUsersAdder from "views/ChannelUsersAdder.js";
import ChannelUsersMessenger from "views/ChannelUsersMessenger.js";
import SingleUsersMessenger from "views/SingleUserMessenger.js";
import UserStatusFollowup from "views/UsersStatusFollowup.js";
import Notifications from "views/Notifications.js";
import Icons from "views/Icons.js";
import Typography from "views/Typography.js";
import TableList from "views/Tables.js";
import Maps from "views/Map.js";
import UserPage from "views/User.js";
import SessionReg from "views/SessionReg.js";
import JALLambda from "views/JALLambda";
import JALLambdaBeta from "views/JALLambdaBeta";
import FolderJAL from "views/FolderJAL";
import Wallet from "views/Wallet";
import JALLambdaAdmin from "views/JALLambdaAdmin";
import UpgradeToPro from "views/Upgrade.js";
import messenger from "views/messenger.js";
import Scraper from "views/Scraper.js";
import GroupCreator from "views/GroupCreator.js";
import reverse_logo from "assets/img/icon1.png";
import user_df_logo from "assets/img/icon2.png";
import user_profile from "assets/img/UserPro.png";
var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: " header-dashboard-icon",
    component: Dashboard,
    layout: "",
    access:"adder",
  },
  {
    path: "/wallet",
    name: "Wallet",
    icon: "header-dashboard-icon",
    component: Wallet,
    layout: "",
    access:"wallet",
  },
  {
    path: "/reg_session",
    name: "Sessions Reg",
    icon: " header-dashboard-icon",
    component: SessionReg,
    layout: "",
    access:"manager",
  },
  {
    path: "/add_or_remove",
    name: "Join and Leave",
    icon: " header-join-icon",
    component: AddRemove,
    layout: "",
    access:"manager",
  },
  {
    path: "/admin_jal_lambda",
    name: "JAL Lambda Admin",
    icon: "header-dashboard-icon",
    component: JALLambdaAdmin,
    layout: "",
    access:"manager",
  },
  {
    path: "/beta_jal_lambda",
    name: "Beta JAL Lambda",
    icon: "header-join-icon",
    component: JALLambdaBeta,
    layout: "",
    access:"manager",
  },
  {
    path: "/jal_lambda",
    name: "JAL Lambda",
    icon: "header-join-icon",
    component: JALLambda,
    layout: "",
    access:"jal_lambda",
  },
  {
    path: "/folder_jal",
    name: "Folder JAL",
    icon: "header-join-icon",
    component: FolderJAL,
    layout: "",
    access:"folder_jal",
  },
  {
    path: "/user_scraper",
    name: "Fetch User",
    icon: "  header-fetchuser-icon",
    component: Scraper,
    layout: "",
    access:"scraper",
  },
  {
    path: "/adder_admin",
    name: "Adder Admin",
    icon: "header-dashboard-icon",
    component: AdderAdmin,
    layout: "",
    access:"manager",
  },
  {
    path: "/Adder",
    name: "Adder",
    icon: " header-adduser-icon",
    component: Adder,
    layout: "",
    access:"adder",
  },
  {
    path: "/channel_users_adder",
    name: "Channel Users Adder",
    icon: " header-adduser-icon",
    component: ChannelUsersAdder,
    layout: "",
    access:"channel_users_adder",
  },
  {
    path: "/online_users_followup",
    name: "Online Users Followup",
    icon: " header-adduser-icon",
    component: UserStatusFollowup,
    layout: "",
    access:"online_users_followup",
  },
  {
    path: "/channel_users_messenger",
    name: "Channel Users Messenger",
    icon: " header-messenger-icon",
    component: ChannelUsersMessenger,
    layout: "",
    access:"channel_users_messenger",
  },
  {
    path: "/single_user_messenger",
    name: "Single User Messenger",
    icon: " header-messenger-icon",
    component: SingleUsersMessenger,
    layout: "",
    access:"single_user_messenger",
  },
  {
    path: "/messenger",
    name: "Messenger",
    icon: " header-messenger-icon",
    component: messenger,
    layout: "",
    access:"messenger",
  },
  {
    path: "/user-page",
    name: "User Profile",
    icon: " header-flag-icon",
    component: UserPage,
    layout: "",
    access:"user",
  },
  {
    path: "/group-creator",
    name: "Create Group",
    icon: "header-flag-icon",
    component: GroupCreator,
    layout: "",
    access:"group_creator",
  },
];
export default routes;
