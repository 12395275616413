import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import routes from "routes.js";

const PrivateRoute = ({ component: Component, auth, ...rest }) => (
  
  <Route
    {...rest}
    render={props => {
      console.log(props,auth,'props.routes')
      console.log(window.location.href)
      let route = routes.find(el => el.path === props.location.pathname);
      if (auth.isLoading) {
        return <h2>Loading</h2>;
      }
      
      
       else if (!auth.isAuthenticated) {
        return <Redirect to="/accounts/login" />;
      } else {
        try{
          console.log(props.location.pathname)
          
          console.log(auth.user.group)
          console.log(route.access)
          if (auth.user.group.includes(route.access)){
            return <Component {...props} />;
         }
         else{
           return <Redirect to="/error/400" />;
 
         }
        }
        catch{
          return <Redirect to="/error/400" />;
        }
         
      }
    }}
  />
);

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  null,
  null,
  { pure: false }
)(PrivateRoute);
