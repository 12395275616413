import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { registerUser } from "../../actions/auth";

class Unauthorized extends Component {

  static propTypes = {};

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };


  render() {
    return(
    <>
    404
    </>

    )
  }
}


export default Unauthorized;
