import React from 'react'
// import styled from 'styled-components'
import { useTable, usePagination } from 'react-table'
import PerfectScrollbar from 'react-perfect-scrollbar'


// Let's add a fetchData method to our Table component that will be used to fetch
// new data when pagination state changes
// We can also add a loading state to let our table know it's loading new data



function Table({
  columns,
  setColumns,
  data,
  fetchData,
  loading,
  pageCount: controlledPageCount,
  search,
  setSearch,
  autoFetch,
  setAutoFetch
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    allColumns,
    getToggleHideAllColumnsProps,

    // Get the state from the instance
    state: { pageIndex, pageSize},
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0}, // Pass our hoisted table state
      manualPagination: true,
      defaultCanSort:true,
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: controlledPageCount,
    },
    usePagination
  )

  // Listen for changes in pagination and use the state to fetch our new data
  React.useEffect(() => {
    fetchData({ pageIndex, pageSize,search,columns })

      
    //  const autoRefresh= setInterval(() => {
    //   if (autoFetch!=0){

    //     fetchData({ pageIndex, pageSize,search,columns })
    //   }
  
    //   }, 10000);
    //   return () => clearInterval(autoRefresh);
  }, [fetchData, pageIndex, pageSize,search,columns,autoFetch])
  // Render the UI for your table
  function sortColumn (index,Sorted){
    if (columns[index].isSorted===false){
        columns[index].isSorted=true
    }
    columns[index].isSortedDesc=!columns[index].isSortedDesc
    setColumns([...columns])
    

  }
  return (
    <>
    <div className='card bg-darker '>
    <div className='card-body'>
    <PerfectScrollbar>
      

      
      <table  className='table table-sm    table-dark table-hover  table-striped bg-darker  no-border' style={{'overflow':'auto'}} {...getTableProps()}>
        <thead  className="info ">
          {headerGroups.map(headerGroup => (
            <tr  {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column,index) => (
                <th className={column.isSorted?'':''} onClick={() => {
                  if (column.canSort){sortColumn(index,!column.isSorted)}
                  }} style={{cursor:'pointer'}} {...column.getHeaderProps()}>

                  {column.render('Header')}


                  <span className="mx-1 " style={{background:"transparent"}} onClick={() => sortColumn(index,!column.isSorted)} >
                    {column.canSort?column.isSorted?column.isSortedDesc
                        ? <i className="bi bi-arrow-down " ></i>
                        : <i className="bi bi-arrow-up" ></i>:<i className="bi bi-arrow-down-up " ></i>:''
                        }
                  </span>
                  
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {/* {loading ?'Fetching...': ( */}
          
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (

              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td  {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
          
        </tbody>
        {/* )} */}
      </table>

      </PerfectScrollbar>
      <div className='row '>
        <div className="col-6 ">
                  {loading?<div>
              <span className="spinner-border spinner-border-sm"></span>
              Loading..
                  </div>:''}
                {/* <OverlayTrigger
                  trigger="click"
                  key={'right'}
                  placement={'right'}
                  overlay={
                    <Popover id={`popover-positioned-${'right'}`}>
                      <Popover.Title as="h3">Column Hide/Show</Popover.Title>
                      <Popover.Content>
                      <div style={{height:"400px",overflow:'auto'}}>
                          {allColumns.map(column => (
                                <div  key={column.id}>
                                  <label>
                                    <input type="checkbox" {...column.getToggleHiddenProps()} />{' '}
                                    {column.id}
                                  </label>
                                </div>
                              ))}
                        </div>
                      </Popover.Content>
                    </Popover>
                  }
                >
                  <Button variant="success">Column Visibility </Button>
                </OverlayTrigger> */}
        </div>
        <div className="col-6">
          
        <input 
            type="text"
            className='form-control  searchedit input sm float-right m-3 '
            value={search}
            style={{ width: '200px' }}
            onChange={e => 
            { 

              console.log(e.target.value)
              setSearch(e.target.value)
              gotoPage(0)

            }} 
            placeholder="Search"/>
        </div>
    </div>
    </div>
      </div>

      <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12  m-1">
          {loading ? (
              // Use our custom loading state to show a loading indicator
              <td colSpan="10000">Loading...</td>
            ) : (
              <td colSpan="10000" className='text-muted'>
                Showing {page.length} of {controlledPageCount * pageSize}{' '}
                results
              </td>
            )}
          </div>
          <div className='col-lg-8 col-md-8 col-sm-12  '>
            <ul className="pagination ">
              <span className='font-small-1 my-3'>
                Page{' '}
                <strong>
                {pageIndex + 1} of {pageOptions.length}
                </strong>{' '}
                </span>
                <li className="page-item"> <button className=' btn btn-outline-green  p-1 bg-transparent green mx-1 bold-700 square border-green border-4' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {'<<'}
                </button></li>
                <li className="page-item"> <button className=' btn btn-outline-green  p-1 bg-transparent green mx-1 bold-700 square border-green border-4 ' onClick={() => previousPage()} disabled={!canPreviousPage}>
                {'<'}
                </button></li>                
                <li className="page-item"> <button className=' btn btn-outline-green  p-1 bg-transparent green mx-1 bold-700 square border-green border-4 ' onClick={() => nextPage()} disabled={!canNextPage}>
                {'>'}
                </button></li>
                <li className="page-item"> <button className=' btn btn-outline-green  p-1 bg-transparent green mx-1 bold-700 square border-green border-4 ' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                {'>>'}
                </button></li>
            </ul>
          </div>

        
          <div className='col-lg-4 col-xl-4 col-md-12 col-sm-12  '>
                <div className='row my-3 ml-1 p-1  float-right'>
              
                    <label className="col-form-label font-small-3 col-4 mr-2 float-left">Go to Page</label>
                    <input

                        className="ml-0 col-sm-7 col-md-7 col-lg-7 col-xl-7 square form-control "
                        type="number"
                        style={{'height':'30px'}}
                        placeholder="Go to page:"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                        const page = e.target.value ? Number(e.target.value) - 1 : 0
                        gotoPage(page)
                        }}
                        onKeyUp={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(page)
                        }}
                        
                    />
                    <label className="col-form-label font-small-3 mr-2 col-4 mt-2 float-left">Show Pages </label>

                      <select
                          style={{ width: '100px','height':'30px' }}

                          className="m-0 input-xs form-control square  col-sm-7 col-md-7 col-lg-7 col-xl-7 form-select w-100 my-3"
                          value={pageSize}
                          onChange={e => {
                              setPageSize(Number(e.target.value))
                          }}
                          >
                          {[10, 20, 30, 40, 50].map(pageSize => (
                              <option key={pageSize} value={pageSize}>
                              Show {pageSize}
                              
                              </option>
                          ))}
                      
                  </select>
                </div>
          </div>

        

        
        
        
      </div>
      
    </>
  )
}

export default Table
