import axios from "axios.js";
import React from "react";
import { connect } from "react-redux";
import Select from 'react-select'
import VisibilitySensor from 'react-visibility-sensor';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import JALLambdaOrderTable from './JALLambdaOrderTable'
import JALLambdaChannelTable from './JALLambdaChannelTable'
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
    Button,
} from "reactstrap";

const MySwal = withReactContent(Swal)

// Core Component
class JALLambda extends React.Component {
    constructor() {
        super()
        this.state = {
            action: 'join',
            new_channel: true,
            form_load: true,
            target_url: null,
            loading_urls: false,
            selected_category: 0,
            spam: 'No',
            proxy: true,
            max_user: null,
            scheduled_request: false,
            emergency_request: false,
            skip_views: false,
            join_via_search: false,
            premium: false,
            custom_search_keyword: false,
            search_keyword: null,
            scheduled_days: null,
            scheduled_hours: null,
            scheduled_mins: null,
            status_running: false,
            status_scheduled: false,
            status: null,
            hold_join_btn: false,
            hold_leave_btn: false,
            hold_stop_btn: false,
            hold_restart_btn: false,
            counts_loading: false,
            active_session: 0,
            category_count_load: {},
            active_session_cat: 0,
            statistics_counts: true,
            added_count: 0,
            removed_count: 0,
            reported_count: 0,
            bot_started_count: 0,
            bot_interacted_count: 0,
            bot_reported_count: 0,
            boosted_count: 0,
            counts_visibility: false,
            channel_counts_visibility: false,
            channel_counts_loading: false,
            channel_lists_loading: false,

            selected_channel: 0,
            selected_country: [],

            categories: {},
            price_charts: {},
            price_plan: {},
            possible_actions: {},
            channels: [],

            history_visibility: null,
            channel_history: null,
            order_history: null,
            loading_history: false,
            history_type: 'order_based',
            history_ago: 0,

            tt_active_users: '',
            tt_channel_history: '',
            tt_order_history: '',
            tt_channel_counts: '',
            running_req_list: {},
            sel_running_req: null,
            tt_running_req_list: '',
            running_req_list_load: false,
        }
    }

    countries = () => {
        return [
            {label:'ru',value:'ru'}, {label:'kz',value:'kz'}, {label:'ua',value:'ua'},
            {label:'ro',value:'ro'}, {label:'uk',value:'uk'}, {label:'ar',value:'ar'},
            {label:'br',value:'br'}, {label:'vn',value:'vn'}, {label:'de',value:'de'},
            {label:'ge',value:'ge'}, {label:'eg',value:'eg'}, {label:'il',value:'il'},
            {label:'id',value:'id'}, {label:'es',value:'es'}, {label:'kh',value:'kh'},
            {label:'ca_v',value:'ca_v'}, {label:'ke',value:'ke'}, {label:'co',value:'co'},
            {label:'lv',value:'lv'}, {label:'lt',value:'lt'}, {label:'my',value:'my'},
            {label:'mx',value:'mx'}, {label:'nl',value:'nl'}, {label:'nz',value:'nz'},
            {label:'py',value:'py'}, {label:'pl',value:'pl'}, {label:'pt',value:'pt'},
            {label:'us',value:'us'}, {label:'ph',value:'ph'}, {label:'fr',value:'fr'},
            {label:'hr',value:'hr'}, {label:'se',value:'se'}, {label:'ee',value:'ee'},
        ]
    }

    make_call(request_event, data={}) {
        axios.defaults.withCredentials = false;
        return axios({
            url: `https://993ibru2ff.execute-api.ap-south-1.amazonaws.com/api/${request_event}`,
            method: 'POST',
            data: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'X-Api-Key': localStorage.getItem('jal_api_key')
            },
        })
    }

    getChannels = () => {
        this.setState({channel_lists_loading: true})
        this.make_call("get_channels").then((response) => {
            this.setState({channels: response.data, channel_lists_loading: false})
        })
    }

    getCategories() {
        this.make_call("get_categories").then((response) => {
            this.setState({categories: response.data})
        })
    }

    update_price_plan(price_plan_id) {
        this.setState({
            skip_views: !this.state.price_charts[this.state.action][price_plan_id]['with_views'],
            premium: this.state.price_charts[this.state.action][price_plan_id]['with_premium'],
            join_via_search: this.state.price_charts[this.state.action][price_plan_id]['via_search'],
            price_plan: this.state.price_charts[this.state.action][price_plan_id]
        }, () => {console.log(this.state.price_plan)})
    }

    getPlans() {
        this.make_call("get_price_chart").then((response) => {
            const names = {};
            for (const key in response.data['data']) {
                for (const innerKey in response.data['data'][key]) {
                    names[key] = response.data['data'][key][innerKey]['action_name'];
                    break; // Stop inner iteration after processing the first element
                }
            }
            console.log("Possible Actions :", names)
            this.setState({price_charts: response.data['data'], possible_actions: names})
        })
    }

    getActiveSessions = () => {
        this.setState({counts_loading: true})
        this.make_call("get_category_sessions").then((response) => {
            this.setState({active_session: response.data.count, active_session_cat: response.data.categories, tt_active_users: new Date(), counts_loading: false})
        })
    }

    getChannelCount = (warn=false) => {
        if ((this.state.new_channel || !this.state.target_url)){
            if (warn) {
                Swal.fire("Select Channel", "Please Select Channel to Get Count", )
            }
        } else if (this.state.target_url){
            this.setState({channel_counts_loading: true})
            this.make_call("get_channel_count", {"target_url": this.state.target_url}).then((response) => {
                if (response.data) {
                    this.setState({
                        added_count: response.data["joined"],
                        removed_count: response.data["left"],
                        reported_count: response.data["report"],
                        bot_started_count: response.data["bot_start"],
                        bot_interacted_count: response.data["bot_interact"],
                        bot_reported_count: response.data["bot_report"],
                        boosted_count: response.data["boost"],
                        status_running: response.data["status"],
                        status_scheduled: response.data["scheduled"],
                        tt_channel_counts: new Date(),
                        channel_counts_loading: false,
                    })
                }
            })
        }
    }

    getRunningRequests = () => {
        this.setState({running_req_list_load: true})
        this.make_call("get_scheduled_request", {}).then((response) => {
            if (response.data) {
                this.setState({
                    running_req_list: response.data,
                    running_req_list_load: false,
                    sel_running_req: '',
                    tt_running_req_list: new Date(),
                })
            }
        })
    }


    get_history = (history_type) => {
        if (history_type == '') {
            Swal.fire("History Type", "History Type is not yet selected Please select one")
        }
        else{
            this.setState({loading_history: true})
            if (history_type == 'channel_based') {
                var data = {
                    "detailed": true,
                    "order_based": false,
                    "days_ago": this.state.history_ago
                }
            } else if (history_type == 'order_based') {
                var data = {
                    "detailed": false,
                    "order_based": true,
                    "days_ago": this.state.history_ago
                }
            }
            this.make_call("get_statistics", data).then((response) => {
                if (history_type == 'channel_based') {
                    this.setState({channel_history: response.data, tt_channel_history: new Date()})
                } else if (history_type == 'order_based') {
                    this.setState({order_history: response.data, tt_order_history: new Date()})
                }
                this.setState({loading_history: false})
            })
        }
    }

    stop_process = (request_id=null) => {
        this.setState({form_load: true})
        if (request_id){
            var data = {
                request_id: request_id,
                auto_leave: true
            }
        } else {
            var data = {
                target_url: this.state.target_url
            }
        }
        this.make_call("stop_request", data).then((response) => {
            this.getRunningRequests()
            this.getChannels()
            Swal.fire("Status", response.data["message"])
            this.setState({form_load: false}, () => {this.getChannelCount(false)})
        })
    }

    start_immediate = (request_id) => {
        Swal.fire({
            title: "Only for Admins ⚠️",
            html: `Please Make Sure that you are Admin,
            <br/><b> This will schedule one of the running request and start the selected one now.<b>`,
            showDenyButton: true,
            confirmButtonText: `Confirm, Start this Now`,
            denyButtonText: "Cancel",
            icon: "warning",
        }).then((result) => {
            if (result.isConfirmed){
                this.setState({form_load: true})
                this.make_call("admin/start_immediate", {request_id: request_id}).then((response) => {
                    this.getRunningRequests()
                    this.getChannels()
                    Swal.fire("Status", response.data["message"])
                    this.setState({form_load: false})
                })
            }
        })
    }

    client_request = () => {
        this.setState({form_load: true})
        this.make_call("client_request", {
            category: this.state.selected_category,
            target_url: this.state.target_url,
            max_user: this.state.max_user,
            spam: this.state.spam,
            proxy: this.state.proxy,
            countries: this.selctionsToList(this.state.selected_country),
            scheduled_request: this.state.scheduled_request,
            emergency_request: this.state.emergency_request,
            price_chart: this.state.price_plan["price_id"],
            search_keyword: this.state.search_keyword,
            days: this.state.scheduled_days,
            hours: this.state.scheduled_hours,
            minutes: this.state.scheduled_mins,
        }).then((response) => {
            this.getChannelCount(false)
            this.getChannels()
            Swal.fire("Status", response.data["message"])
            this.setState({new_channel: false, form_load: false})
        })
    }

    validate_private_link = (request_func) => {
        if (this.state.target_url.startsWith("https://t.me/+")) {
            this.setState({target_url: this.state.target_url.replace("https://t.me/+", "https://t.me/joinchat/")}, request_func)
        } else {
            request_func()
        }
    }

    get_plan_option = (key) => {
        if (this.state.action && (this.state.price_charts[this.state.action][key]["category_ids"].includes(this.state.selected_category))) {
            return <option value={this.state.price_charts[this.state.action][key]["price_id"]}>{this.state.price_charts[this.state.action][key]["price"]?`${this.state.price_charts[this.state.action][key]["price"]}$ / ${this.state.price_charts[this.state.action][key]["count"]}`:"Free"} - {this.state.price_charts[this.state.action][key]["with_premium"]?"Premium Accounts":"Regular Accounts"}{(this.state.price_charts[this.state.action][key]["days"] || this.state.price_charts[this.state.action][key]["hours"] || this.state.price_charts[this.state.action][key]["minutes"])?` - ${this.state.price_charts[this.state.action][key]["days"]?this.state.price_charts[this.state.action][key]["days"]+" Day(s)":""} ${this.state.price_charts[this.state.action][key]["hours"]?this.state.price_charts[this.state.action][key]["hours"]+" Hour(s)":""} ${this.state.price_charts[this.state.action][key]["minutes"]?this.state.price_charts[this.state.action][key]["minutes"]+" Minute(s)":""}`:''}{this.state.price_charts[this.state.action][key]["via_search"]?" - Via Search":""}{this.state.price_charts[this.state.action][key]["with_views"]?" - Enable Views":""}{this.state.price_charts[this.state.action][key]["is_offer"]?` - In Offer 🎉 (Actual Price is ${this.state.price_charts[this.state.action][key]["non_offer_price"]}$)`:""}{this.state.price_charts[this.state.action][key]["only_for_admins"]?" - Only For Admins":""}</option>
        } else {
            return <></>
        }
    }

    validate_url = (request_func) => {
        if (this.state.target_url.startsWith("@")) {
            this.setState({target_url: this.state.target_url.replace("@", "https://t.me/")}, () => {this.validate_private_link(request_func)})
        } else if (this.state.target_url.startsWith("t.me/")) {
            this.setState({target_url: this.state.target_url.replace("t.me/", "https://t.me/")}, () => {this.validate_private_link(request_func)})
        } else if (this.state.target_url.startsWith("http://t.me/")) {
            this.setState({target_url: this.state.target_url.replace("http://t.me/", "https://t.me/")}, () => {this.validate_private_link(request_func)})
        } else if (!this.state.target_url.startsWith("https://t.me/")){
            Swal.fire("Improper Format", "The Target URL is not matched with any supported format (https://t.me/channel or https://t.me/channel or @channel)")
            return
        } else {
            this.validate_private_link(request_func)
        }
    }

    selctionsToList = (data) => {
        let countries_list = [];
        for (var i = 0; i < data.length; i++){
            countries_list.push(data[i]["value"])
        }
        return countries_list
    }

    isChannelHistory() {
        return (this.state.history_type == 'channel_based') && this.state.channel_history
    }
    isOrderHistory() {
        return (this.state.history_type == 'order_based') && this.state.order_history
    }



    // testCall() {
    //     this.getActiveSessions()
    // }

    componentDidMount = () => {
        this.getChannels()
        this.getCategories()
        this.getPlans()
        this.getActiveSessions()
        this.getRunningRequests()
        this.setState({form_load: false})
        // $(document).ready(function(){
        //     $('.search_select_box select').selectpicker()
        // })
        // this.input_interval = setInterval(() => console.log(this.state), 3000);
    }

    componentWillUnmount() {
        // clearInterval(this.input_interval);
    }

    checkBoolVal(cond) {
        if (cond == 'true'){
            return true
        } else if (cond == 'false') {
            return false
        } else {
            return undefined
        }

    }

    notificationAlert = React.createRef();

    render() {
        return (<>
            <div className="content">
                <VisibilitySensor partialVisibility onChange={(isVisible) => {this.setState({counts_visibility: isVisible})}}><>
                    <Card className="card-stats">
                        <CardBody>
                            <Row>
                                <Col lg="4" md="4" sm="4">
                                    <Card className="card-stats card border-green joinleave">
                                        <CardBody>
                                            <Row>
                                                <Col md="3" xs="3">
                                                    <div className="icon-big text-center icon-warning">
                                                    <a><img className="join-settings-icon" /></a>
                                                    </div>
                                                </Col>
                                                <Col md="9" xs="9">
                                                    <div className="numbers text-center my-auto">
                                                        <p className="card-category">Active Users</p>
                                                        <CardTitle className="tle-actve" tag="p">{this.state.counts_loading?<i className='fa fa-refresh icon-spin'></i>:this.state.active_session}</CardTitle>
                                                        <p />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                        <CardFooter>
                                            <hr />
                                            <div className="stats text-center">
                                                <i className="sas" />
                                            </div>
                                        </CardFooter>
                                    </Card>
                                </Col>
                                <Col lg="4" md="4" sm="4">
                                    <Card className="card-stats card border-green joinleave">
                                        <CardBody>
                                            <Row>
                                                <Col md="3" xs="3">
                                                    <div className="icon-big text-center icon-warning">
                                                        <a><img className="join-settings-icon" /></a>
                                                    </div>
                                                </Col>
                                                <Col md="9" xs="9">
                                                    <div className="numbers">
                                                        <p className="card-category text-center">Active Users In {this.state.active_session_cat[this.state.selected_category]?this.state.active_session_cat[this.state.selected_category]["category"]:''} Category</p>
                                                        <CardTitle className="tle-actve" tag="p">{this.state.counts_loading?<i className='fa fa-refresh icon-spin'></i>:this.state.active_session_cat[this.state.selected_category]?this.state.active_session_cat[this.state.selected_category]["count"]:0}</CardTitle>
                                                        <p />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                        <CardFooter>
                                            <hr />
                                            <div className="stats text-center">
                                                <i className="sas" />
                                            </div>
                                        </CardFooter>
                                    </Card>
                                </Col>
                                <Col lg="4" md="4" sm="4">
                                    <Card className="card-stats card border-green joinleave">
                                        <CardBody>
                                            <Row>
                                                <Col md="3" xs="3">
                                                    <div className="icon-big text-center icon-warning">
                                                        <a><img className="join-settings-icon" /></a>
                                                    </div>
                                                </Col>
                                                <Col md="9" xs="9">
                                                    <div className="numbers">
                                                        <p className="card-category text-center">Active Premium Users In {this.state.active_session_cat[this.state.selected_category+"-premium"]?this.state.active_session_cat[this.state.selected_category+"-premium"]["category"]:''} Category</p>
                                                        <CardTitle className="tle-actve" tag="p">{this.state.counts_loading?<i className='fa fa-refresh icon-spin'></i>:this.state.active_session_cat[this.state.selected_category+"-premium"]?this.state.active_session_cat[this.state.selected_category+"-premium"]["count"]:0}</CardTitle>
                                                        <p />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                        <CardFooter>
                                            <hr />
                                            <div className="stats text-center">
                                                <i className="sas" />
                                            </div>
                                        </CardFooter>
                                    </Card>
                                </Col>
                            </Row>
                            </CardBody>
                            <CardTitle>
                                <Button disabled={true} className="btn btn-purple float-left ml-3">Updated on : {this.state.tt_active_users?this.state.tt_active_users.toUTCString():''}</Button>
                                <Button onClick={this.getActiveSessions} disabled={this.state.counts_loading} className="btn btn-outline-green float-right mr-3">{this.state.tt_active_users?<>Reload Count <i className={`fa fa-refresh ${this.state.counts_loading?'icon-spin':''} `}></i></>:<>Get Count</>}</Button>
                            </CardTitle>
                        </Card>
                </></VisibilitySensor>

                <Row>
                    <Col className="mx-auto" lg="12" md="12" sm="12">
                        <Card className="card-stats border-info">
                            <CardBody className="p-3"><>
                                <Row>
                                    <Col className="mx-auto" lg="8" md="8" sm="12">
                                        <Row className="my-2">
                                            <Col className="mx-auto" lg="6" md="6" sm="6"><button disabled={this.state.form_load} onClick={() => {if(!this.state.new_channel){this.setState({status_running: false, status_scheduled: false, target_url: null})}; this.setState({new_channel: !this.state.new_channel})}} className={`btn btn-lg btn-info col-sm-12 col-lg-12 col-12 bg-${this.state.new_channel?"blue":"green"}`}> <a><img className={`${this.state.new_channel?"join-start":"header-adduser"}-icon`} /></a> {this.state.new_channel?"Select":"New"} Channel </button></Col>
                                            {!this.state.new_channel?
                                                <Col className="mx-auto" lg="6" md="6" sm="6"><button disabled={this.state.form_load} onClick={() => this.getChannels()} className="btn btn-lg btn-info col-sm-12 col-lg-12 col-12 bg-green"><i className={`fa fa-refresh ${this.state.channel_lists_loading?'icon-spin':''} `}></i> Reload Channels </button></Col>
                                            :
                                                <></>}
                                        </Row>
                                        {this.state.form_load?<div id="loader"></div>:<></>}
                                        <div className="my-3">
                                            {this.state.new_channel?
                                                <>
                                                    <label className="labelfont">Enter the group / channel / bot link:</label>
                                                    <input type="url" className='form-control square col-sm-12 col-lg-12 col-12 my-2 m-1' readOnly={this.state.form_load} value={this.state.target_url} onChange={(e) => { this.setState({ target_url: e.target.value }) }} placeholder="Insert the Group to be added" />
                                                </>
                                                :
                                                <>
                                                    
                                                    <label className="my-2">Select Channel:</label> {this.state.loading_urls?<i className='fa fa-refresh icon-spin'></i>:''}
                                                    <Select className='black my-2' disabled={this.state.form_load} onChange={(value) => {this.setState({target_url: value["value"]}, () => {this.getChannelCount(false)})}} name='selected_channel' id='selected_channel' options={this.state.channels} closeMenuOnSelect={true}/>
                                                </>
                                            }

                                            <label className="my-2">Regular / Urgent Request:</label>
                                            <select className='form-control square col-sm-12 col-lg-12 col-12 my-2 m-1' readOnly={this.state.form_load} value={this.state.emergency_request} onChange={(e) => { this.setState({ emergency_request: this.checkBoolVal(e.target.value) }) }}>
                                                <option value={false} >Regular Request</option>
                                                <option value={true} >Urgent, Start earlier, even at slow speed</option>
                                            </select>

                                            <label className="my-2">Category:</label>
                                            <select className='form-control square col-sm-12 col-lg-12 col-12 my-2 m-1' readOnly={this.state.form_load} value={this.state.selected_category} onChange={(e) => {this.setState({ selected_category: parseInt(e.target.value), price_plan: {}})}}>
                                                {this.state.selected_category?<></>:<>
                                                    <option value={0} >Select Category</option>
                                                </>}
                                                {Object.keys(this.state.categories).map( key => <option value={this.state.categories[key]['id']} >{this.state.categories[key]['category']}</option>)}
                                            </select>

                                            {this.state.selected_category!=0 ?
                                                <Row>
                                                    <Col>
                                                        <label className="my-2">Description:</label>
                                                        <textarea type="text" className='border-0 form-control square col-sm-12 col-lg-12 col-12 my-2 m-1 p-2' readOnly={true} placeholder={this.state.categories[this.state.selected_category]['description']?this.state.categories[this.state.selected_category]['description']:"No Description"} />
                                                    </Col>
                                                </Row>:<></>
                                            }

                                            {this.state.selected_category?<>
                                                <label className="my-2">Action:</label>
                                                <select className='form-control square col-sm-12 col-lg-12 col-12 my-2 m-1' disabled={this.state.form_load} value={this.state.action} onChange={(e) => { this.setState({ action: e.target.value, price_plan: {} }) }}>
                                                    {Object.keys(this.state.price_charts).map(key => <option value={key} >{this.state.possible_actions[key]}</option>)}
                                                </select>

                                                {this.state.action.includes('report')?<>
                                                    <label className="my-2">Report Channel or Group:</label>
                                                    <select className='form-control square col-sm-12 col-lg-12 col-12 my-2 m-1'
                                                            disabled={!this.state.action.includes('report')} value={this.state.spam}
                                                            onChange={(e) => { this.setState({ spam: e.target.value }) }}>
                                                        <option value={'InputReportReasonSpam'} >Spam</option>
                                                        <option value={'InputReportReasonFake'} >Fake Account</option>
                                                        <option value={'InputReportReasonViolence'} >Violence</option>
                                                        <option value={'InputReportReasonPornography'} >Pornography</option>
                                                        <option value={'InputReportReasonChildAbuse'} >ChildAbuse</option>
                                                        <option value={'InputReportReasonGeoIrrelevant'} >Geo Irrelevant</option>
                                                        <option value={'InputReportReasonCopyright'} >Copyright</option>
                                                        <option value={'InputReportReasonIllegalDrugs'} >Illegal Drugs</option>
                                                        <option value={'InputReportReasonOther'} >Other</option>
                                                    </select>

                                                </>:<></>}

                                                {/* <label className="my-2">Select Countries:</label>
                                                <Select className='black my-2' disabled={this.state.form_load} onChange={(value) => {this.setState({selected_country: value})}} name='country' id='country' options={this.countries()} isMulti closeMenuOnSelect={false}/> */}

                                                <label className="my-2">Price Plan: </label>
                                                <select className='form-control square col-sm-12 col-lg-12 col-12 my-2 m-1' disabled={this.state.form_load} value={(Object.keys(this.state.price_plan).length === 0)?null:this.state.price_plan["price_id"]} onChange={(e) => { this.update_price_plan(e.target.value) }}>
                                                    {(Object.keys(this.state.price_plan).length === 0)?<>
                                                        <option value={null} >Select The Price Plan</option>
                                                    </>:<></>}
                                                    {this.state.price_charts[this.state.action]?<>
                                                        {Object.keys(this.state.price_charts[this.state.action]).map(key => this.get_plan_option(key))}
                                                    </>:<></>}
                                                </select>
                                            </>:<></>}

                                            {(Object.keys(this.state.price_plan).length === 0)?<></>:<>
                                                <Row><Col>
                                                    <label className="my-2">Plan Description:</label>
                                                    <textarea type="text" className='border-0 form-control square col-sm-12 col-lg-12 col-12 my-2 m-1 p-2'
                                                    readOnly={true} value={this.state.price_plan["plan_statement"]} />
                                                </Col></Row>
                                                <Row><Col>
                                                    <label className="my-2">Is Premium Telegram Account ?</label>
                                                    <textarea type="text" className='border-0 form-control square col-sm-12 col-lg-12 col-12 my-2 m-1 p-2'
                                                    readOnly={true} value={this.state.premium?"Yes, Premium Telegram Account will be used":"No, Regular Non Premium Telegram Accounts will be used"} />
                                                </Col></Row>
                                                <Row><Col>
                                                    <label className="my-2">Is via Search ?</label>
                                                    <textarea type="text" className='border-0 form-control square col-sm-12 col-lg-12 col-12 my-2 m-1 p-2'
                                                    readOnly={true} value={this.state.join_via_search?"Yes, The Group or Channel will be found using Search":"No, The Group or Channel will be just found with the Link"} />
                                                </Col></Row>
                                                {(this.state.price_plan["days"] || this.state.price_plan["hours"] || this.state.price_plan["minutes"])?<>
                                                    <Row><Col>
                                                        <label className="my-2">How Long will Stay ?</label>
                                                        <textarea type="text" className='border-0 form-control square col-sm-12 col-lg-12 col-12 my-2 m-1 p-2'
                                                        readOnly={true} value={`${this.state.price_plan["days"]?this.state.price_plan["days"]+" Day(s)":""} ${this.state.price_plan["hours"]?this.state.price_plan["hours"]+" Hour(s)":""} ${this.state.price_plan["minutes"]?this.state.price_plan["minutes"]+" Minute(s)":""}`} />
                                                    </Col></Row>
                                                </>:<></>}
                                                <label>Enter the Maximum user count:</label>
                                                <input type="number" className='form-control square col-sm-12 col-lg-12 col-12 m-1' disabled={this.state.form_load} value={this.state.max_user} onChange={(e) => { this.setState({ max_user: parseInt(e.target.value) }) }} placeholder="Enter Max number of users" />
                                                {this.state.max_user?<>
                                                    <Row><Col>
                                                        <label className="my-2">Total Price ($) : <strike>{this.state.price_plan["is_offer"]?this.state.price_plan["non_offer_price"]+"$":""}</strike> {this.state.max_user * (this.state.price_plan["price"]/this.state.price_plan["count"])}$ {this.state.price_plan["is_offer"]?"🎉":""}</label>
                                                    </Col></Row>
                                                </>:<></>}
                                                {this.state.join_via_search?<>
                                                    <label className="my-2">Use Custom Keyword for Search:</label>
                                                    <select className='form-control square col-sm-12 col-lg-12 col-12 my-2 m-1' readOnly={this.state.form_load} value={this.state.custom_search_keyword} onChange={(e) => { this.setState({ custom_search_keyword: this.checkBoolVal(e.target.value) }) }}>
                                                        <option value={false} >No (Use the Username as Keyword for Search)</option>
                                                        <option value={true} >Yes (Use the Custom Keyword for Search)</option>
                                                    </select>

                                                    {this.state.custom_search_keyword?<>
                                                        <label className="my-2 labelfont">Enter the Search Keyword:</label>
                                                        <input type="url" className='form-control square col-sm-12 col-lg-12 col-12 my-2 m-1' readOnly={this.state.form_load} value={this.state.search_keyword} onChange={(e) => { this.setState({ search_keyword: e.target.value }) }} placeholder="Search Keyword" />
                                                    </>:<></>}
                                                </>:<></>}

                                                <label className="my-2">Proxy:</label>
                                                <select className='form-control square col-sm-12 col-lg-12 col-12 my-2 m-1' readOnly={this.state.form_load} value={this.state.proxy} onChange={(e) => { this.setState({ proxy: this.checkBoolVal(e.target.value) }) }}>
                                                    <option value={true} >Enable Proxy</option>
                                                    <option value={false} >Disable Proxy</option>
                                                </select>

                                                <label className="my-2">Start Later:</label>
                                                <select className='form-control square col-sm-12 col-lg-12 col-12 my-2 m-1' readOnly={this.state.form_load} value={this.state.scheduled_request} onChange={(e) => { this.setState({ scheduled_request: this.checkBoolVal(e.target.value) }) }}>
                                                    <option value={true} >Yes</option>
                                                    <option value={false} >No</option>
                                                </select>
                                                {this.state.scheduled_request?
                                                    <>
                                                        <Row className="my-2">
                                                            <Col className="ml-auto" lg="4" md="4" sm="4">
                                                                <input type="number" className='form-control square col-sm-12 col-lg-12 col-12 m-1 p-2' readOnly={this.state.form_load} value={this.state.scheduled_days} onChange={(e) => { this.setState({ scheduled_days: parseInt(e.target.value) }) }} placeholder="Number of Days" />
                                                            </Col>
                                                            <Col className="mr-auto" lg="4" md="4" sm="4">
                                                                <input type="number" className='form-control square col-sm-12 col-lg-12 col-12 m-1 p-2' readOnly={this.state.form_load} value={this.state.scheduled_hours} onChange={(e) => { this.setState({ scheduled_hours: parseInt(e.target.value) }) }} placeholder="Number of Hours" />
                                                            </Col>
                                                            <Col className="ml-auto" lg="4" md="4" sm="4">
                                                                <input type="number" className='form-control square col-sm-12 col-lg-12 col-12 m-1 p-2' readOnly={this.state.form_load} value={this.state.scheduled_mins} onChange={(e) => { this.setState({ scheduled_mins: parseInt(e.target.value) }) }} placeholder="Number of Minutes" />
                                                            </Col>
                                                        </Row>
                                                    </>:<></>
                                                }
                                            </>}
                                            {(this.state.status_running || this.state.status_scheduled)?
                                                <>
                                                    <Row className="my-2 mt-4">
                                                        <Col className="mx-auto" lg="6" md="6" sm="6">
                                                            <button onClick={() => this.stop_process()} disabled={this.state.form_load || this.state.hold_stop_btn} className="btn btn-lg btn-info col-sm-12 col-lg-12 col-12 bg-danger"> Stop {this.state.hold_stop_btn?<i className='fa fa-refresh icon-spin'></i>:''}</button>
                                                        </Col>
                                                        {/* <Col className="mr-auto" lg="4" md="4" sm="4">
                                                            <button onClick={() => this.restart_process()} disabled={this.state.form_load || this.state.spam !== 'No' || this.state.hold_restart_btn} className="btn btn-lg btn-info col-sm-12 col-lg-12 col-12 bg-blue"> Restart {this.state.hold_restart_btn?<i className='fa fa-refresh icon-spin'></i>:''}</button>
                                                        </Col> */}
                                                        {/* <Col className="ml-auto" lg="4" md="4" sm="4">
                                                            <h4 className="m-3 text-left text-muted text-white-50">status : {this.state.status?this.state.status+"...":<i className='fa fa-refresh icon-spin'></i>}</h4>
                                                        </Col> */}
                                                    </Row>
                                                </>:<>
                                                    <Row className="my-2 mt-4">
                                                        <Col className="mx-auto" lg="6" md="6" sm="6">
                                                            <button onClick={() => {this.validate_url(this.client_request)}} disabled={this.state.form_load || this.state.hold_join_btn || (Object.keys(this.state.price_plan).length === 0)} className={`btn btn-lg btn-info col-sm-12 col-lg-12 col-12 bg-${this.state.action=='join'?"green":this.state.action=='leave'?"red":"blue"}`}> <a><img className="join-start-icon" /></a> Start {this.state.action.replace("_", " ")} {this.state.hold_join_btn?<i className='fa fa-refresh icon-spin'></i>:''}</button>
                                                        </Col>
                                                    </Row>
                                                </>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </></CardBody>
                        </Card>
                    </Col>
                </Row>

                {this.state.new_channel?<></>
                :<>
                    <VisibilitySensor partialVisibility onChange={(isVisible) => {this.setState({channel_counts_visibility: isVisible})}}><>
                    
                        <Card className="card-stats">
                            <CardBody>
                                <Row className="mx-auto" lg="12" md="12" sm="12">
                                    <Col lg="4" md="4" sm="4">
                                        <Card className="card-stats card border-green joinleave">
                                            <CardBody>
                                                <Row>
                                                    <Col md="3" xs="3">
                                                        <div className="icon-big text-center icon-warning">
                                                        <a><img className="join-joined-icon" /></a>
                                                        </div>
                                                    </Col>

                                                    <Col md="9" xs="9">
                                                        <div className="numbers">
                                                            <p className="card-category text-center">joined</p>
                                                            <CardTitle className="tle-actve" tag="p">{this.state.channel_counts_loading?<i className='fa fa-refresh icon-spin'></i>:this.state.added_count}</CardTitle>
                                                            <p />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                            <CardFooter>
                                                <hr />
                                                <div className="stats text-center">
                                                    <i className="fas fa-sync-alt" />
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                    <Col lg="4" md="4" sm="4">
                                        <Card className="card-stats card border-green joinleave mw-100">
                                            <CardBody>
                                                <Row>
                                                    <Col md="3" xs="3">
                                                        <div className="icon-big text-center icon-warning">
                                                        <a><img className="join-settings-icon" /></a>
                                                        </div>
                                                    </Col>
                                                    <Col md="9" xs="9">
                                                        <div className="numbers">
                                                            <p className="card-category text-center">Left </p>
                                                            <CardTitle className="tle-actve" tag="p">{this.state.channel_counts_loading?<i className='fa fa-refresh icon-spin'></i>:this.state.removed_count}</CardTitle>
                                                            <p />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                            <CardFooter>
                                                <hr />
                                                <div className="stats text-center">
                                                    <i className="sas" />
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                    <Col lg="4" md="4" sm="4">
                                        <Card className="card-stats card border-green joinleave">
                                            <CardBody>
                                                <Row>
                                                    <Col md="3" xs="3">
                                                        <div className="icon-big text-center icon-warning">
                                                        <a><img className="join-dead-icon" /></a>
                                                        </div>
                                                    </Col>
                                                    <Col md="9" xs="9">
                                                        <div className="numbers">
                                                            <p className="card-category text-center">Reported Channel</p>
                                                            <CardTitle className="tle-actve" tag="p">{this.state.channel_counts_loading?<i className='fa fa-refresh icon-spin'></i>:this.state.reported_count}</CardTitle>
                                                            <p />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                            <CardFooter>
                                                <hr />
                                                <div className="stats text-center">
                                                <i className="sas" />
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                    <Col lg="4" md="4" sm="4">
                                        <Card className="card-stats card border-green joinleave">
                                            <CardBody>
                                                <Row>
                                                    <Col md="3" xs="3">
                                                        <div className="icon-big text-center icon-warning">
                                                        <a><img className="join-joined-icon" /></a>
                                                        </div>
                                                    </Col>
                                                    <Col md="9" xs="9">
                                                        <div className="numbers">
                                                            <p className="card-category text-center">Bot Started</p>
                                                            <CardTitle className="tle-actve" tag="p">{this.state.channel_counts_loading?<i className='fa fa-refresh icon-spin'></i>:this.state.bot_started_count}</CardTitle>
                                                            <p />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                            <CardFooter>
                                                <hr />
                                                <div className="stats text-center">
                                                <i className="sas" />
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                    <Col lg="4" md="4" sm="4">
                                        <Card className="card-stats card border-green joinleave">
                                            <CardBody>
                                                <Row>
                                                    <Col md="3" xs="3">
                                                        <div className="icon-big text-center icon-warning">
                                                        <a><img className="join-joined-icon" /></a>
                                                        </div>
                                                    </Col>
                                                    <Col md="9" xs="9">
                                                        <div className="numbers">
                                                            <p className="card-category text-center">Bot Interacted</p>
                                                            <CardTitle className="tle-actve" tag="p">{this.state.channel_counts_loading?<i className='fa fa-refresh icon-spin'></i>:this.state.bot_interacted_count}</CardTitle>
                                                            <p />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                            <CardFooter>
                                                <hr />
                                                <div className="stats text-center">
                                                <i className="sas" />
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                    <Col lg="4" md="4" sm="4">
                                        <Card className="card-stats card border-green joinleave">
                                            <CardBody>
                                                <Row>
                                                    <Col md="3" xs="3">
                                                        <div className="icon-big text-center icon-warning">
                                                        <a><img className="join-dead-icon" /></a>
                                                        </div>
                                                    </Col>
                                                    <Col md="9" xs="9">
                                                        <div className="numbers">
                                                            <p className="card-category text-center">Bot Reported</p>
                                                            <CardTitle className="tle-actve" tag="p">{this.state.channel_counts_loading?<i className='fa fa-refresh icon-spin'></i>:this.state.bot_reported_count}</CardTitle>
                                                            <p />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                            <CardFooter>
                                                <hr />
                                                <div className="stats text-center">
                                                <i className="sas" />
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                    <Col lg="4" md="4" sm="4">
                                        <Card className="card-stats card border-green joinleave">
                                            <CardBody>
                                                <Row>
                                                    <Col md="3" xs="3">
                                                        <div className="icon-big text-center icon-warning">
                                                        <a><img className="join-dead-icon" /></a>
                                                        </div>
                                                    </Col>
                                                    <Col md="9" xs="9">
                                                        <div className="numbers">
                                                            <p className="card-category text-center">Boost</p>
                                                            <CardTitle className="tle-actve" tag="p">{this.state.channel_counts_loading?<i className='fa fa-refresh icon-spin'></i>:this.state.boosted_count}</CardTitle>
                                                            <p />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                            <CardFooter>
                                                <hr />
                                                <div className="stats text-center">
                                                <i className="sas" />
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardTitle>
                                <Button disabled={true} className="btn-purple float-left ml-3">Updated on : {this.state.tt_channel_counts?this.state.tt_channel_counts.toUTCString():''}</Button>
                                <Button disabled={true} className={`btn-${this.state.status_scheduled?"info":this.state.status_running?"green":this.state.tt_channel_counts?"red":""} float-left ml-3`}>Status : {this.state.status_scheduled?"Scheduled":this.state.status_running?"Running":this.state.tt_channel_counts?"Stopped":""}</Button>
                                <Button onClick={this.getChannelCount} disabled={this.state.channel_counts_loading} className="btn btn-outline-green float-right">{this.state.tt_channel_counts?<>Update Details <i className={`fa fa-refresh ${this.state.channel_counts_loading?'icon-spin':''} `}></i></>:<>Get Details</>}</Button>
                            </CardTitle>
                        </Card>
                    </></VisibilitySensor>
                </>}

                <Row>
                    <Col className="mx-auto" lg="12" md="12" sm="12" >
                        <Card className="card-stats border-info">
                            <CardHeader>
                                <CardTitle>
                                    <Row>
                                        <Col className="m-2 h5">Running Requests:</Col>
                                        <Col lg="10" md="10" sm="10">
                                            <select className='form-control square col-sm-12 col-lg-12 col-12 my-2 m-1' disabled={this.state.form_load} value={this.state.sel_running_req} onChange={(e) => { this.setState({ sel_running_req: e.target.value }) }}>
                                                <option value={''} >Select Request</option>
                                                {Object.keys(this.state.running_req_list).map( key => <option value={key} >{key} - {this.state.running_req_list[key]['target_url']}</option>)}
                                            </select>
                                        </Col>
                                    </Row>
                                    {this.state.sel_running_req?
                                    <>
                                        <Button disabled={true} className="btn-purple float-left">Updated on : {this.state.tt_running_req_list?this.state.tt_running_req_list.toUTCString():''}</Button>
                                        {this.state.running_req_list[this.state.sel_running_req]['re-scheduled']?<Button disabled={true} className={`btn-${this.state.running_req_list[this.state.sel_running_req]['auto_leave']?"blue":"warning"} ml-1 float-left`}>{this.state.running_req_list[this.state.sel_running_req]['auto_leave']?"Auto Leave in :":"Scheduled for :"} {this.state.running_req_list[this.state.sel_running_req]['remaining_time']}</Button>:""}
                                        {this.state.running_req_list[this.state.sel_running_req]['running']?<Button disabled={true} className="btn-green ml-1 float-left">Running</Button>:""}
                                        <Button disabled={true} className="btn-infp ml-1 float-left">{this.state.running_req_list[this.state.sel_running_req]['action']} : {this.state.running_req_list[this.state.sel_running_req]['count']}</Button>
                                        {this.state.running_req_list[this.state.sel_running_req]['auto_leave']?<></>:<>
                                            <Button onClick={() => {this.stop_process(this.state.sel_running_req)}} disabled={this.state.form_load || this.state.running_req_list_load} className="btn btn-outline-red float-right">{<>Stop Request <i className={`fa fa-refresh ${this.state.form_load?'icon-spin':''} `}></i></>}</Button>
                                            {this.state.running_req_list[this.state.sel_running_req]['running']?"":<Button onClick={() => {this.start_immediate(this.state.sel_running_req)}} disabled={this.state.form_load || this.state.running_req_list_load} className="btn btn-outline-blue ml-1 float-right">Start Immediately <i className={`fa fa-refresh ${this.state.form_load?'icon-spin':''} `}></i></Button>}
                                        </>}
                                    </>
                                    :<></>}
                                    <Button onClick={() => {this.getRunningRequests()}} disabled={this.state.form_load || this.state.running_req_list_load} className="btn btn-outline-green float-right">{<>Reload Requests <i className={`fa fa-refresh ${this.state.running_req_list_load?'icon-spin':''} `}></i></>}</Button>
                                </CardTitle>
                            </CardHeader>
                            <CardBody className="p-3 my-0" style={{display: 'block', maxWidth: '100%', width: '100%', height: 'auto'}}>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <VisibilitySensor partialVisibility onChange={(isVisible) => {
                    this.setState({history_visibility: isVisible})
                    if (this.state.history_visibility){
                        (this.state.channel_history || this.state.order_history)?console.log('Loaded Already'):console.log('No Data')
                    }
                }}>
                    <Row>
                        <Col className="mx-auto" lg="12" md="12" sm="12" >
                            <Card className="card-stats border-info">
                                <CardHeader>
                                    <CardTitle>
                                        <Row>
                                            <Col className="m-2 h4">History:</Col>
                                            <Col lg="10" md="10" sm="10">
                                                <select className='form-control square col-sm-12 col-lg-12 col-12 my-2 m-1' disabled={true} value={this.state.history_type} onChange={(e) => { this.setState({ history_type: e.target.value }) }}>
                                                    {/* <option value={''} >Select History Type</option>
                                                    <option value={'channel_based'} >Channel Based History</option> */}
                                                    <option value={'order_based'} >Order Based History</option>
                                                </select>
                                            </Col>
                                        </Row>
                                        {this.state.history_type?
                                        <>
                                            <Row>
                                                <Col className="mr-auto" lg="8" md="12" sm="12">
                                                    <Button disabled={true} className="btn-purple float-left">Updated on : {this.isChannelHistory()?this.state.tt_channel_history.toUTCString():this.isOrderHistory()?this.state.tt_order_history.toUTCString():''}</Button>
                                                </Col>
                                                <Col className="float-right" lg="2" md="6" sm="6">
                                                    <select className='form-control square col-sm-12 col-lg-12 col-12 my-2' readOnly={this.state.form_load} value={this.state.history_ago} onChange={(e) => { this.setState({ history_ago: parseInt(e.target.value) }) }}>
                                                        {new Array(32).fill("", 0, 30).map((p, i) => {return (i>0)?
                                                            <>
                                                                <option value={i} >Last {(i*100)+1} - {(i+1)*100} Requests</option>
                                                            </>:<>
                                                                <option value={i} >Last {(i+1)*100} Requests</option>
                                                            </>
                                                        })}
                                                    </select>
                                                </Col>
                                                <Col className="ml-auto" lg="2" md="6" sm="6">
                                                    <Button onClick={() => {this.get_history(this.state.history_type)}} disabled={this.state.loading_history} className="btn btn-outline-green float-right">{(this.isChannelHistory() || this.isOrderHistory())?<>Reload History <i className={`fa fa-refresh ${this.state.loading_history?'icon-spin':''} `}></i></>:<>Get History</>}</Button>
                                                </Col>
                                            </Row>
                                        </>
                                        :<></>}
                                    </CardTitle>
                                </CardHeader>
                                <CardBody className="p-3 my-0" style={{display: 'block', maxWidth: '100%', width: '100%', height: 'auto', opacity: this.state.history_visibility ? 1 : 0.25, transition: 'opacity 1000ms linear'}}>
                                    {this.isChannelHistory()?
                                        <JALLambdaChannelTable data={this.state.channel_history} categories={this.state.categories} history_type={this.state.history_type} />
                                    :this.isOrderHistory()?
                                        <JALLambdaOrderTable data={this.state.order_history} categories={this.state.categories} history_type={this.state.history_type} />
                                    :this.state.loading_history?
                                        'Loading...'
                                    :''}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </VisibilitySensor>

            </div>
        </>);
    }
}
const mapStateToProps = state => ({
    auth: state.auth
});
export default connect(mapStateToProps)(JALLambda);

