import axios from "axios.js";
import {
  USER_LOADING,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  REGISTER_SUCCESS,
  REGISTER_FAIL
} from "./types";

import Swal from 'sweetalert2'


// axios.defaults.withCredentials = true;

export const loadUser = () => (dispatch, getState) => {
  dispatch({ type: USER_LOADING });

  const token = getState().auth.token;

  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  if (token) {
    config.headers["Authorization"] = `JWT ${token}`;
  }


  axios
    .get(`${process.env.REACT_APP_API_URL}/api/auth/user`, config)
    .then(res => {
      dispatch({
        type: USER_LOADED,
        payload: res.data
      });
      localStorage.setItem("jal_api_key", res.data.jal_api_key);
    })
    .catch(err => {
      dispatch({
        type: AUTH_ERROR
      });
    });
};

export const loginUser = (username, password) => (dispatch, getState) => {
  dispatch({ type: USER_LOADING });
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  const body = JSON.stringify({ username, password });

  axios
    .post(`${process.env.REACT_APP_API_URL}/api/auth/token/`, body, config)
    .then(res => {
      console.log(res.data)
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data
      });
      localStorage.setItem("jal_api_key", res.data.jal_api_key);
    })
    .catch(err => {
      Swal.fire({title:'Wrong Username Or Password',text:'Please check the username and password',closeButton:'ok',timer:5000, icon:'error',timerProgressBar:true})
      dispatch({
        type: LOGIN_FAIL
      });
    });
};

export const logoutUser = () => (dispatch, getState) => {
  localStorage.removeItem("token");
  localStorage.removeItem("jal_api_key");
  dispatch({
    type: LOGOUT
  });
};

export const registerUser = (
  first_name,
  last_name,
  username,
  email,
  password
) => (dispatch, getState) => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  const body = JSON.stringify({
    first_name,
    last_name,
    username,
    email,
    password
  });

  axios
    .post(`${process.env.REACT_APP_API_URL}/api/auth/register/`, body, config)
    .then(res => {
      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: REGISTER_FAIL
      });
    });
};
