
import React from "react";
import { NavLink } from "react-router-dom";
import { Nav } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";


import logo from "assets/img/logo.png";

var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    
    this.activeRoute.bind(this);
    this.sidebar = React.createRef();
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  render() {
    return (
      <div
        className="sidebar"
        data-color={this.props.bgColor}
        data-active-color={this.props.activeColor}
      >
        <div className="logo">
        <h3 className='white pl-3 text-bold-700 m-0 ' style={{"font-family":"Montserrat"}}>TeleBooster</h3>
            <div className="logo-img ">
            <img src={logo} alt="logo " />
            </div>

        </div>
        
        <div className="sidebar-wrapper " ref={this.sidebar}>
          <Nav>
            {this.props.routes.map((prop, key) => {
              return (
                <>
                {this.props.auth.user.group.includes(prop.access)?<>
                <li
                  className={
                    this.activeRoute(prop.path) +
                    (prop.pro ? " active-pro" : "")
                  }
                  key={key}
                >
                  <NavLink
                    to={prop.layout + prop.path}
                    className="nav-link text-bold-700"
                    activeClassName="active header-active"
                  >
                    <i className={prop.icon+' pl-3'} />
                    <p>{prop.name}</p>
                  </NavLink>
                </li>
                </>:<></>}
                
                </>
              );
            })}
          </Nav>
        </div>
      </div>
    );
  }
}

export default Sidebar;
