
import axios from "axios.js";
import React from "react";
import { connect } from "react-redux";
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import Messenger_table from 'views/messenger_table.js'
import NotificationAlert from "react-notification-alert";
import Swal from 'sweetalert2'
// react plugin used to create charts
import { Line, Pie } from "react-chartjs-2";
import withReactContent from 'sweetalert2-react-content'
import msgr from "assets/img/msgr.png";
import Loader_gif from "../assets/img/loader.gif"

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
// core components
const MySwal = withReactContent(Swal)


class messenger extends React.Component {
  constructor(){
    super()
    
    this.state={
      has_data_in_messenger:false,
      message_text:'',
      user_count:'loading...',
      messenger_running:false,
      messenger_status:{
        counter:0,
        error_count:0
      },
      media:'',

      session_counts_loading: false,
      ready_to_use_session_count: 0,
      muted_session_count: 0,
      alive_session_count: 0,
      dead_session_last_week: 0,
      tt_session_counts: null,
    }
  }
  componentDidMount=()=>{
    let s=process.env.REACT_APP_DATABASE
    console.log('messenger mounted',this.props.auth,s)
    
    this.get_session_counts()
    this.get_input_count()
    this.getFullStatus('inti')
    
    this.interval = setInterval(() => this.getFullStatus(''), 2000);
    this.interval2 = setInterval(() => this.get_input_count(), 10000);
    
}
    componentWillUnmount() {
      clearInterval(this.interval);
      clearInterval(this.interval2);
    }

notificationAlert = React.createRef();



  // specify upload params and url for your files
  get_session_counts = () => {
    this.setState({session_counts_loading: true})
    axios({
      url: this.props.auth.BaseUrl+'messenger_channel/get_counts',
      headers:this.props.auth.config.headers,
      method: 'GET',
    }).then((response) => {
      this.setState({
        muted_session_count: response.data["data"]["muted_sessions"],
        alive_session_count: response.data["data"]["alive_sessions"],
        ready_to_use_session_count: response.data["data"]["ready_to_use_sessions"],
        dead_session_last_week: response.data["data"]["dead_last_7_days"],
        session_counts_loading: false,
        tt_session_counts: new Date()
      })
    }).catch((error) => {
      this.setState({session_counts_loading: false})
      console.error("Error :", error)
      if (error.response){
        Swal.fire({
          title: "Something Went Wrong",
          html: error.response.data["error_msg"],
          icon: "danger",
        })
      }
    })
  }


  getUploadParams = ({ meta }) => {
    var config
    config={ 
        ...this.props.auth.config,
        url: this.props.auth.BaseUrl+'messenger/upload_messenger_input' }
    config.headers['Content-Type']='multipart/form-data'
    config.headers['media_type']='*/*'
    config.headers['Content-Disposition']=`attachment; filename=${meta.name}`
    return config 
  }
  deletemessengerData= ()=>{
    Swal.fire({
        title: 'Do you want to delete and stop the messenger?',
        showDenyButton: true,
        // showCancelButton: true,
        confirmButtonText: `Delete all`,
        denyButtonText: `Cancel it`,
      }).then((result) => {
        if (result.isConfirmed) {
            axios({
                url: this.props.auth.BaseUrl+'messenger/messenger_data_count?option=delete',
                headers:this.props.auth.config.headers,
                method: 'GET',
                }).then((response) => {
                    this.setState({
                        has_data_in_messenger:response.data.messenger_count>0?true:false,
                        user_count:response.data.messenger_count,
                        messenger_running:response.data.messenger_running,
                        message_text:response.data.message_text
                    })
                });

          Swal.fire('Deleted!', '', 'success')
        } else if (result.isDenied) {
          Swal.fire('Canceled', '', 'info')
        }
      })
  }
  // called every time a file's `status` changes
   handleChangeStatus = ({ meta, file }, status) => {  }
  
  // receives array of files that are done uploading when submit button is clicked
    UploadExcelSubmit = (files, allFiles) => {
        var config
        config = {
            ...this.props.auth.config,
        }
        config.headers['Content-Type'] = 'multipart/form-data'
        var files_all = []
        const formData = new FormData();
        files.map(f => {
            formData.append('files', f.file)
        })

        Swal.fire({title:'Loading the CSV File(s) in the System, Please wait...... ',
          imageUrl:Loader_gif,
          imageAlt: 'loading...',
          showConfirmButton: false,
        })

        axios({
            url: this.props.auth.BaseUrl + 'messenger/upload_messenger_input', data: formData, headers: config.headers,
            method: 'POST',

        }).then((response) => {
            Swal.fire({title:'CSV File(s) are Loaded ', closeButton:'ok', timer:1000, icon:'success', timerProgressBar:true})
            this.get_input_count()
            this.getFullStatus('inti')
        }).catch(
            function (error) {
                console.error(error)
                Swal.fire({title:'Something Went Wrong', closeButton:'ok', timer:1000, icon:'error', timerProgressBar:true})
                  // alert('Input correct file')
                return Promise.reject(error)
            }
        )
    }
    get_input_count=()=>{
        console.log(this.props.auth.config)
        axios({
            url: this.props.auth.BaseUrl+'messenger/messenger_data_count?option=count',
            headers:this.props.auth.config.headers,
            method: 'GET',
          }).then((response) => {
              this.setState({
                  has_data_in_messenger:response.data.messenger_count>0?true:false,
                  user_count:response.data.messenger_count,
                  messenger_running:response.data.messenger_running,

              })
              
            
          });
    }
    import_user_from_scraper=()=>{
      Swal.fire({
        icon:'warning',
        title: 'Confirm',
        text:'Are you sure to import all users from scrapper to Messenger?',
        showDenyButton: true,
        // showCancelButton: true,
        confirmButtonText: `Yes Import`,
        denyButtonText: `Cancel`,
      }).then((result) => {
        if (result.isConfirmed) {
            axios({
                url: this.props.auth.BaseUrl+`messenger/import_user_from_scraper`,
                headers:this.props.auth.config.headers,
                method: 'GET',
              }).then((response) => {
                Swal.fire(response.data.message, '', 'success')
                  this.get_input_count()
                  
              });
            }
            else if (result.isDenied) {
              Swal.fire('Canceled', '', 'info')
            }
          
  });
  }
    set_key_values=(key,value)=>{

      axios({
          url: this.props.auth.BaseUrl+`api/auth/status?key=${key}&value=${value}`,
          headers:this.props.auth.config.headers,
          method: 'GET',
        }).then((response) => {
            this.get_input_count()
            
          
        });
  }
    getFullStatus=(v) =>{
      if (this.state.messenger_running | v=='inti'){
        axios({
          url: this.props.auth.BaseUrl+`api/auth/status?key=messenger_full_status`,
          headers:this.props.auth.config.headers,
          method: 'GET',
        }).then((response) => {
            console.log(response.data)
            this.setState({
                  messenger_status:response.data.data
            })
            if (this.state.message_text==''){
              this.setState({
                message_text:this.state.messenger_status.message_text
          })
            }
            
        });
      }
      
    }


    start_messenger_username=()=>{
      Swal.fire({
        title: 'Are you sure start the adding process with the  current Username',
        showDenyButton: true,
        // showCancelButton: true,
        confirmButtonText: `Start`,
        denyButtonText: `Cancel`,
      }).then((result) => {
        if (result.isConfirmed && (typeof this.state.message_text  === 'string' || this.state.media!=='') ) {
              const data = new FormData() 
              data.append('file', this.state.media)
              data.append('message_text', this.state.message_text)
              console.log(data);
              Swal.fire({title:'message sending command Intitaing please wait...... ',closeButton:'ok', icon:'success'})

          
              var config
              config = {
                  ...this.props.auth.config,
              }
              config.headers['Content-Type'] = 'multipart/form-data'
              axios({
                  url:  this.props.auth.BaseUrl+`messenger/messenger_username_init`, data: data, headers: config.headers,
                  method: 'POST',
          
              }).then((response) => {
                  console.log(response)
                  Swal.fire({title:'message sending command initiated ',closeButton:'ok',timer:1000, icon:'success',timerProgressBar:true})
                  this.get_input_count()
                  this.getFullStatus('inti')
              }).catch(
                  function (error) {
                      alert('Input correct file')
                      return Promise.reject(error)
                  }
              )
      
            }
        else if (result.isDenied) {
          Swal.fire('Canceled', '', 'info')
        }
        else{
          Swal.fire('No Message Found', 'Please add message or media to continue', 'error')
        }
          
  });
}

  get_export=()=>{
    axios({
      url: this.props.auth.BaseUrl+'Master_sessions/export_master',config:this.props.auth.config,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      console.log(response)
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
    });

  }
  Auto_refresh_flag=()=>{
    alert('called')
    return this.state.messenger_running
  }

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col lg="3" md="6" sm="12">
              <Card className="card-stats card joinleave">
                <CardBody>
                  <Row>
                    <Col md="3" xs="3">
                      <div className="icon-big text-center icon-warning">
                        <a><img className="join-joined-icon" /></a>
                      </div>
                    </Col>
                    <Col md="9" xs="9">
                      <div className="numbers">
                        <p className="card-category">Ready To Use Sessions</p>
                          <CardTitle className="tle-joinleave" tag="p">{this.state.session_counts_loading?<i className='fa fa-refresh icon-spin'></i>:this.state.ready_to_use_session_count}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fas fa-sync-alt" /> {this.state.status}
                  </div>
                </CardFooter>
              </Card>
            </Col>

            <Col lg="3" md="6" sm="12">
              <Card className="card-stats card joinleave">
                <CardBody>
                  <Row>
                    <Col md="3" xs="3">
                      <div className="icon-big text-center icon-warning">
                        <a><img className="join-settings-icon" /></a>
                      </div>
                    </Col>
                    <Col md="9" xs="9">
                      <div className="numbers">
                        <p className="card-category lft">Muted Session</p>
                          <CardTitle className="tle-lft" tag="p">{this.state.session_counts_loading?<i className='fa fa-refresh icon-spin'></i>:this.state.muted_session_count}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                      <i className="sas" />
                </div>
              </CardFooter>
            </Card>
          </Col>

            <Col lg="3" md="6" sm="12">
              <Card className="card-stats card joinleave">
                <CardBody>
                  <Row>
                    <Col md="3" xs="3">
                      <div className="icon-big text-center icon-warning">
                        <a><img className="join-dead-icon" /></a>
                      </div>
                    </Col>
                    <Col md="9" xs="9">
                      <div className="numbers">
                        <p className="card-category lft">Alive Session</p>
                          <CardTitle className="tle-actve" tag="p">{this.state.session_counts_loading?<i className='fa fa-refresh icon-spin'></i>:this.state.alive_session_count}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                  <i className="sas" />
                </div>
              </CardFooter>
            </Card>
          </Col>

            <Col lg="3" md="6" sm="12">
              <Card className="card-stats card joinleave">
                <CardBody>
                  <Row>
                    <Col md="3" xs="3">
                      <div className="icon-big text-center icon-warning">
                        <a><img className="join-settings-icon" /></a>
                      </div>
                    </Col>
                    <Col md="9" xs="9">
                      <div className="numbers">
                        <p className="card-category lft">Dead In Last 7 Days</p>
                          <CardTitle className="tle-actve" tag="p">{this.state.session_counts_loading?<i className='fa fa-refresh icon-spin'></i>:this.state.dead_session_last_week}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                  <i className="sas" />
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Row className="mb-3">
          {this.state.tt_session_counts?<>
            <Col className="mr-auto" lg="6" md="6" sm="6">
              <button disabled={true} className="btn btn-lg btn-outline-warning col-sm-12 col-lg-12 col-12">Session Counts Updated on : {this.state.tt_session_counts.toUTCString()}</button>
            </Col>
          </>:<></>}
          <Col className="ml-auto" lg="2" md="2" sm="2">
            <button onClick={this.get_session_counts} disabled={this.state.form_load || this.state.session_counts_loading} className="btn btn-lg col-sm-12 col-lg-12 col-12 btn-outline-green"> {this.state.session_counts_loading?<i className='fa fa-refresh icon-spin'></i>:''} Reset Count </button>
          </Col>
        </Row>
          <Row>
          <NotificationAlert ref={this.notificationAlert} />
          {/* {JSON.stringify(this.state.messenger_status)} */}
            <Col lg="12" md="12" sm="12">
            <Row>
            <Col lg="2" md="6" sm="6" p-2>
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="3" xs="3">
                      <div className="icon-big text-center icon-warning">
                      <a><img className="msgsent" /></a>
                      </div>
                    </Col>
                    <Col md="9" xs="9">
                      <div className="numbers">
                        <p className="card-category">Message Sent</p>
                        <CardTitle className="tle-joinleave" tag="p">{this.state.messenger_status?this.state.messenger_status.counter:0}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fas fa-sync-alt" /> Update Now
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="2" md="6" sm="6" p-2>
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="3" xs="3">
                      <div className="icon-big text-center icon-warning">
                      <a><img className="msgrwrng" /></a>
                      </div>
                    </Col>
                    <Col md="9" xs="9">
                      <div className="numbers">
                        <p className="card-category">Message Not Sent</p>
                        <CardTitle className="tle-joinleave" tag="p">{this.state.messenger_status?this.state.messenger_status.error_count:0}</CardTitle>
                        <p />
                      </div>
                      </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fas fa-sync-alt" /> Update Now
                  </div>
                </CardFooter>
              </Card>
            </Col>
            </Row>

              <Card className="card-stats">
                <CardHeader>
                <Row>
                  <Col lg="6" md="6" sm="6">
                    <h5 className="white">Send Bulk messages to users</h5>
                  </Col>
                </Row>
                </CardHeader>
                <CardBody className="p-3">
                  {!this.state.has_data_in_messenger?
                  <>
                        <Dropzone className="dzu-dropzone"                      
                        // getUploadParams={this.getUploadParams}
                        onChangeStatus={this.handleChangeStatus}
                        onSubmit={this.UploadExcelSubmit}
                        accept="*"
                        multiple={true}
                        inputContent={'Upload the Username CSV with format | username | user | ...'}
                        submitButtonContent={'Upload'}

                        />
                          <div className="center-btn">
                        <button onClick={this.import_user_from_scraper} className="btn btn-msgr btn-md"><img class="img-responsive-left-icon" src={msgr}  alt="logo "/>Import All Scraped user</button>
                        </div>
                        </>
                  :
                        <>
                        <Row className=''>
                        <Col lg="3" md="3" sm="3">
                        <div className="my-3">
                                    <small className='h6 text-muted'>Total users :</small><span className='text-primary'> {this.state.user_count}</span>
                                </div>  
                          </Col>
                        <Col lg="9" md="9" sm="12">
                                <div className='float-right'>
                                
                                    <button onClick={this.deletemessengerData} disabled={this.state.messenger_running?true:false} className='btn btn-danger square'>Delete Input username</button>
                                    <button onClick={this.import_user_from_scraper} disabled={this.state.messenger_running?true:false} className="btn btn-success square">Import All Scraped user</button>
                                </div>
                        </Col>
                        </Row>
                            <Row className=''>
                                <Col lg="8" md="8" sm="12">
                                    <div className="my-3">
                                      <Row>
                                        <Col lg="12" md="12" sm="12">
                                          <label htmlFor=""> Message text:</label>
                                          <br/>
                                          <textarea cols='50' rows='2' className='border' value={this.state.message_text} onChange={(e) => { this.setState({ message_text: e.target.value }) }} placeholder="Enter the message to be send " />

                                        </Col>
                                        <Col lg="6" md="6" sm="6">
                                        <label htmlFor=""> Media file:</label>

                                          <input type='file' className='form-control'   name="upload_file" onChange={(event) => { this.setState({ media:event.target.files[0] }) }} placeholder='file to send'/>
                                        </Col>


                                      

                                        {this.state.messenger_running?
                                        <>
                                        <div className='row'>
                                          <div className="col-4">
                                            <button className='btn btn-danger' onClick={(e) => this.set_key_values('messenger_status','stopped')} >Stop</button>
                                          </div>
                                          <div className="col-8 text-center pt-3 text-muted text-black-50">
                                            status : sending message...
                                          </div>
                                        </div>
                                        </>
                                        :
                                        <Col lg="6" md="6" sm="6">

                                          <button className='btn btn-info btn-float  btn-square float-right' onClick={(e) => this.start_messenger_username()} > <i className='nc-icon nc-send'></i> Send Message</button>
                                        </Col>
                                        }
                                        </Row>

                                    </div>
                                </Col>

                                <Col lg="2" md="2" sm="6" >
                                  <Card className="card-stats bg-blue bg-lighten-5 mt-2 ">
                                    <CardBody>
                                      <Row>
                                        <Col md="4" xs="4">
                                          <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-send text-success" />
                                          </div>
                                        </Col>
                                        <Col md="8" xs="8">
                                          <div className="numbers">
                                            <p className="card-category">Message Sent</p>
                                            <CardTitle tag="p">{this.state.messenger_status?this.state.messenger_status.counter:0}</CardTitle>
                                            <p />
                                          </div>
                                        </Col>
                                      </Row>
                                    </CardBody>
                                      <CardFooter>
                                        <hr />
                                        <div className="stats">
                                          <i className="fas fa-sync-alt" /> 
                                          {this.state.messenger_running?
                                          
                                          this.state.messenger_status.counter===0?<>proxy connecting...</>:'proxy connected'
                                          :
                                          ''
                                          }
                                        </div>
                                      </CardFooter>
                                  </Card>
                                  </Col>
                                <Col lg="2" md="2" sm="6" >

                                  <Card className="card-stats bg-primary bg-lighten-4 mt-2  ">
                                    <CardBody>
                                      <Row>
                                      <Col md="4" xs="4">
                                          <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-simple-remove text-secondary" />
                                          </div>
                                        </Col>
                                        <Col md="8" xs="8">
                                          <div className="numbers">
                                            <p className="card-category primary">Message Not Sent</p>
                                            <CardTitle tag="p">{this.state.messenger_status?this.state.messenger_status.error_count:0}</CardTitle>
                                            <p />
                                          </div>
                                        </Col>
                                      </Row>
                                    </CardBody>
                                      <CardFooter>
                                        <hr />
                                        <div className="stats">
                                          <i className="fas fa-sync-alt" /> 
                                        </div>
                                      </CardFooter>
                                  </Card>
                                     
                                    {/* <div className="my-3 float-right">
                                        <small className='h6 text-muted'>Message Sent to :</small><span className='text-primary'> {this.state.messenger_status.counter?this.state.messenger_status.counter:0}</span>
                                    </div>  
                                    
                                    <div className="my-3 float-right">
                                        <small className='h6 text-muted'>Message Cant Send :</small><span className='text-primary'> {this.state.messenger_status.error_count?this.state.messenger_status.error_count:0}</span>
                                    </div>                     */}
                                </Col>
                            </Row>
                            <Messenger_table config={this.props.auth.config} auto_refresh={this.state.messenger_running}/>
                        </>
                  }
                </CardBody>
                
              </Card>
            </Col>
            
          </Row>
          <Row>


          </Row>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps)(messenger);

