import React, { Fragment, Component } from "react";
import Header from "./layout/Header";
import { HashRouter as Router, Route, Switch,Redirect } from "react-router-dom";
import Login from "./account/Login";
import Register from "./account/Register";
import Unauthorized_r from "./account/unauthorized";
import Footer from "./layout/Footer";
import Profile from "./account/Profile";
import { Provider } from "react-redux";
import store from "../store";
import PrivateRoute from "./utils/PrivateRoute";
import { loadUser } from "../actions/auth";
import Sidebar from "./Sidebar/Sidebar.js";
import Admin from "components/layouts/Admin.js"
import { createBrowserHistory } from "history";
const hist = createBrowserHistory();
export class App extends Component {
  componentDidMount() {
    store.dispatch(loadUser());
  }

  render() {
    return (
      <Provider store={store}>
        <Fragment>
          <Router >
            <Switch>
              {/* <PrivateRoute  path="/admin" component={Admin} /> */}
              <Route  path="/accounts/login/" component={Login} />
              <Route  path="/accounts/register/" component={Register} />
              <Route  path="/error/400/" component={Unauthorized_r} />
              <PrivateRoute  path='' component={Admin} />
              
            </Switch>

          </Router>
        </Fragment>
      </Provider>
    );
  }
}

export default App;
