// src/components/filter.table.js
import React from "react";

import { useTable, useSortBy,useFilters, useGlobalFilter, useAsyncDebounce,usePagination } from 'react-table'
// import 'bootstrap/dist/css/bootstrap.min.css';



// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    gotoPage
}) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <span>

            <input
                className='form-control searchedit input lg float-right m-3 '
                value={value || ""}
                style={{ width: '200px' }}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                    gotoPage(0)
                }}
                placeholder={`Search in ${count} Records...`}
            />
        </span>
    )
}

function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    const count = preFilteredRows.length

    return (
        <input
            className="form-control searchedit input sm float-right m-3 "
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
            placeholder={`Search in ${count} Records...`}
        />
    )
}

function Table({ columns, data }) {

    const defaultColumn = React.useMemo(
        () => ({
            // Default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state,
        state: { pageIndex, pageSize },
        preGlobalFilteredRows,
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState: { pageIndex: 0, pageSize:20 },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
    )

    return (
        <>
            <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                gotoPage={gotoPage}
            />
            <table className='table table-sm    table-dark table-hover  table-striped bg-darker  no-border'      {...getTableProps()}>
            
            <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                // Add the sorting props to control sorting. For this example
                                // we can add them into the header props
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    {/* Add a sort direction indicator */}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? ' 🔽'
                                                : ' 🔼'
                                            : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <ul className="pagination ">
              <span className='font-small-1 my-3'>
                Page{' '}
                <strong>
                {pageIndex + 1} of {pageOptions.length}
                </strong>{' '}
                </span>
                <li className="page-item"> <button className=' btn btn-outline-green  p-1 bg-transparent green mx-1 bold-700 square border-green border-4' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {'<<'}
                </button></li>
                <li className="page-item"> <button className=' btn btn-outline-green  p-1 bg-transparent green mx-1 bold-700 square border-green border-4 ' onClick={() => previousPage()} disabled={!canPreviousPage}>
                {'<'}
                </button></li>                
                <li className="page-item"> <button className=' btn btn-outline-green  p-1 bg-transparent green mx-1 bold-700 square border-green border-4 ' onClick={() => nextPage()} disabled={!canNextPage}>
                {'>'}
                </button></li>
                <li className="page-item"> <button className=' btn btn-outline-green  p-1 bg-transparent green mx-1 bold-700 square border-green border-4 ' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                {'>>'}
                </button></li>
            </ul>

                <select
                    className="form-control"
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                        gotoPage(0)
                    }}
                    style={{ width: '120px', height: '38px' }}
                >
                    {[ 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            <br />
            <div>Showing the first {pageSize} results of {rows.length} rows</div>

        </>
    )
} 



function FilterTableComponent(data) {
    var spam_dict = {
        No: "Don't Report",
        InputReportReasonSpam: "Spam",
        InputReportReasonFake: "Fake Account",
        InputReportReasonViolence: "Violence",
        InputReportReasonPornography: "Pornography",
        InputReportReasonChildAbuse: "ChildAbuse",
        InputReportReasonGeoIrrelevant: "Geo Irrelevant",
        InputReportReasonCopyright: "Copyright",
        InputReportReasonIllegalDrugs: "Illegal Drugs",
        InputReportReasonOther: "Other",
    };

    const columns = React.useMemo(() => [
        {
            Header: 'Order ID',
            accessor: 'order_id'
        },
        {
            Header: 'Channel Id',
            accessor: d=>{ 
                return (
                    <span>{d.channel_id?d.channel_id:'-'}</span>
                )
            },
        },
        {
            Header: 'Channel url',
            accessor: 'target_url'
        },
        {
            Header: 'Action',
            accessor: d=>{
                return(d.action[0].toUpperCase() + d.action.replace("_", " ").substring(1))
            }
        },
        {
            Header: 'Count',
            accessor: 'count'
        },
        {
            Header: 'Report',
            accessor: d=>{
                return(spam_dict[d.spam])
            }
        },
        {
            Header: 'Category',
            accessor: d=>{
                if (d.category_id) {
                    var cat = data.categories[d.category_id].category
                    return(<span>{cat[0].toUpperCase() + cat.substring(1)}</span>)
                } else {
                    return(<span>Default</span>)
                }
            }
        },
        {
            Header: 'Created ',
            accessor: d => { 
                var date = d.time.split(' ')[0].split('/')
                var time = d.time.split(' ')[1].split(':')
                return (
                    <span>{d.time?new Date(Date.UTC(date[2], date[1]-1, date[0], time[0], time[1], time[2], 0)).toUTCString():'-'}</span>
                )
            },
        },
        {
            Header: 'Final Status',
            accessor: d=>{
                if (d.reason){
                    return(d.reason)
                } else {
                    return(<span>-</span>)
                }
            }
        },
    ], [])

    return (
        <Table  columns={columns} data={data.data} />
    )
}

export default FilterTableComponent;