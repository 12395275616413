// api-client.js

import axios from 'axios';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

// Add a request interceptor
axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    console.log('Sending request');
    return config;
  }, function (error) {
    // Do something with request error
    console.log('error in sending request');
    return Promise.reject(error);
  });

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
  }, function (error) {
    // Do something with response error
    try{
        if (error.response.status==401){
            if (window.location.href.includes('login')){
                
            }
            else{
              window.location.replace('/');
            }
            
        }
        else{
          console.log(error.response.status)
        }

    }catch{
        
    }
    
    return Promise.reject(error);
  });

export default axios;