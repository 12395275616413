
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/auth";
import logo from "assets/img/logo.png";
import flag_logo from "assets/img/flag_new.png";
import flagfinal from "assets/img/Flagfinal.png";

import reverse_logo from "assets/img/icon1.png";
import user_df_logo from "assets/img/icon2.png";
import user_profile from "assets/img/UserPro.png";



import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Input,
} from "reactstrap";

import routes from "routes.js";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      dropdownOpen: false,
      color: "transparent",
    };
    this.toggle = this.toggle.bind(this);
    this.dropdownToggle = this.dropdownToggle.bind(this);
    this.sidebarToggle = React.createRef();
  }
  logout = () => {
    this.props.logoutUser();
  };
  toggle() {
    if (this.state.isOpen) {
      this.setState({
        color: "transparent",
      });
    } else {
      this.setState({
        color: "dark",
      });
    }
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  dropdownToggle(e) {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }
  getLogo() {
    let brandICon = "Default Brand";
    routes.map((prop, key) => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        brandICon = prop.icon;
      }
      return null;
    });
    return brandICon;
  }
  getBrand() {
    let brandName = "Default Brand";
    routes.map((prop, key) => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        brandName = prop.name;
      }
      return null;
    });
    return brandName;
  }
  openSidebar() {
    document.documentElement.classList.toggle("nav-open");
    this.sidebarToggle.current.classList.toggle("toggled");
  }
  // function that adds color dark/transparent to the navbar on resize (this is for the collapse)
  updateColor() {
    if (window.innerWidth < 993 && this.state.isOpen) {
      this.setState({
        color: "dark",
      });
    } else {
      this.setState({
        color: "transparent",
      });
    }
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateColor.bind(this));
  }
  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      this.sidebarToggle.current.classList.toggle("toggled");
    }
  }
  render() {
    console.log(this.props)
    return (
      // add or remove classes depending if we are on full-screen-maps page or not
      <Navbar
        color={
          this.props.location.pathname.indexOf("full-screen-maps") !== -1
            ? "dark"
            : this.state.color
        }
        expand="lg"
        className={
          this.props.location.pathname.indexOf("full-screen-maps") !== -1
            ? "navbar-absolute fixed-top"
            : "navbar-absolute fixed-top " +
              (this.state.color === "transparent" ? "navbar-transparent " : "")
        }
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-toggle">
              <button
                type="button"
                ref={this.sidebarToggle}
                className="navbar-toggler"
                onClick={() => this.openSidebar()}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            
          <a> <div className='header-active  p-3 rounded bg-darker border-radius-2'>
          <i className={this.getLogo()+''} />
            </div>
            </a>

            <NavbarBrand href="/">{this.getBrand()}</NavbarBrand>
          </div>
          <NavbarToggler onClick={this.toggle}>
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </NavbarToggler>
          <Collapse
            isOpen={this.state.isOpen}
            navbar
            className="justify-content-end"
          >

            <Nav navbar>
            <ul className="navbar-nav mr-auto">
      <li className="nav-item active">
        <span style={{cursor:'pointer'}} className="nav-link pointer" to="user-page" onClick={()=>{ window.history.back() }}><img className="img-responsive-left-icon" src={reverse_logo}  alt="logo " />Back</span>
      </li>
      <li className="nav-item active">
        <span style={{cursor:'pointer'}}  className="nav-link pointer" onClick={this.logout} ><img className="img-responsive-sec-left-icon" src={user_df_logo}   alt="logo "/>Logout</span>
      </li>
      
    </ul>
    {/* <span className="navbar-text">
      Navbar text with an inline element
    </span>
                            <a className="nav-link" href="#">
                            <img src={reverse_logo} className="img-responsive-left-icon" alt="logo " />
                            </a>
                        
                            <a className="nav-link" href="#">
                            <img src={user_df_logo} className="img-responsive-sec-left-icon" alt="logo " />
                            </a> */}
                        
             
              <Dropdown
                nav
                isOpen={this.state.dropdownOpen}
                toggle={(e) => this.dropdownToggle(e)}
              >
                
                <DropdownToggle caret nav>
                  <p>
                  
                  <span className="text px-1">{this.props.auth.user.username}</span><img className="img-responsive-user-icon" src={user_profile}  alt="logo " />
                  </p>
                </DropdownToggle>
                <DropdownMenu right className='bg-info'>
                  <DropdownItem >
                    <Link to="user-page" className="white"> Profile</Link>
                  </DropdownItem>
                  {/* <DropdownItem className="white" > 
                      Logout
                  </DropdownItem> */}
                </DropdownMenu>
              </Dropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {
    logoutUser
  }
)(Header);

