// src/components/filter.table.js
import React from "react";

import { useTable, useSortBy,useFilters, useGlobalFilter, useAsyncDebounce,usePagination } from 'react-table'
// import 'bootstrap/dist/css/bootstrap.min.css';



// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    gotoPage
}) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <span>

            <input
                className='form-control searchedit input sm float-right m-3 '
                value={value || ""}
                style={{ width: '200px' }}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                    gotoPage(0)
                }}
                placeholder={`Search in ${count} Records...`}
            />
        </span>
    )
}

function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    const count = preFilteredRows.length

    return (
        <input
            className="form-control searchedit input sm float-right m-3 "
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
            placeholder={`Search in ${count} Records...`}
        />
    )
}

function Table({ columns, data }) {

    const defaultColumn = React.useMemo(
        () => ({
            // Default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state,
        state: { pageIndex, pageSize },
        preGlobalFilteredRows,
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState: { pageIndex: 0, pageSize:20 },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
    )

    return (
        <>
            <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                gotoPage={gotoPage}
            />
            <table className='table table-sm    table-dark table-hover  table-striped bg-darker  no-border'      {...getTableProps()}>

            <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                // Add the sorting props to control sorting. For this example
                                // we can add them into the header props
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    {/* Add a sort direction indicator */}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? ' 🔽'
                                                : ' 🔼'
                                            : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <ul className="pagination ">
              <span className='font-small-1 my-3'>
                Page{' '}
                <strong>
                {pageIndex + 1} of {pageOptions.length}
                </strong>{' '}
                </span>
                <li className="page-item"> <button className=' btn btn-outline-green  p-1 bg-transparent green mx-1 bold-700 square border-green border-4' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {'<<'}
                </button></li>
                <li className="page-item"> <button className=' btn btn-outline-green  p-1 bg-transparent green mx-1 bold-700 square border-green border-4 ' onClick={() => previousPage()} disabled={!canPreviousPage}>
                {'<'}
                </button></li>
                <li className="page-item"> <button className=' btn btn-outline-green  p-1 bg-transparent green mx-1 bold-700 square border-green border-4 ' onClick={() => nextPage()} disabled={!canNextPage}>
                {'>'}
                </button></li>
                <li className="page-item"> <button className=' btn btn-outline-green  p-1 bg-transparent green mx-1 bold-700 square border-green border-4 ' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                {'>>'}
                </button></li>
            </ul>

                <select
                    className="form-control"
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                        gotoPage(0)
                    }}
                    style={{ width: '120px', height: '38px' }}
                >
                    {[ 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            <br />
            <div>Showing the first {pageSize} results of {rows.length} rows</div>

        </>
    )
}



function FilterTableComponent(data) {
    var columns_list = null
    console.log("Table Data : ", data)
    if (data.history_type === 'orders'){
        columns_list = [
            {
                Header: 'User Chat ID',
                accessor: 'online_user_id'
            },
            {
                Header: 'User Full Name',
                accessor: 'online_user_name'
            },
            {
                Header: 'User Username',
                accessor: 'online_username'
            },
            {
                Header: 'Time',
                accessor: d => {
                    var date = d.added_on.split(' ')[0].split('/')
                    var time = d.added_on.split(' ')[1].split(':')
                    return (
                        <span>{d.added_on?new Date(Date.UTC(date[2], date[1]-1, date[0], time[0], time[1], time[2], 0)).toUTCString():'-'}</span>
                    )
                },
            },
            {
                Header: 'Message Sent',
                accessor: d => {
                    var msg_sent = d.message_sent

                    return (
                        <span>{msg_sent?'Yes':'Not yet'}</span>
                    )
                },
            },
            {
                Header: 'Added to Group',
                accessor: d => {
                    var added_to_group = d.added_to_group

                    return (
                        <span>{added_to_group?'Yes':'Not yet'}</span>
                    )
                },
            },
            {
                Header: 'Status',
                accessor: 'status'
            },
        ]
    } else if ((data.history_type === 'target_group') || (data.history_type === 'source_channel')){
        columns_list = [
            {
                Header: (data.history_type == 'target_group')?'Source Channel':'Target Group',
                accessor: (data.history_type == 'target_group')?'source_channel':'target_group'
            },
            {
                Header: 'User Chat ID',
                accessor: 'online_user_id'
            },
            {
                Header: 'User Full Name',
                accessor: 'online_user_name'
            },
            {
                Header: 'User Username',
                accessor: 'online_username'
            },
            {
                Header: 'Time',
                accessor: d => {
                    var date = d.added_on.split(' ')[0].split('/')
                    var time = d.added_on.split(' ')[1].split(':')
                    return (
                        <span>{d.added_on?new Date(Date.UTC(date[2], date[1]-1, date[0], time[0], time[1], time[2], 0)).toUTCString():'-'}</span>
                    )
                },
            },
            {
                Header: 'Message Sent',
                accessor: d => {
                    var msg_sent = d.message_sent
                    return (
                        <span>{msg_sent?'Yes':'Not yet'}</span>
                    )
                },
            },
            {
                Header: 'Added to Group',
                accessor: d => {
                    var added_to_group = d.added_to_group
                    return (
                        <span>{added_to_group?'Yes':'Not yet'}</span>
                    )
                },
            },
            {
                Header: 'Status',
                accessor: 'status'
            },
        ]
    }

    const columns = React.useMemo(() => columns_list, [])

    return (
        <Table  columns={columns} data={data.data} />
    )
}

export default FilterTableComponent;