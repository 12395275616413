
import axios from "axios.js";
import React from "react";
import { connect } from "react-redux";
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import Scraper_table from 'views/scraper_table.js'
import Loader_gif from "../assets/img/loader.gif"
import delete_icon from "../assets/img/delete.png";
import dnldfile_icon from "../assets/img/dwnld.png";
import leave_icon from "../assets/img/leave.png";



import Swal from 'sweetalert2'
// react plugin used to create charts
import { Line, Pie } from "react-chartjs-2";
import withReactContent from 'sweetalert2-react-content'
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
// core components
const MySwal = withReactContent(Swal)

class messenger extends React.Component {
  constructor(){
    super()
    
    this.state={
      target_channel:'',
      scraper_date:new Date().toISOString().slice(0,10),
      refresh_table:false,
    }
  }
  componentDidMount=()=>{    
    
}
    componentWillUnmount() {
      // clearInterval(this.interval);
      // clearInterval(this.interval2);
    }





    sent_init_scraper=(event)=>{
      event.preventDefault();
      Swal.fire({
        title: 'Are u sure this is valid input for group?',
        showDenyButton: true,
        // showCancelButton: true,
        confirmButtonText: `yes fetch user`,
        denyButtonText: `Cancel`,
      }).then((result) => {
        if (result.isConfirmed ) {
              console.log(Loader_gif)

              const data = new FormData() 
              data.append('target_channel', this.state.target_channel)
              data.append('scraper_date', this.state.scraper_date)

              Swal.fire({title:'Fetching the users from the Group, Please wait...... ',
              imageUrl:Loader_gif,
              imageAlt: 'loading...',
              showConfirmButton: false,

            })

          
              var config
              config = {
                  ...this.props.auth.config,
              }
              config.headers['Content-Type'] = 'multipart/form-data'
              axios({
                  url:  this.props.auth.BaseUrl+`scraper/fetch_user`, data: data, headers: config.headers,
                  method: 'POST',
          
              }).then((response) => {
                  this.setState({
                    refresh_table:true,
                  })
                  Swal.fire({title:response.data.message,closeButton:'ok', icon:response.data.status==200?'success':'error'})
                  setTimeout(()=>{
                    this.setState({

                      refresh_table:false,
                    })
                    
                  },
                    2000
                )

              }).catch(
                  function (error) {
                    Swal.fire({title:'Please check you internet connect or reload the page ',closeButton:'ok',timer:1000, icon:'error',timerProgressBar:true})
                      return Promise.reject(error)
                  }
              )
      
            }
        else if (result.isDenied) {
          Swal.fire('Canceled', '', 'info')
        }
        else{
          Swal.fire('No Message Found', 'Please add message or media to continue', 'error')
        }
        
          
  });
}

  get_export=()=>{
    console.log(this.props.auth.config)
    axios({
      url: this.props.auth.BaseUrl+'scraper/export_csv',
      headers:this.props.auth.config.headers,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      console.log(response.headers['content-type'].split(';')[1])
      link.setAttribute('download', response.headers['content-type'].split(';')[1]); //or any other extension
      document.body.appendChild(link);
      link.click();
    });

  }
  
  deleteScraperData= ()=>{
    Swal.fire({
        title: 'Do you want to delete all the scraper?',
        showDenyButton: true,
        // showCancelButton: true,
        confirmButtonText: `Delete <i class='fa fa-trash'></i> `,
        denyButtonText: `Cancel <i class='fa fa-close'></i> `,
      }).then((result) => {
        if (result.isConfirmed) {
            axios({
                url: this.props.auth.BaseUrl+'scraper/delete_scraper',
                headers:this.props.auth.config.headers,
                method: 'GET',
                }).then((response) => {
                    this.setState({
                      refresh_table:true,
                    })
                    console.log(response)
                    
                    Swal.fire(`Deleted `, `${response.data.deleted_scraper_count[0]} users deleted`, 'success')
                    setTimeout(()=>{
                      this.setState({
  
                        refresh_table:false,
                      })
                      
                    },
                      5000
                  )
                    
                });

          
        } else if (result.isDenied) {
          Swal.fire('Canceled', '', 'info')
        }
      })
  }

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col lg="12" md="12" sm="12">
              <Card className="card-fetchuser card-stats p-3">
                <CardHeader>
                <Row>
                  <Col lg="6" md="6" sm="6">
                    <h5 className="fectuser">Get the User from the Group</h5>
                  </Col>
                </Row>
                </CardHeader>
                <CardBody className="p-3">

                        <>
                        <Row className=''>
                        <Col lg="12" md="12" sm="12" className='my-2'>
                          <form onSubmit={this.sent_init_scraper}>
                          <Row>
                          <Col md="6"size="sm" s='6'>
                          <label htmlFor="targetchannel">Target Group:</label>
                          <input type="url" required className='form-control square input-sm mb-1' id='targetchannel' value={this.state.target_channel} onChange={(e) => { this.setState({ target_channel: e.target.value }) }} placeholder="Enter the Groups Links or Invite Link ( eg : https://t.me/joinchat/qqwssssqswdqs or https://t.me/username )" />
                          </Col>
                          <Col md="6"size="sm" s='6'>
                          <Row>
                          <Col md="6"size="sm" s='6'>
                          <button type="button" onClick={this.deleteScraperData} className='btn btn-fetchuser-deleteall-clr btn-md btn-block' ><img class="img-responsive-left-icon"  src={delete_icon} alt="logo " />Delete All users</button>
                          </Col>
                          <Col md="6"size="sm" s='6'>
                          <button type="button" onClick={this.get_export} className='btn btn-new btn-click fectchuser glowing-border btn-md btn-block' ><img class="img-responsive-left-icon"  src={dnldfile_icon} alt="logo " />Download CSV</button>
                          </Col>
                          </Row>
                          </Col>
                          <br/>
                          <br/>
                          <Col md="6"size="sm" s='6'>
                          <label htmlFor="scraper_date">Active User Date (5 days from online):</label>
                          <input type="date" max={new Date().toISOString().slice(0,10)} required className='form-control square ' id='scraper_date' value={this.state.scraper_date} onChange={(e) => { this.setState({ scraper_date: e.target.value }) }} placeholder="Select a date to till the users to be fetched" />
                          </Col>
                          <Col md="6"size="sm" s='6'>
                          <button type='submit'  className='btn btn-lg btnclr btn-lg btn-block' ><img class="img-responsive-left-icon"  src={leave_icon} alt="logo " />Fetch User</button>
                          </Col>
                          </Row>
                          </form>
                          <Col lg="6" md="6" sm="12" xl='6'  className=''>
                        {/* <Row className='mt-lg-4'>
                        <Col lg="6" md="6" sm="12" xl='6'  >
                          <button onClick={this.deleteScraperData} className='btn mx-2 btn-danger square col-md-12 col-lg-12 col-sm-6' ><i className='fa fa-trash'></i> <br/>Delete All users</button>
                        </Col>
                        <Col lg="6" md="6" sm="12" xl='6' >
                          <button onClick={this.get_export} className='btn mx-2 square btn-info col-md-12 col-lg-12 col-sm-6' > <i className='fa fa-download'></i> <br/>Download CSV</button>
                        </Col>

                        </Row> */}
                        </Col>
                        
                        </Col>


                       

                        </Row>
                        <div >
                            <Scraper_table config={this.props.auth.config} auto_refresh={this.state.refresh_table}/>
                            </div>
                        </>

                </CardBody>
                
              </Card>
            </Col>
            
          </Row>
          
        </div>
      </>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps)(messenger);

