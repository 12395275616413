
import axios from "axios.js";
import React from "react";
import { connect } from "react-redux";
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import Adder_table from 'views/Adder_table.js'
import NotificationAlert from "react-notification-alert";
import Swal from 'sweetalert2'
// react plugin used to create charts
import { Line, Pie } from "react-chartjs-2";
import withReactContent from 'sweetalert2-react-content'
import Select from 'react-select'
import { Formik, Field, Form } from 'formik';
import Load from "../assets/img/Untitled-2.png"
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
// core components


class AdderForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = this.props.data

   
  }
  componentDidMount = () => {
    this.setState({
      number_of_process:1
    })

    

 

  }
  componentWillUnmount() {
   
  }
  Toast= () => Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
  
  set_key_values=(key,value)=>{

    axios({
        url: this.props.auth.BaseUrl+`api/auth/status?key=${key}&value=${value}&process_id=${this.state.process_id}`,
        headers:this.props.auth.config.headers,
        method: 'GET',
      }).then((response) => {
        
        Swal.fire({
          toast: true,
          
          position: 'top-end',
          title: 'Process Stopped',
          icon:'success',
          timer:1000,
          heightAuto:true,
          showConfirmButton: false,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }

  })
        this.props.refresh()

          
        
      });
}
  start_adder_username=()=>{
    Swal.fire({
    icon:'warning',
      title: 'Are you sure start the adding process with the  current Username',
      background:`#000 url(${Load})`,
      showDenyButton: true,
      width:847,
      padding:'2.25rem',

      confirmButtonText: `Start`,
      denyButtonText: `Cancel`,

        

    }).then((result) => {
      if (result.isConfirmed) {

        Swal.fire({title:'Processing your request',
        position: 'top-end',
        background:`#fff url(${Load})`,
       
        text:'Sending user data to workers to start the adding process',
        showConfirmButton: false,

      })
          axios({
              url: this.props.auth.BaseUrl+`Adder/adder_username_init?Target_channel=${this.state.channel_name}&number_of_process=${this.state.number_of_process}&max_user_count=${this.state.max_user_count}&process_id=${this.state.process_id}`,
              headers:this.props.auth.config.headers,
              method: 'GET',
            }).then((response) => {
              if (response.data.status==200){
                
                // Swal.fire(response.data.message, '', 'success')
                Swal.fire({
                  toast: true,
                  
                  position: 'top-end',
                  title:'Started',
                  text:response.data.message,
                  icon:'success',
                  timer:1000,
                  heightAuto:true,
                  showConfirmButton: false,
                  didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                  }
    
          })


              }
              else{
                Swal.fire(response.data.message, '', 'error')
                
              }
              this.props.refresh()
                
            }).catch(
              function (error) {
                Swal.fire('Processing Error', 'error', 'error')
              }
            )            }
          else if (result.isDenied) {

           
          }
        
});


}

  render() {
    return (
      
      <>
      <Col lg="3" md="6" sm="12" className='border border-green  m-2'>

        <Card className="card-stats">
          <CardHeader>
            Adder {this.state.process_id}


          </CardHeader>
          <CardBody className="p-3">
            <Row>

              <Col lg="12" md="12" sm="12">

                <div className="my-3">
                  <div className="my-3 list-group ">
                    <li className=" list-inline-item  float-left"><small className='h6 text-muted'>status:</small><span className='text-primary'> {this.state.status}</span></li>
                  </div>
                  <label>Target Channel:</label>
                  <input value={this.state.channel_name} onChange={(e) => { this.setState({ channel_name: e.target.value }) }} type="text" className='form-control col-12 m-1' placeholder="Enter the Channel link" />
                  <label>User Count:</label>
                  <input type="number" value={this.state.max_user_count} onChange={(e) => { this.setState({ max_user_count: e.target.value }) }} className='form-control col-12 m-1' placeholder="User count" />
                  <label>Number Of process:</label>
                  <select className='form-control col-sm-12 col-lg-12 col-12 m-1 ' value={this.state.number_of_process} onChange={(e) => { this.setState({ number_of_process: e.target.value }) }}>
                      <option value={1} >1 Process</option>
                      <option value={2} >2 Process</option>
                      <option value={3} >3 Process</option>
                    </select>
                    {this.state.status=='stopped'?<>
                    <button onClick={this.start_adder_username} className="btn btn-xs btn-green">Start Adding</button>
                    </>:<>
                        <button className='btn btn-danger' onClick={(e) => this.set_key_values('status','stopped')} >Stop </button>

                    </>}




                </div>

              </Col>
            </Row>
          </CardBody>

        </Card>
      </Col>
      </>
     
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps)(AdderForm);

