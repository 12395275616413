import axios from "axios.js";
import React from "react";
import { connect } from "react-redux";
import Select from 'react-select'
import "react-dropzone-uploader/dist/styles.css";
import SingleUserMessengerTable from "./SingleUserMessengerTable.js";
import Papa from 'papaparse';
import Swal from "sweetalert2";
import JsonTable from "views/Simple_table.js";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
    Button,
} from "reactstrap";
// core components

class ChannelUsersMessenger extends React.Component {
    constructor() {
        super();
        this.state = {
            session_counts_loading: false,
            muted_session_count: 0,
            alive_session_count: 0,
            ready_to_use_session_count: 0,
            dead_session_last_week: 0,
            tt_session_counts: null,

            slot_number: 1,
            is_username: true,
            username: "",
            phone_number: "", 
            // count: 1,
            slots_data_loading: false,
            slots_data: {},
            request_messages: {},
            input_messages: {},
            input_message: "",
            min_wait_time_mins: 2,
            max_wait_time_mins: 3,
            min_wait_time_secs: 0,
            max_wait_time_secs: 0,

            request_list: [],
            request_history_loading: false,
            request_history: {},
            request_list_loading: false,
            history_order_id: null,
            form_load: false,
        };
    }

    make_call(request_event, payloads={}) {
        let payload_keys = Object.keys(payloads)
        let payload_str = '?'
        if (payload_keys.length > 0) {
            payload_keys.forEach(function(key) {
                payload_str += key + "=" + payloads[key] + "&"
            })
        }

        axios.defaults.withCredentials = false;
        return axios({
            url: this.props.auth.BaseUrl + `single_user_messenger/${request_event}${payload_str.slice(0, -1)}`,
            headers: this.props.auth.config.headers,
            method: 'GET',
        })
    }

    make_post_call(request_event, payloads={}) {
        let payload_keys = Object.keys(payloads)
        const data = new FormData()
        if (payload_keys.length > 0) {
            payload_keys.forEach(function(key) {
                data.append(key, payloads[key])
            })
        }

        console.log(data)
        var config = {
            ...this.props.auth.config,
        }
        config.headers['Content-Type'] = 'multipart/form-data'
        return axios({
            url: this.props.auth.BaseUrl + `single_user_messenger/${request_event}`,
            data: data,
            headers: config.headers,
            method: 'POST',
        })
    }


    get_session_counts = () => {
        this.setState({session_counts_loading: true})
        this.make_call("get_counts").then((response) => {
            this.setState({
                muted_session_count: response.data["data"]["muted_sessions"],
                alive_session_count: response.data["data"]["alive_sessions"],
                ready_to_use_session_count: response.data["data"]["ready_to_use_sessions"],
                dead_session_last_week: response.data["data"]["dead_last_7_days"],
                session_counts_loading: false,
                tt_session_counts: new Date()
            })
        }).catch((error) => {
            this.setState({session_counts_loading: false})
            console.error("Error :", error)
            if (error.response){
                Swal.fire({
                    title: "Something Went Wrong",
                    html: error.response.data["error_msg"],
                    icon: "danger",
                })
            }
        })
    }


    get_slot_list = () => {
        this.setState({slots_data_loading: true})
        this.make_call("get_slot_details").then((response) => {
            let data = {}
            response.data["data"].map((element, index) => {
                element["completed_on"] = this.format_time(element["completed_on"])
                element["created_on"] = this.format_time(element["created_on"])
                data[element["slot_number"]] = element
            });
            this.setState({slots_data: data, slots_data_loading: false}, () => {this.update_slot(this.state.slot_number)})
        }).catch((error) => {
            this.setState({slots_data_loading: false})
            console.error("Error :", error)
            if (error.response){
                Swal.fire({
                    title: "Something Went Wrong",
                    html: error.response.data["error_msg"],
                    icon: "danger",
                })
            }
        })
    }

    get_request_messages = () => {
        if (this.state.request_id && !this.state.request_messages[this.state.request_id]){
            this.setState({form_load: true})
            this.make_call("get_request_messages", {
                "request_id": this.state.slots_data[this.state.slot_number]["request_id"]
            }).then((response) => {
                let request_messages = {...this.state.request_messages}
                request_messages[this.state.request_id] = {
                    data: response.data["data"],
                    tt_request_messages: new Date(),
                }
                console.log(request_messages)
                this.setState({
                    request_messages: request_messages,
                    form_load: false
                })
            }).catch((error) => {
                this.setState({form_load: false})
                console.error("Error :", error)
                if (error.response){
                    Swal.fire({
                        title: "Something Went Wrong",
                        html: error.response.data["error_msg"],
                        icon: "danger",
                    })
                }
            })
        }
    }

    update_request_list = (data, clear=false) => {
        console.log("Update Request List :", data)
        let request_list = []
        if (!clear){
            request_list = [...this.state.request_list]
        }
        data.map((element, index) => {
            console.log("Element :", element)
            let date = element["created_on"].split('T')[0].split('-')
            let time = element["created_on"].split('T')[1].split(':')
            let created_on = new Date(Date.UTC(date[0], date[1]-1, date[2], time[0], time[1], time[2], 0)).toUTCString()
            request_list = [{label: `${element["id"]?element["id"]:element["request_id"]} - ${element["is_username"]?element["username"]:element["phone_number"]} - ${created_on} (${element["count"]} Message${(element["count"]>1)?"s":""})`, value: element["id"]}, ...request_list]
        })
        this.setState({
            request_list: request_list,
        })
    }

    get_request_list = () => {
        this.setState({request_list_loading: true})
        this.make_call("get_request_list").then((response) => {
            this.update_request_list(response.data["data"], true)
            this.setState({
                request_list_loading: false
            })
        }).catch((error) => {
            this.setState({request_list_loading: false})
            console.error("Error :", error)
            if (error.response){
                Swal.fire({
                    title: "Something Went Wrong",
                    html: error.response.data["error_msg"],
                    icon: "danger",
                })
            }
        })
    }


    get_requests_history = () => {
        this.setState({request_history_loading: true})
        this.make_call("get_requests_history", {
            "request_id": this.state.history_request_id
        }).then((response) => {
            let request_history = {...this.state.request_history}
            let request = response.data["data"]["request"]
            request["created_on"] = this.format_time(request["created_on"])
            request["completed_on"] = this.format_time(request["completed_on"])
            request_history[this.state.history_request_id] = {
                history: response.data["data"]["history"],
                request: request,
                tt_request_history: new Date(),
            }
            this.setState({
                request_history: request_history,
                request_history_loading: false
            })
        }).catch((error) => {
            this.setState({request_history_loading: false})
            console.error("Error :", error)
            if (error.response){
                Swal.fire({
                    title: "Something Went Wrong",
                    html: error.response.data["error_msg"],
                    icon: "danger",
                })
            }
        })
    }


    format_time = (time) => {
        if(time){
            var date = time.split(' ')[0].split('/')
            var time = time.split(' ')[1].split(':')
            return new Date(Date.UTC(date[2], date[1]-1, date[0], time[0], time[1], time[2], 0)).toUTCString()
        }
        else{
            return time
        }
    }


    // Input On Change Event Handlers ===>
    update_slot = (slot_number) => {
        console.log(slot_number)
        console.log(this.state.slots_data)
        if (this.state.slots_data[slot_number]) {
            this.setState({
                request_id: this.state.slots_data[slot_number]["request_id"],
                slot_number: this.state.slots_data[slot_number]["slot_number"],
                created_on: this.state.slots_data[slot_number]["created_on"],
                completed_on: this.state.slots_data[slot_number]["completed_on"],
                sent_count: this.state.slots_data[slot_number]["sent_count"],
                is_username: this.state.slots_data[slot_number]["is_username"],
                min_wait_time_mins: this.state.slots_data[slot_number]["min_wait_time_mins"],
                max_wait_time_mins: this.state.slots_data[slot_number]["max_wait_time_mins"],
                min_wait_time_secs: this.state.slots_data[slot_number]["min_wait_time_secs"],
                max_wait_time_secs: this.state.slots_data[slot_number]["max_wait_time_secs"],
                username: this.state.slots_data[slot_number]["username"],
                phone_number: this.state.slots_data[slot_number]["phone_number"],
                // count: this.state.slots_data[slot_number]["count"],
                running: this.state.slots_data[slot_number]["running"],
                completed: this.state.slots_data[slot_number]["completed"],
                reason: this.state.slots_data[slot_number]["reason"]
            }, this.get_request_messages)
        } else {
            this.setState({
                request_id: 0,
                slot_number: slot_number,
                created_on: "",
                completed_on: "",
                sent_count: "",
                is_username: true,
                min_wait_time_mins: 2,
                max_wait_time_mins: 3,
                min_wait_time_secs: 0,
                max_wait_time_secs: 0,
                username: "",
                phone_number: "",
                // count: 1,
                running: false,
                completed: false,
                reason: "Loading..."
            })
        }
    }


    validate_url = (target_link) => {
        if (target_link.startsWith("@")) {
            target_link = target_link.replace("@", "https://t.me/")
        } else if (target_link.startsWith("t.me/")) {
            target_link = target_link.replace("t.me/", "https://t.me/")
        } else if (target_link.startsWith("http://t.me/")) {
            target_link = target_link.replace("http://t.me/", "https://t.me/")
        } else if (!target_link.startsWith("https://t.me/")){
            Swal.fire("Improper Format", `The ${target_link} is not matched with any supported format (https://t.me/channel or https://t.me/channel or @channel)`)
            return
        }
        if (target_link.startsWith("https://t.me/+")) {
            target_link = target_link.replace("https://t.me/+", "https://t.me/joinchat/")
        }
        return target_link
    }


    // Button Click Event Handlers ===>
    start_new_process = () => {
        this.setState({form_load: true})
        if (!this.state.phone_number) {
            var username = this.validate_url(this.state.username)
            if (!username) {
                this.setState({form_load: false})
                return
            }
        }
        let payload = {
            'slot_number': this.state.slot_number,
            'is_username': this.state.is_username,
            'username': username,
            'phone_number': this.state.phone_number,
            'min_wait_time_mins': this.state.min_wait_time_mins,
            'max_wait_time_mins': this.state.max_wait_time_mins,
            'min_wait_time_secs': this.state.min_wait_time_secs,
            'max_wait_time_secs': this.state.max_wait_time_secs,
            'messages': JSON.stringify(this.state.input_messages[this.state.slot_number]),
            // 'count': this.state.count
        }
        console.log(payload)

        this.make_post_call("initiate_request", payload).then((response) => {
            let data = {...this.state.slots_data}
            let element = response.data["data"]
            element["completed_on"] = this.format_time(element["completed_on"])
            element["created_on"] = this.format_time(element["created_on"])
            data[element["slot_number"]] = element
            this.update_request_list([element])
            this.setState({input_messages: [], slots_data: data}, () => {this.update_slot(element["slot_number"])})

            Swal.fire({
                title: "Success Note",
                html: response.data["message"],
                icon: "info",
            })
            this.setState({form_load: false})
        }).catch((error) => {
            this.setState({form_load: false})
            console.error("Error :", error)
            if (error.response){
                Swal.fire({
                    title: "Something Went Wrong",
                    html: error.response.data["error_msg"],
                    icon: "danger",
                })
            }
        })
    }


    resume_process = () => {
        this.setState({form_load: true})
        this.make_call("start_process", {"slot_number": this.state.slot_number}).then((response) => {
            let data = {...this.state.slots_data}
            let element = response.data["data"]
            element["completed_on"] = this.format_time(element["completed_on"])
            element["created_on"] = this.format_time(element["created_on"])
            data[element["slot_number"]] = element
            this.setState({slots_data: data}, () => {this.update_slot(element["slot_number"])})

            Swal.fire({
                title: "Success Note",
                html: response.data["message"],
                icon: "info",
            })
            this.setState({form_load: false})
        }).catch((error) => {
            this.setState({form_load: false})
            console.error("Error :", error)
            if (error.response){
                Swal.fire({
                    title: "Something Went Wrong",
                    html: error.response.data["error_msg"],
                    icon: "danger",
                })
            }
        })
    }


    stop_process = () => {
        this.setState({form_load: true})
        this.make_call("stop_process", {"slot_number": this.state.slot_number}).then((response) => {

            let data = {...this.state.slots_data}
            let element = response.data["data"]
            element["completed_on"] = this.format_time(element["completed_on"])
            element["created_on"] = this.format_time(element["created_on"])
            data[element["slot_number"]] = element
            this.setState({slots_data: data}, () => {this.update_slot(element["slot_number"])})

            Swal.fire({
                title: "Success Note",
                html: response.data["message"],
                icon: "info",
            })
            this.setState({form_load: false})
        }).catch((error) => {
            this.setState({form_load: false})
            console.error("Error :", error)
            if (error.response){
                Swal.fire({
                    title: "Something Went Wrong",
                    html: error.response.data["error_msg"],
                    icon: "danger",
                })
            }
        })
    }


    mark_process_done = () => {
        this.setState({form_load: true})
        this.make_call("mark_process_done", {"slot_number": this.state.slot_number}).then((response) => {

            let data = {...this.state.slots_data}
            let element = response.data["data"]
            element["completed_on"] = this.format_time(element["completed_on"])
            element["created_on"] = this.format_time(element["created_on"])
            data[element["slot_number"]] = element
            this.setState({slots_data: data}, () => {this.update_slot(element["slot_number"])})

            Swal.fire({
                title: "Success Note",
                html: response.data["message"],
                icon: "info",
            })
            this.setState({form_load: false})
        }).catch((error) => {
            this.setState({form_load: false})
            console.error("Error :", error)
            if (error.response){
                Swal.fire({
                    title: "Something Went Wrong",
                    html: error.response.data["error_msg"],
                    icon: "danger",
                })
            }
        })
    }

    append_input_message = (input_message, cleanInput=false) => {
        input_message = input_message.trim()
        if (input_message){
            let temp = {...this.state.input_messages};
            let temp_2 = []
            if (temp[this.state.slot_number]){
                temp_2 = [...temp[this.state.slot_number], input_message];
            } else {
                temp_2 = [input_message]
            }
            temp[this.state.slot_number] = temp_2
            this.setState({input_messages: temp})
        }
        if (cleanInput){
            this.setState({input_message: ""})
        }
    }

    upload_csv_messages = (e) => {
        const file = e.target.files[0];
        console.log("FILES[0] :", file)
        Papa.parse(file, {
            // download: true,
            // headers: true,
            // delimiter: ',',
            complete: ((result) => {
                result.data.map((data, ind) => {
                    console.log(ind+1, ") " + data[0])
                    this.append_input_message(data[0])
                })
            }),
            error: ((result) => {console.log("It's Done...", result.data)}),
        })
    }

    remove_input_message = (index) => {
        let temp = {...this.state.input_messages};
        let temp_2 = []
        this.state.input_messages[this.state.slot_number].map((val, ind) => {
            if (ind != index){
                temp_2 = [...temp_2, val]
            }
        })
        temp[this.state.slot_number] = temp_2
        this.setState({input_messages: temp})
    }

    checkBoolVal(cond) {
        if (cond == 'true'){
            return true
        } else if (cond == 'false') {
            return false
        } else {
            return undefined
        }

    }

    componentDidMount = () => {
        this.get_slot_list()
        this.get_session_counts()
        this.get_request_list()
    };


    componentWillUnmount = () => {};

    render() {
        return (<>
            <div className="content">
                <Row>
                    <Col lg="3" md="6" sm="12">
                        <Card className="card-stats card border-green joinleave">
                            <CardBody>
                                <Row>
                                    <Col md="3" xs="3">
                                        <div className="icon-big text-center icon-warning">
                                        <a><img className="join-joined-icon" /></a>
                                        </div>
                                    </Col>

                                    <Col md="9" xs="9">
                                        <div className="numbers">
                                            <p className="card-category">Ready To Use Sessions</p>
                                            
                                            <CardTitle className="tle-joinleave" tag="p">{this.state.session_counts_loading?<i className='fa fa-refresh icon-spin'></i>:this.state.ready_to_use_session_count}</CardTitle>
                                            <p />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <hr />
                                <div className="stats">
                                    <i className="fas fa-sync-alt" />
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>

                    <Col lg="3" md="6" sm="12">
                        <Card className="card-stats card border-green joinleave">
                            <CardBody>
                                <Row>
                                    <Col md="3" xs="3">
                                        <div className="icon-big text-center icon-warning">
                                        <a><img className="join-settings-icon" /></a>
                                        </div>
                                    </Col>
                                    <Col md="9" xs="9">
                                        <div className="numbers">
                                            <p className="card-category lft">Muted Session</p>
                                            <CardTitle className="tle-lft" tag="p">{this.state.session_counts_loading?<i className='fa fa-refresh icon-spin'></i>:this.state.muted_session_count}</CardTitle>
                                            <p />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <hr />
                                <div className="stats">
                                    <i className="sas" />
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>

                    <Col lg="3" md="6" sm="12">
                        <Card className="card-stats card border-green joinleave">
                            <CardBody>
                                <Row>
                                    <Col md="3" xs="3">
                                        <div className="icon-big text-center icon-warning">
                                        <a><img className="join-dead-icon" /></a>
                                        </div>
                                    </Col>
                                    <Col md="9" xs="9">
                                        <div className="numbers">
                                            <p className="card-category lft">Alive Session</p>
                                            <CardTitle className="tle-actve" tag="p">{this.state.session_counts_loading?<i className='fa fa-refresh icon-spin'></i>:this.state.alive_session_count}</CardTitle>
                                            <p />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <hr />
                                <div className="stats">
                                <i className="sas" />
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>

                    <Col lg="3" md="6" sm="12">
                        <Card className="card-stats card border-green joinleave">
                            <CardBody>
                                <Row>
                                    <Col md="3" xs="3">
                                        <div className="icon-big text-center icon-warning">
                                        <a><img className="join-settings-icon" /></a>
                                        </div>
                                    </Col>
                                    <Col md="9" xs="9">
                                        <div className="numbers">
                                            <p className="card-category lft">Dead In Last 7 Days</p>
                                            <CardTitle className="tle-actve" tag="p">{this.state.session_counts_loading?<i className='fa fa-refresh icon-spin'></i>:this.state.dead_session_last_week}</CardTitle>
                                            <p />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <hr />
                                <div className="stats">
                                <i className="sas" />
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>

                <Row className="mb-3">
                    {this.state.tt_session_counts?<>
                        <Col className="mr-auto" lg="6" md="6" sm="6">
                            <button disabled={true} className="btn btn-lg btn-outline-warning col-sm-12 col-lg-12 col-12">Counts Updated on : {this.state.tt_session_counts.toUTCString()}</button>
                        </Col>
                    </>:<></>}
                    <Col className="ml-auto" lg="2" md="2" sm="2">
                        <button onClick={this.get_session_counts} disabled={this.state.form_load || this.state.session_counts_loading} className="btn btn-lg col-sm-12 col-lg-12 col-12 bg-green"> {this.state.session_counts_loading?<i className='fa fa-refresh icon-spin'></i>:''} Reset Count </button>
                    </Col>
                </Row>

                <Row>
                    <Col className="mx-auto" lg="12" md="12" sm="12">
                        <Card className="card-stats border-info">
                            <CardBody className="p-3"><>
                                <Row className="mb-3">
                                    <Col className="m-auto text-center" lg="10" md="10" sm="10">
                                        <p className="h3 my-auto">Single Users Messenger</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="mx-auto" lg="8" md="8" sm="12">
                                        {this.state.form_load?<div id="loader"></div>:<></>}
                                        <div className="my-3">
                                            <Row>
                                                <label className="my-auto">Slot Number :</label>
                                                <button onClick={this.get_slot_list} className="ml-2 btn btn-outline-green float-right"><i className={`fa fa-refresh${this.state.slots_data_loading?" icon-spin":""}`}></i></button>
                                                <select className='form-control square col-sm-12 col-lg-12 col-12 m-1' readOnly={this.state.form_load} value={this.state.slot_number} onChange={(e) => { this.update_slot(e.target.value) }}>
                                                    {new Array(51).fill("", 1, 11).map((p, i) =><>
                                                        <option value={i} >{i} Slot{this.state.slots_data[i]?" - "+(this.state.slots_data[i]["is_username"]?this.state.slots_data[i]["username"]:this.state.slots_data[i]["phone_number"]):""}</option>
                                                    </>)}
                                                </select>
                                            </Row>

                                            <Row>
                                                <label className="my-2">User Contact Mode:</label>
                                                <select className='form-control square col-sm-12 col-lg-12 col-12 my-2 m-1' readOnly={this.state.form_load || this.state.slots_data_loading || (this.state.slots_data[this.state.slot_number]?!this.state.slots_data[this.state.slot_number]["completed"]:false)} value={this.state.is_username} onChange={(e) => { this.setState({ is_username: this.checkBoolVal(e.target.value) }) }}>
                                                    <option value={true} >Username</option>
                                                    <option value={false} >Phone Number</option>
                                                </select>
                                            </Row>

                                            {this.state.is_username?
                                                <Row className="my-3">
                                                    <label className="labelfont">Username:</label>
                                                    <input type="url" className='form-control square col-sm-12 col-lg-12 col-12 my-2 m-1' readOnly={this.state.form_load || this.state.slots_data_loading || (this.state.slots_data[this.state.slot_number]?!this.state.slots_data[this.state.slot_number]["completed"]:false)} value={this.state.username} onChange={(e) => { this.setState({ username: e.target.value }) }} placeholder="Enter the Username" />
                                                </Row>
                                            :
                                                <Row className="my-3">
                                                    <label className="labelfont">Phone Number:</label>
                                                    <input type="url" className='form-control square col-sm-12 col-lg-12 col-12 my-2 m-1' readOnly={this.state.form_load || this.state.slots_data_loading || (this.state.slots_data[this.state.slot_number]?!this.state.slots_data[this.state.slot_number]["completed"]:false)} value={this.state.phone_number} onChange={(e) => { this.setState({ phone_number: e.target.value }) }} placeholder="Enter the Phone Number" />
                                                </Row>
                                            }

                                            {/* <Row className="my-3">
                                                <label>Enter the Maximum Count:</label>
                                                <input type="number" className='form-control square col-sm-12 col-lg-12 col-12 m-1' disabled={this.state.form_load || this.state.slots_data_loading || (this.state.slots_data[this.state.slot_number]?!this.state.slots_data[this.state.slot_number]["completed"]:false)} value={this.state.count} onChange={(e) => { if (e.target.value > 0) {this.setState({ count: parseInt(e.target.value) })} else {this.setState({ count: 1 })} }} placeholder="Enter the Maximum Count" />
                                            </Row> */}

                                            <Row className="mt-3">
                                                <label>Enter the Minimum Time Break Between Messages:</label>
                                            </Row>
                                            <Row className="mb-3">
                                                <label className="my-auto" >Minutes:</label>
                                                <input type="number" className='form-control square col-sm-4 col-lg-4 col-4 my-1 mx-auto' disabled={this.state.form_load || this.state.slots_data_loading || (this.state.slots_data[this.state.slot_number]?!this.state.slots_data[this.state.slot_number]["completed"]:false)} value={this.state.min_wait_time_mins} onChange={(e) => { if (e.target.value > 1) {this.setState({ min_wait_time_mins: parseInt(e.target.value) })} else {this.setState({ min_wait_time_mins: 1 })} }} placeholder="Minimum Wait Minutes" />
                                                <label className="my-auto" >Seconds:</label>
                                                <input type="number" className='form-control square col-sm-4 col-lg-4 col-4 my-1 mx-auto' disabled={this.state.form_load || this.state.slots_data_loading || (this.state.slots_data[this.state.slot_number]?!this.state.slots_data[this.state.slot_number]["completed"]:false)} value={this.state.min_wait_time_secs} onChange={(e) => { if (e.target.value > 1) { if (e.target.value < 59) {this.setState({ min_wait_time_secs: parseInt(e.target.value) })} else {this.setState({ min_wait_time_secs: 59 })}} else {this.setState({ min_wait_time_secs: 1 })} }} placeholder="Minimum Wait Seconds" />
                                            </Row>

                                            <Row className="mt-3">
                                                <label>Enter the Maximum Time Break Between Messages:</label>
                                            </Row>
                                            <Row className="mb-3">
                                                <label className="my-auto" >Minutes:</label>
                                                <input type="number" className='form-control square col-sm-4 col-lg-4 col-4 my-1 mx-auto' disabled={this.state.form_load || this.state.slots_data_loading || (this.state.slots_data[this.state.slot_number]?!this.state.slots_data[this.state.slot_number]["completed"]:false)} value={this.state.max_wait_time_mins} onChange={(e) => { if (e.target.value > 1) {this.setState({ max_wait_time_mins: parseInt(e.target.value) })} else {this.setState({ max_wait_time_mins: 1 })} }} placeholder="Maximum Wait Minutes" />
                                                <label className="my-auto" >Seconds:</label>
                                                <input type="number" className='form-control square col-sm-4 col-lg-4 col-4 my-1 mx-auto' disabled={this.state.form_load || this.state.slots_data_loading || (this.state.slots_data[this.state.slot_number]?!this.state.slots_data[this.state.slot_number]["completed"]:false)} value={this.state.max_wait_time_secs} onChange={(e) => { if (e.target.value > 1) { if (e.target.value < 59) {this.setState({ max_wait_time_secs: parseInt(e.target.value) })} else {this.setState({ max_wait_time_secs: 59 })}} else {this.setState({ max_wait_time_secs: 1 })} }} placeholder="Maximum Wait Seconds" />
                                            </Row>

                                            {this.state.slots_data[this.state.slot_number]?<>
                                                <Row className="my-3">
                                                    <label>Current Status:</label>
                                                    <input type="text" className='form-control square col-sm-12 col-lg-12 col-12 m-1' disabled={true} placeholder={this.state.slots_data[this.state.slot_number]["reason"]} />
                                                </Row>
                                                {this.state.slots_data[this.state.slot_number]["created_on"]?<>
                                                    <Row className="my-3">
                                                        <label>Created On:</label>
                                                        <input type="text" className='form-control square col-sm-12 col-lg-12 col-12 m-1' disabled={true} placeholder={this.state.slots_data[this.state.slot_number]["created_on"]} />
                                                    </Row>
                                                </>:<></>}

                                                {this.state.slots_data[this.state.slot_number]["completed_on"]?<>
                                                    <Row className="my-3">
                                                        <label>Completed On:</label>
                                                        <input type="text" className='form-control square col-sm-12 col-lg-12 col-12 m-1' disabled={true} placeholder={this.state.slots_data[this.state.slot_number]["completed_on"]} />
                                                    </Row>
                                                </>:<></>}
                                            </>:<></>}
                                            {(!this.state.slots_data[this.state.slot_number]||this.state.slots_data[this.state.slot_number]["completed"])?<>
                                                <Row className="mt-3 mb-1">
                                                    <label>Message Text:</label>
                                                </Row>
                                                <Row className="mt-1 mb-3">
                                                    <textarea type="text" className='form-control square col-sm-8 col-lg-8 col-8 my-2 p-2 mx-auto' readOnly={this.state.form_load || this.state.slots_data_loading || (this.state.slots_data[this.state.slot_number]?!this.state.slots_data[this.state.slot_number]["completed"]:false)} value={this.state.input_message} onChange={(e) => { this.setState({ input_message: e.target.value }) }} placeholder="Enter the Message to Add..." />
                                                    <button onClick={() => this.append_input_message(this.state.input_message, true)} className="btn btn-outline-success col-sm-1 col-lg-1 col-1 float-right mx-auto" readOnly={this.state.form_load || this.state.slots_data_loading || (this.state.slots_data[this.state.slot_number]?!this.state.slots_data[this.state.slot_number]["completed"]:false)}>➕ ADD</button>
                                                    <button className="btn btn-outline-warning col-sm-1 col-lg-1 col-1 float-right mx-auto p-0" readOnly={this.state.form_load || this.state.slots_data_loading || (this.state.slots_data[this.state.slot_number]?!this.state.slots_data[this.state.slot_number]["completed"]:false)}><input type="file" className="bg-black h-100 w-100 m-0 p-auto" style={{opacity: "0%"}} onChange={(e) => this.upload_csv_messages(e)}/><span style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>➕ CSV</span></button>
                                                    <button onClick={() => this.setState({input_messages: []})} className="btn btn-outline-danger col-sm-1 col-lg-1 col-1 float-right mx-auto p-0" readOnly={this.state.form_load || this.state.slots_data_loading || (this.state.slots_data[this.state.slot_number]?!this.state.slots_data[this.state.slot_number]["completed"]:false)}>🆑 CLEAR</button>
                                                </Row>
                                            </>:<></>}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="mx-auto" lg="8" md="8" sm="12">
                                        <div className="my-3">
                                            <Row>
                                                {(!this.state.slots_data[this.state.slot_number]||this.state.slots_data[this.state.slot_number]["completed"])?<>
                                                    {this.state.input_messages[this.state.slot_number]?this.state.input_messages[this.state.slot_number].map((val, ind) => <>
                                                        <Col className="my-auto" lg="11" md="11" sm="11">
                                                            <label className="large m-auto">{ind+1}) {JSON.stringify(val)}</label>
                                                        </Col>
                                                        <Col className="m-auto" lg="1" md="1" sm="1">
                                                            <button onClick={() => this.remove_input_message(ind)} className="btn btn-outline-red float-right">❌</button>
                                                        </Col>
                                                    </>):<></>}
                                                </>:<>
                                                    {this.state.request_messages[this.state.request_id]?this.state.request_messages[this.state.request_id]["data"].map((val, ind) => <>
                                                        <Col className="my-auto" lg="11" md="11" sm="11">
                                                            <label className="large m-auto">{ind+1}) {JSON.stringify(val["message_text"])}</label>
                                                        </Col>
                                                        <Col className="m-auto" lg="1" md="1" sm="1"></Col>
                                                    </>):<></>}
                                                </>}
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className="mx-auto" lg="8" md="8" sm="12">
                                        <div className="my-3">
                                            <Row className="my-2 mt-4">
                                                {(!this.state.slots_data[this.state.slot_number] || this.state.slots_data[this.state.slot_number]["completed"])?<>
                                                    <Col className="mx-auto" lg="5" md="5" sm="5">
                                                        <button onClick={this.start_new_process} disabled={this.state.form_load} className={"btn btn-lg col-sm-12 col-lg-12 col-12 bg-green"}> {this.state.form_load?<i className='fa fa-refresh icon-spin'></i>:''} <a><img className="join-start-icon" /></a> Start New Process </button>
                                                    </Col>
                                                </>:<>
                                                    {this.state.slots_data[this.state.slot_number]["running"]?<>
                                                        <Col className="mx-auto" lg="5" md="5" sm="5">
                                                            <button onClick={this.stop_process} disabled={this.state.form_load} className="btn btn-lg col-sm-12 col-lg-12 col-12 bg-danger"> {this.state.form_load?<i className='fa fa-refresh icon-spin'></i>:''} Stop Process </button>
                                                        </Col>
                                                    </>:<>
                                                        <Col className="mx-auto" lg="5" md="5" sm="5">
                                                            <button onClick={this.resume_process} disabled={this.state.form_load} className={"btn btn-lg col-sm-12 col-lg-12 col-12 bg-green"}> {this.state.form_load?<i className='fa fa-refresh icon-spin'></i>:''} <a><img className="join-start-icon" /></a> Start Process </button>
                                                        </Col>
                                                    </>}
                                                    <Col className="mx-auto" lg="5" md="5" sm="5">
                                                        <button onClick={this.mark_process_done} disabled={this.state.form_load} className="btn btn-lg col-sm-12 col-lg-12 col-12 bg-success"> {this.state.form_load?<i className='fa fa-refresh icon-spin'></i>:''} Mark as Done </button>
                                                    </Col>
                                                </>}
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </></CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col className="mx-auto" lg="12" md="12" sm="12" >
                        <Card className="card-stats border-info">
                            <CardHeader>
                                <CardTitle>
                                    <Row className="mb-3">
                                        <label className="m-auto">Select Request :</label>
                                        <Col lg="9" md="9" sm="9">
                                            <Select
                                                className='black my-2'
                                                disabled={this.state.form_load}
                                                onChange={(value) => {this.setState({history_request_id: value["value"]})}}
                                                name='selected_request'
                                                id='selected_request'
                                                options={this.state.request_list}
                                                closeMenuOnSelect={true}
                                            />
                                        </Col>
                                        <button onClick={this.get_request_list} className="mx-3 my-auto btn btn-outline-green float-right"><i className={`fa fa-refresh${this.state.request_list_loading?" icon-spin":""}`}></i></button>
                                    </Row>
                                    <Row className="mb-3">
                                        {(this.state.request_history[this.state.history_request_id] && this.state.request_history[this.state.history_request_id].tt_request_history)?<>
                                            <Col className="my-auto mr-auto" lg="4" md="6" sm="8">
                                                <button disabled={true} className="btn btn-lg btn-outline-warning col-sm-12 col-lg-12 col-12">History Updated on : {this.state.request_history[this.state.history_request_id].tt_request_history.toUTCString()}</button>
                                            </Col>
                                        </>:<></>}
                                        <Col className="ml-auto" lg="2" md="2" sm="2">
                                            <button onClick={this.get_requests_history} disabled={this.state.form_load || this.state.request_history_loading || !this.state.history_request_id} className="btn btn-lg col-sm-12 col-lg-12 col-12 bg-green"> {this.state.request_history_loading?<i className='fa fa-refresh icon-spin'></i>:''} {this.state.request_history[this.state.history_request_id]?"Reload":"Get"} History </button>
                                        </Col>
                                    </Row>
                                </CardTitle>
                                {this.state.request_history[this.state.history_request_id]?<>
                                    <CardTitle>
                                        <Row className="mx-auto">
                                            <Col className="my-auto" lg="12" md="12" sm="12" >
                                                <Row>
                                                    <Col className="my-auto" lg="4" md="4" sm="4" >
                                                        <span className="large m-auto">Slot Used</span>
                                                    </Col>
                                                    <Col className="my-auto" lg="1" md="1" sm="1" >
                                                        <span className="large m-auto">:</span>
                                                    </Col>
                                                    <Col className="my-auto" lg="6" md="6" sm="6" >
                                                        <span className="large m-auto">{this.state.request_history[this.state.history_request_id].request["slot_number"]}</span>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="my-auto" lg="4" md="4" sm="4" >
                                                        <span className="large m-auto">Created On</span>
                                                    </Col>
                                                    <Col className="my-auto" lg="1" md="1" sm="1" >
                                                        <span className="large m-auto">:</span>
                                                    </Col>
                                                    <Col className="my-auto" lg="6" md="6" sm="6" >
                                                        <span className="large m-auto">{this.state.request_history[this.state.history_request_id].request["created_on"]}</span>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="my-auto" lg="4" md="4" sm="4" >
                                                        <span className="large m-auto">Count</span>
                                                    </Col>
                                                    <Col className="my-auto" lg="1" md="1" sm="1" >
                                                        <span className="large m-auto">:</span>
                                                    </Col>
                                                    <Col className="my-auto" lg="6" md="6" sm="6" >
                                                        <span className="large m-auto">{this.state.request_history[this.state.history_request_id].request["count"]}</span>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="my-auto" lg="4" md="4" sm="4" >
                                                        <span className="large m-auto">Sent Count</span>
                                                    </Col>
                                                    <Col className="my-auto" lg="1" md="1" sm="1" >
                                                        <span className="large m-auto">:</span>
                                                    </Col>
                                                    <Col className="my-auto" lg="6" md="6" sm="6" >
                                                        <span className="large m-auto">{this.state.request_history[this.state.history_request_id].request["sent_count"]}</span>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="my-auto" lg="4" md="4" sm="4" >
                                                        <span className="large m-auto">User's {this.state.request_history[this.state.history_request_id].request["is_username"]?"Username":"Phone Number"}</span>
                                                    </Col>
                                                    <Col className="my-auto" lg="1" md="1" sm="1" >
                                                        <span className="large m-auto">:</span>
                                                    </Col>
                                                    <Col className="my-auto" lg="6" md="6" sm="6" >
                                                        <span className="large m-auto">{this.state.request_history[this.state.history_request_id].request["is_username"]?this.state.request_history[this.state.history_request_id].request["username"]:this.state.request_history[this.state.history_request_id].request["phone_number"]}</span>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="my-auto" lg="4" md="4" sm="4" >
                                                        <span className="large m-auto">Status</span>
                                                    </Col>
                                                    <Col className="my-auto" lg="1" md="1" sm="1" >
                                                        <span className="large m-auto">:</span>
                                                    </Col>
                                                    <Col className="my-auto" lg="6" md="6" sm="6" >
                                                        <span className="large m-auto">{this.state.request_history[this.state.history_request_id].request["reason"]?this.state.request_history[this.state.history_request_id].request["reason"]:"-"}</span>
                                                    </Col>
                                                </Row>
                                                {this.state.request_history[this.state.history_request_id].request["completed"]?
                                                    <Row>
                                                        <Col className="my-auto" lg="4" md="4" sm="4" >
                                                            <span className="large m-auto">Completed On</span>
                                                        </Col>
                                                        <Col className="my-auto" lg="1" md="1" sm="1" >
                                                            <span className="large m-auto">:</span>
                                                        </Col>
                                                        <Col className="my-auto" lg="6" md="6" sm="6" >
                                                            <span className="large m-auto">{this.state.request_history[this.state.history_request_id].request["completed_on"]}</span>
                                                        </Col>
                                                    </Row>
                                                :<></>}
                                            </Col>
                                        </Row>
                                    </CardTitle>
                                </>:<></>}
                            </CardHeader>
                            {this.state.request_history[this.state.history_request_id]?<>
                                <CardBody className="p-3 my-0" style={{display: 'block', maxWidth: '100%', width: '100%', height: 'auto', opacity: 1, transition: 'opacity 1000ms linear'}}>
                                    <SingleUserMessengerTable data={this.state.request_history[this.state.history_request_id].history}/>
                                </CardBody>
                            </>:<></>}
                        </Card>
                    </Col>
                </Row>
                {/* </VisibilitySensor> */}
            </div>
        </>);
    }
}
const mapStateToProps = (state) => ({
    auth: state.auth,
});
export default connect(mapStateToProps)(ChannelUsersMessenger);
