import React from 'react'
// import styled from 'styled-components'
import { useTable, usePagination } from 'react-table'

import BTable from 'react-bootstrap/Table';

import makeData from 'makeData'
import Axios from 'axios'

import Table from "./ServerTable.js"
 




function GetData(start,length,Search,columns,config) {

    // create a promise for the axios request
    console.log('++++++++',config)
    var config={
        ...config.config,
        params:{
            start: start,
            length:length,
            "search[value]":Search
          }
    }
    console.log('++++++++',config)
    var i;
    for (i = 0; i < columns.length; i++) {
        console.log(columns[i].isSorted)
        config.params[`columns[${i}][name]`]=columns[i].name
        config.params[`columns[${i}][searchable]`]=columns[i].searchable
        config.params[`columns[${i}][search][value]`]=columns[i].search_value
        config.params[`columns[${i}][data]`]=columns[i].name
        config.params[`columns[${i}][orderable]`]=columns[i].canSort

        
    }
    var j;
    var i=0;
    for (j = 0; j < columns.length; j++) {
        if (columns[j].isSorted){
            config.params[`order[${i}][column]`]=j
            config.params[`order[${i}][dir]`]=columns[j].isSortedDesc?'desc':'asc'
        i++
        }
        
    }

    

    const promise = Axios.get(`${process.env.REACT_APP_API_URL}/Master_sessions/Master_db_data`,config)

    // using .then, create a new promise which extracts the data
    const dataPromise = promise.then((response) => response.data)

    return dataPromise
}



function App(config) {

//   const columns = React.useMemo(() => [
    var column =  [
    { 
        Header: "app secret", 
        accessor: "app_secret" ,
        name: "app_secret" ,
        searchable:true,
        search_value:'',
        canSort:true,
        isSortedDesc: true,
        isSorted:false,

    },
    { 
        Header: "parent app id", 
        accessor: "parent_app_id" ,
        name: "parent_app_id" ,
        searchable:true,
        search_value:'',
        canSort:true,
        isSortedDesc: true,
        isSorted:false,
    },
    { 
        Header: "parent app hash", 
        accessor: "parent_app_hash" ,
        name: "parent_app_hash" ,
        searchable:true,
        search_value:'',
        canSort:true,
        isSortedDesc: true,
        isSorted:false,
    },
    { 
        Header: "ip reg", 
        accessor: "ip_reg" ,
        name: "ip_reg" ,
        searchable:true,
        search_value:'',
        canSort:true,
        isSortedDesc: true,
        isSorted:false,
    },
    { 
        Header: "ip country", 
        accessor: "ip_country" ,
        name: "ip_country" ,
        searchable:true,
        search_value:'',
        canSort:true,
        isSortedDesc: true,
        isSorted:false,
    },
    { 
        Header: "full number", 
        accessor: "full_number" ,
        name: "full_number" ,
        searchable:true,
        search_value:'',
        canSort:true,
        isSortedDesc: true,
        isSorted:false,
    },
    { 
        Header: "phone country", 
        accessor: "phone_country" ,
        name: "phone_country" ,
        searchable:true,
        search_value:'',
        canSort:true,
        isSortedDesc: true,
        isSorted:false,
    },
    { 
        Header: "username", 
        accessor: "username" ,
        name: "username" ,
        searchable:true,
        search_value:'',
        canSort:true,
        isSortedDesc: true,
        isSorted:false,
    },
    { 
        Header: "password", 
        accessor: "password" ,
        name: "password" ,
        searchable:true,
        search_value:'',
        canSort:true,
        isSortedDesc: true,
        isSorted:false,
    },
    { 
        Header: "first", 
        accessor: "first" ,
        name: "first" ,
        searchable:true,
        search_value:'',
        canSort:true,
        isSortedDesc: true,
        isSorted:false,
    },
    { 
        Header: "last", 
        accessor: "last" ,
        name: "last" ,
        searchable:true,
        search_value:'',
        canSort:true,
        isSortedDesc: true,
        isSorted:false,
    },
    { 
        Header: "sex", 
        accessor: "sex" ,
        name: "sex" ,
        searchable:true,
        search_value:'',
        canSort:true,
        isSortedDesc: true,
        isSorted:false,
    },
    { 
        Header: "device", 
        accessor: "device" ,
        name: "device" ,
        searchable:true,
        search_value:'',
        canSort:true,
        isSortedDesc: true,
        isSorted:false,
    },
    { 
        Header: "device model", 
        accessor: "device_model" ,
        name: "device_model" ,
        searchable:true,
        search_value:'',
        canSort:true,
        isSortedDesc: true,
        isSorted:false,
    },
    { 
        Header: "system version", 
        accessor: "system_version" ,
        name: "system_version" ,
        searchable:true,
        search_value:'',
        canSort:true,
        isSortedDesc: true,
        isSorted:false,
    },
    { 
        Header: "app version", 
        accessor: "app_version" ,
        name: "app_version" ,
        searchable:true,
        search_value:'',
        canSort:true,
        isSortedDesc: true,
        isSorted:false,
    },
    { 
        Header: "app id", 
        accessor: "app_id" ,
        name: "app_id" ,
        searchable:true,
        search_value:'',
        canSort:true,
        isSortedDesc: true,
        isSorted:false,
    },
    { 
        Header: "app hash", 
        accessor: "app_hash" ,
        name: "app_hash" ,
        searchable:true,
        search_value:'',
        canSort:true,
        isSortedDesc: true,
        isSorted:false,
    },
    { 
        Header: "created", 
        accessor: "created" ,
        name: "created" ,
        searchable:true,
        search_value:'',
        canSort:true,
        isSortedDesc: true,
        isSorted:false,
    },
    { 
        Header: "last checked", 
        accessor: "last_checked" ,
        name: "last_checked" ,
        searchable:true,
        search_value:'',
        canSort:true,
        isSortedDesc: true,
        isSorted:false,
    },
    { 
        Header: "session string", 
        accessor: d=>d.session_string.slice(0, 10)+"...." ,
        name: "session_string" ,
        searchable:true,
        search_value:'',
        canSort:true,
        isSortedDesc: true,
        isSorted:false,
    },
    { 
        Header: "status", 
        accessor: "status" ,
        name: "status" ,
        searchable:true,
        search_value:'',
        canSort:true,
        isSortedDesc: true,
        isSorted:false,
    },
    { 
        Header: "channels joined", 
        accessor: "channels_joined" ,
        name: "channels_joined" ,
        searchable:true,
        search_value:'',
        canSort:true,
        isSortedDesc: true,
        isSorted:false,
    },
    { 
        Header: "messages sent", 
        accessor: "messages_sent" ,
        name: "messages_sent" ,
        searchable:true,
        search_value:'',
        canSort:true,
        isSortedDesc: true,
        isSorted:false,
    },
    { 
        Header: "last message sent", 
        accessor: "last_message_sent" ,
        name: "last_message_sent" ,
        searchable:true,
        search_value:'',
        canSort:true,
        isSortedDesc: true,
        isSorted:false,
    },
  ]

  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [pageCount, setPageCount] = React.useState(0)
  const [search, setSearch] = React.useState('')
  const [columns, setColumns] = React.useState(column)
  const fetchIdRef = React.useRef(1)

  const fetchData = React.useCallback(({ pageSize, pageIndex,search }) => {
    // This will get called when the table needs new data
    // You could fetch your data from literally anywhere,
    // even a server. But for this example, we'll just fake it.
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current
    console.log(fetchIdRef.current)
    // Set the loading state
    setLoading(true)
    
    // We'll even set a delay to simulate a server here
      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex
        const endRow = startRow + pageSize

        GetData(startRow,pageSize,search,columns,config).then(data => {
            setData(data.data)
            setPageCount(Math.ceil(data.recordsFiltered / pageSize))
            setLoading(false)
        }).catch(err => console.log(err))


      }
    
  }, [])

  return (

        <Table
        columns={columns}
        setColumns={setColumns}
        data={data}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        search={search}
        setSearch={setSearch}
      />

      

  )
}

export default App
