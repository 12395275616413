
import React from "react";
import { connect } from "react-redux";
import axios from "axios.js";
import Swal from 'sweetalert2'
import Loader from 'variables/loading_css.js'
import updateprofilebtnicon from "assets/img/updateprofilebtnicon.png";
import changepasswordicon from "assets/img/changepassword.png";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

class User extends React.Component {
  constructor(){
    super()
    
    this.state={
      username:'Loading...',
      first_name:'Loading...',
      last_name:'Loading...',
      email:'loading...',
      new_password:'',
      confirm_password:'',
      copy_btn_msg: "CLICK TO COPY",
    }
  }
  componentDidMount=()=>{
    this.get_current_data()

  }
  get_current_data=()=>{
    
    axios({
        url: this.props.auth.BaseUrl+'api/auth/get_user_data',
        headers:this.props.auth.config.headers,
        method: 'GET',
      }).then((response) => {
        console.log(response.data)
        this.setState({
            username:response.data.user_data.username,
            first_name:response.data.user_data.first_name,
            last_name:response.data.user_data.last_name,
            email:response.data.user_data.email,
        })
          
        
      });
}

copyAPIKey = () => {
  let key = localStorage.getItem('jal_api_key')
  navigator.clipboard.writeText(key);
  this.setState({copy_btn_msg: "API KEY COPIED !"})
  window.alert(`${key} API Key is Copied...!`)
}

set_user_data=(event)=>{
  event.preventDefault();
  Swal.fire({
    title: 'Are you sure?',
    text:'Do you want to update the details?',
    showDenyButton: true,
    // showCancelButton: true,
    icon:'warning',
    confirmButtonText: `yes`,
    denyButtonText: `Cancel`,
  }).then((result) => {
    if (result.isConfirmed ) {      
          var config
          config = {
              ...this.props.auth.config,
          }
          config.headers['Content-Type'] = 'multipart/form-data'
          const data = new FormData() 
          data.append('first_name', this.state.first_name)
          data.append('last_name', this.state.last_name)
          data.append('email', this.state.email)
          axios({
              url:  this.props.auth.BaseUrl+`api/auth/set_user_data/`, data: data, headers: config.headers,
              method: 'POST',
      
          }).then((response) => {

              Swal.fire({title:response.data.message,closeButton:'ok', icon:response.data.status==200?'success':'error'})


          }).catch(
              function (error) {
                Swal.fire({title:'Please check you internet connect or reload the page ',closeButton:'ok',timer:1000, icon:'error',timerProgressBar:true})
                  return Promise.reject(error)
              }
          )
  
        }
    else if (result.isDenied) {
      Swal.fire('Canceled', '', 'info')
    }
    else{
      Swal.fire('No Message Found', 'Please add message or media to continue', 'error')
    }
    
      
});
}
change_password=(event)=>{
  event.preventDefault();
  if (this.state.new_password===this.state.confirm_password){
    
    Swal.fire({
      title: 'Are you sure?',
      text:'Do you want to Change the password?',
      showDenyButton: true,
      // showCancelButton: true,
      icon:'warning',
      confirmButtonText: `yes`,
      denyButtonText: `Cancel`,
    }).then((result) => {
      if (result.isConfirmed ) {      
            var config
            config = {
                ...this.props.auth.config,
            }
            config.headers['Content-Type'] = 'multipart/form-data'
            const data = new FormData() 
            data.append('new_password', this.state.new_password)
            data.append('confirm_password', this.state.confirm_password)
            axios({
                url:  this.props.auth.BaseUrl+`api/auth/change_password/`, data: data, headers: config.headers,
                method: 'POST',
        
            }).then((response) => {
              this.setState({
                new_password:'',
                confirm_password:'',
            })
                Swal.fire({title:response.data.message,closeButton:'ok', icon:response.data.status==200?'success':'error'})
  
  
            }).catch(
                function (error) {
                  Swal.fire({title:'Please check you internet connect or reload the page ',closeButton:'ok',timer:1000, icon:'error',timerProgressBar:true})
                    return Promise.reject(error)
                }
            )
    
          }
      else if (result.isDenied) {
        Swal.fire('Canceled', '', 'info')
      }
      else{
        Swal.fire('No Message Found', 'Please add message or media to continue', 'error')
      }
    });
  }
  else{
    Swal.fire({
      title: 'Password is not matching',
      text:'Please check if both password is same',
      icon:'error',
    })
  }
    
      
}

  render() {
    return (
      <>
        <div className="content">
          <Row>
          
            <Col md="12" s='12'>
            <Card className="card-user card-edit card-header">
              
                <CardHeader >
                  <CardTitle tag="h5">Edit Profile</CardTitle>
                </CardHeader>
                <CardBody>
                <form  onSubmit={this.set_user_data}>
                    <Row>
                    <Col md="6"size="sm" s='6'>
                            <label>First Name</label>
                            <input value={this.state.first_name}
                              onChange={(e) => { this.setState({ first_name: e.target.value }) }}
                              placeholder="Company" className='form-control square'
                              type="text" />
                            
  
                      </Col>
                    <Col md="6"size="sm" s='6'>
                    <label>User Name</label>
                            
                            <input disabled={true}
                            value={this.state.username}
                            placeholder="Username"
                            type="text"  className='form-control square '/>
                    </Col>

                            
                    </Row>
                    <Row>
                    <Col md="6"size="sm" s='6'>
                    <label>Last Name</label>
                            
                            <input value={this.state.last_name}
                              onChange={(e) => { this.setState({ last_name: e.target.value }) }}
                              placeholder="Last Name"
                              type="text"  className='form-control square '/>
                    </Col>
                    <Col md="6"size="sm" s='6'>
                    <label htmlFor="exampleInputEmail1">Email address</label>
                            
                            <input placeholder="Email" type="email" 
                          value={this.state.email}
                          onChange={(e) => { this.setState({ email: e.target.value }) }} className='form-control square '/>
                    </Col>
  
                    </Row>
                    <Row>
                        <Col lg="3" md="3" sm="12"  >
                          <button  className="btn btn-new btn-sm"
                          type="submit" ><img class="img-responsive-left-icon" src={updateprofilebtnicon}  alt="logo " />Update Profile</button>
                        </Col>
                    </Row>

                  </form>
                </CardBody>
              </Card>
            </Col>

            <Col md="12" s='12'>
              <Card>
                <CardTitle>
                  <Button onClick={this.copyAPIKey} className="float-left ml-3">JAL Lambda API Key : {'*'.repeat(8) + localStorage.getItem('jal_api_key').slice(8, 16) + '*'.repeat(8) + localStorage.getItem('jal_api_key').slice(24, -8) + '*'.repeat(8)}</Button>
                  <Button onClick={this.copyAPIKey} className="btn btn-outline-green float-right mr-3">{this.state.copy_btn_msg}</Button>
                </CardTitle>
              </Card>
            </Col>

            <Col md="12" s='12'>
              <Card className="card-user">
              <CardHeader>
                  <CardTitle tag="h5">Change Password</CardTitle>
                </CardHeader>
                <CardBody>
                  <form  onSubmit={this.change_password}>
                    <Row>
                    <Col md="6"size="sm" s='6'>
                            <label className="card label card-user">Enter New Password</label>
                           
                            <input value={this.state.new_password}
                            onChange={(e) => { this.setState({ new_password: e.target.value }) }}
                            
                            type="password" className='form-control square'
                               />
                            
  
                      </Col>
                    </Row>
                    <Row>
                    <Col md="6"size="sm" s='6'>
                    <label  className="card label card-user">Confirm New Password</label>
                            
                            <input  value={this.state.confirm_password}
                            onChange={(e) => { this.setState({ confirm_password: e.target.value }) }}
                            disabled={this.state.new_password?false:true}
                            type="password" className='form-control square '/>
                    </Col>
                    <Col md="6" size="sm" s='6'  >
                          <button  className="btn btn-click glowing-border btn-sm"
                          type="submit" ><img class="img-responsive-left-icon" src={changepasswordicon}  alt="logo " />Change Password</button>
                        </Col>
                    </Row>
                    

                  </form>
                </CardBody>
              </Card>
              </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps)(User);


