import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <nav className="navbar fixed-bottom navbar-expand-lg navbar-light bg-light mt-4">
        <div className="container">
          <span className="text-center">
            <a href="https://www.spiderlabweb.com" target="_blank" rel="noopener noreferrer">
              Santosh Service
            </a>
            &copy; . Made with  {" "}
            <a href="t.me/Muruganandham1998" target="_blank" rel="noopener noreferrer">
              Muruganandham1998
            </a>
          </span>
        </div>
      </nav>
    );
  }
}
