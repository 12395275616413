import React from 'react'
// import styled from 'styled-components'
import { useTable, usePagination } from 'react-table'

import BTable from 'react-bootstrap/Table';

import makeData from 'makeData'
import Axios from 'axios'

import Table from "./ServerTable.js"
 





function GetData(start,length,Search,columns,config) {

    // create a promise for the axios request
    var config={
        ...config.config,
        params:{
            start: start,
            length:length,
            "search[value]":Search
          }
    }
    var i;
    for (i = 0; i < columns.length; i++) {
        console.log(columns[i].isSorted)
        config.params[`columns[${i}][name]`]=columns[i].name
        config.params[`columns[${i}][searchable]`]=columns[i].searchable
        config.params[`columns[${i}][search][value]`]=columns[i].search_value
        config.params[`columns[${i}][data]`]=columns[i].name
        config.params[`columns[${i}][orderable]`]=columns[i].canSort

        
    }
    var j;
    var i=0;
    for (j = 0; j < columns.length; j++) {
        if (columns[j].isSorted){
            config.params[`order[${i}][column]`]=j
            config.params[`order[${i}][dir]`]=columns[j].isSortedDesc?'desc':'asc'
        i++
        }
        
    }

    

    const promise = Axios.get(`${process.env.REACT_APP_API_URL}/messenger/messenger_data`,config)

    // using .then, create a new promise which extracts the data
    const dataPromise = promise.then((response) => response.data)

    return dataPromise
}



function Messenger_table(config) {
    console.log(config,'props')

    var column =  [
    {
        Header: "by_user", 
        accessor: "by_user__username" ,
        name: "by_user__username" ,
        searchable:true,
        search_value:'',
        canSort:false,
        isSortedDesc: true,
        isSorted:false,

    },
    { 
        Header: "username", 
        accessor: "username" ,
        name: "username" ,
        searchable:true,
        search_value:'',
        canSort:false,
        isSortedDesc: true,
        isSorted:false,
    },
    { 
        Header: "full_phone", 
        accessor: "full_phone" ,
        name: "full_phone" ,
        searchable:true,
        search_value:'',
        canSort:false,
        isSortedDesc: true,
        isSorted:false,
    },
    { 
        Header: "created_on", 
        accessor: "created_on" ,
        name: "created_on" ,
        searchable:true,
        search_value:'',
        canSort:false,
        isSortedDesc: false,
        isSorted:false,
    },{ 
        Header: "updated_at", 
        accessor: d=>{ 

            return (
                <span>{new Date(d.updated_at).toLocaleString()}</span>
            )
        },
        name: "updated_at" ,
        searchable:true,
        search_value:'',
        canSort:true,
        isSortedDesc: true,
        isSorted:true,
    },
    { 
        Header: "status", 
        accessor: "status" ,
        name: "status" ,
        searchable:true,
        search_value:'',
        canSort:false,
        isSortedDesc: false,
        isSorted:false,
    },{ 
        Header: "Message sent", 
        accessor: "Added_to" ,
        name: "Added_to" ,
        searchable:true,
        search_value:'',
        canSort:false,
        isSortedDesc: false,
        isSorted:false,
    },

  ]

  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [pageCount, setPageCount] = React.useState(0)
  const [autoFetch, setAutoFetch] = React.useState(true)
  const [search, setSearch] = React.useState('')
  const [columns, setColumns] = React.useState(column)
  const fetchIdRef = React.useRef(1)

  const fetchData = React.useCallback(({ pageSize, pageIndex,search }) => {
    const fetchId = ++fetchIdRef.current
    console.log(fetchIdRef.current)
    // Set the loading state
    setLoading(true)
    
    // We'll even set a delay to simulate a server here
      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex
        const endRow = startRow + pageSize

        GetData(startRow,pageSize,search,columns,config).then(data => {
            setData(data.data)
            setPageCount(Math.ceil(data.recordsFiltered / pageSize))
            setLoading(false)
        }).catch(err => console.log(err))


      }
    
  }, [])
  React.useEffect(() => {
    const interval = setInterval(() => {
        console.log(config.auto_refresh,'Auto_refresh')
        if (config.auto_refresh){
            const data=search
            setAutoFetch(search+'ssss')
            setAutoFetch(search.replace('ssss',''))
            console.log(search)
        }
       

    }, 5000);
    return () => clearInterval(interval);
  }, [config.auto_refresh]);

  return (

        <Table
        columns={columns}
        setColumns={setColumns}
        data={data}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        search={search}
        setSearch={setSearch}
        autoFetch={autoFetch}
        setAutoFetch={setAutoFetch}
      />

      

  )
}

export default Messenger_table
