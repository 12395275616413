
import axios from "axios.js";
import React from "react";
import { connect } from "react-redux";
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import Adder_table from 'views/Adder_table.js'
import NotificationAlert from "react-notification-alert";
import Swal from 'sweetalert2'
// react plugin used to create charts
import { Line, Pie } from "react-chartjs-2";
import withReactContent from 'sweetalert2-react-content'
import Loader_gif from "../assets/img/loader.gif"
import Select from 'react-select'
import JsonTable from "views/Simple_table.js";

import AddremoveTable from 'views/addremove_table.js'
import VisibilitySensor from 'react-visibility-sensor';





// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
    Button,
} from "reactstrap";
// core components
const MySwal = withReactContent(Swal)

class AddRemove extends React.Component {
    constructor() {
        super()

        this.state = {

            count_added: 0,
            count_removed: 0,
            count_reported: 0,
            target_channel: "",
            current_progress_no: 1,
            progress_list: {},
            number_of_process: 1,
            active_session:'Loading..',
            active_session_cat:0,
            max_session:100,
            spam: 'No',
            proxy: 'True',
            current_process: "",
            message_select:[],
            status: "",
            loading:true,
            loading_history:false,
            loading_url:false,
            channel_id:'',
            counties:[],
            history:null,
            history_visibility:false,
            count_visibility:true,
            messages:[
                {message:'Loading..',
                chat_id:'1',
                views:1231,
            },
            ],
            form_load: true,
            count_req_gen: false,
            categories: {},
            selected_category: 1,
            category_count_load: false,
        }
    }
    counties = ()=>{
        return [
        {label:'ru',value:'ru'},
        {label:'kz',value:'kz'},
        {label:'ua',value:'ua'},
        {label:'ro',value:'ro'},
        {label:'uk',value:'uk'},
        {label:'ar',value:'ar'},
        {label:'br',value:'br'},
        {label:'vn',value:'vn'},
        {label:'de',value:'de'},
        {label:'ge',value:'ge'},
        {label:'eg',value:'eg'},
        {label:'il',value:'il'},
        {label:'id',value:'id'},
        {label:'es',value:'es'},
        {label:'kh',value:'kh'},
        {label:'ca_v',value:'ca_v'},
        {label:'ke',value:'ke'},
        {label:'co',value:'co'},
        {label:'lv',value:'lv'},
        {label:'lt',value:'lt'},
        {label:'my',value:'my'},
        {label:'mx',value:'mx'},
        {label:'nl',value:'nl'},
        {label:'nz',value:'nz'},
        {label:'py',value:'py'},
        {label:'pl',value:'pl'},
        {label:'pt',value:'pt'},
        {label:'us',value:'us'},
        {label:'ph',value:'ph'},
        {label:'fr',value:'fr'},
        {label:'hr',value:'hr'},
        {label:'se',value:'se'},
        {label:'ee',value:'ee'},
        ]

    }
    componentDidMount = () => {
        this.select_progress()
        this.get_all_categories()
        // this.get_history()
        this.interval2 = setInterval(() => this.get_input_count(), 3000);
    }
    componentWillUnmount() {
        clearInterval(this.interval2);


    }

    notificationAlert = React.createRef();

    get_progress_list = () => {
        console.log('get_all_user_progress')
        axios({
            url: this.props.auth.JALBaseUrl + `addremove/addremove_progress_list`,
            headers: this.props.auth.config.headers,
            method: 'GET',
        }).then((response) => {
            let data = response.data
            this.setState({
                progress_list: data.progress_list,
            })
        }).then((resp) => {
            console.log("Progress List Data: ")
            console.log(this.state.progress_list)
        })
    };

    get_all_categories = () => {
        console.log('get_all_categories')
        axios({
            url: this.props.auth.JALBaseUrl + `addremove/get_all_categories`,
            headers: this.props.auth.config.headers,
            method: 'GET',
        }).then((response) => {
            let data = response.data
            this.setState({
                categories: data.categories,
            })
        }).then((resp) => {
            console.log("Categories: ")
            console.log(this.state.categories)
        })
    };

    get_input_count = (refresh=true) => {
        // clearInterval(this.interval2);
        if (refresh === false){
            this.setState({loading:true, form_load:true})
        }
        console.log('get_input_count')
        if ((this.state.count_visibility) || (refresh === false)){
            if ((this.state.count_req_gen === false) || (refresh === false)){
                console.log("Request Generated...!")
                if (refresh === true){
                    this.setState({count_req_gen:true})
                }
                axios({
                    url: this.props.auth.JALBaseUrl + `addremove/addremove_data_count?option=count&progress_no=${this.state.current_progress_no}&category=${this.state.selected_category}`,
                    headers: this.props.auth.config.headers,
                    method: 'GET',
                }).then((response) => {
                    let data = response.data
                    this.setState({
                        count_added: data.count_added,
                        count_removed: data.count_removed,
                        count_reported: data.count_reported,
                        active_session: data.active_session,
                        current_process: data.current_process,
                        active_session_cat:data.active_session_cat,
                        status: data.running_status,
                        messages:data.messages?data.messages:[],
                        loading_url:false,
                    })
                    if (this.state.category_count_load){
                        if (data.category_id === this.state.selected_category){
                            this.setState({
                                category_count_load: false
                            })
                        }
                    }
                    if (this.state.target_channel === '' || this.state.status === 'joining') {
                        this.setState({
                            target_channel: data.target_channel,
                            channel_id:data.channel_id,
                            loading_url:false,
                        })
                    }
                    if (refresh === false){
                        this.setState({
                            target_channel: data.target_channel,
                            channel_id:data.channel_id,
                            loading_url:false,
                            loading:false,
                            form_load:false,
                        })
                    }
                    else {
                        this.setState({count_req_gen:false})
                    }
                });
            }
        }
    }
    
    select_progress = () => {
        this.get_input_count(false)
        this.get_progress_list()
    }

    get_history = () => {
        
        this.setState({loading_history:true,})
        console.log('Loading history')
        axios({
            url: this.props.auth.JALBaseUrl + `addremove/addremove_data_count?option=history`,
            headers: this.props.auth.config.headers,
            method: 'GET',
        }).then((response) => {
            let data = response.data
            
            if (data.history.length!==0){
                console.log(data.history)
                this.setState({
                    history:data.history,
                    loading_history:false,
                })

            }else{
                this.setState({
                    history:[{'No Data Found':'-'}],
                    loading_history:false,
                })

            }
        });
    }
    
    stop_process = () => {

        axios({
            url: this.props.auth.JALBaseUrl + `addremove/addremove_stop?progress_no=${this.state.current_progress_no}`,
            headers: this.props.auth.config.headers,
            method: 'GET',
        }).then((response) => {
            this.select_progress()
        });
    }

    restart_process = () => {
        axios({
            url: this.props.auth.JALBaseUrl + `addremove/refresh_workers?progress_no=${this.state.current_progress_no}`,
            headers: this.props.auth.config.headers,
            method: 'GET',
        }).then((response) => {
            Swal.fire(response.data.message, '', 'success')
            this.select_progress()
        });
    }

    getFullStatus = (v) => {
        if (this.state.adder_running | v == 'inti') {
            axios({
                url: this.props.auth.BaseUrl + `api/auth/status?key=full_status`,
                headers: this.props.auth.config.headers,
                method: 'GET',
            }).then((response) => {

                this.setState({
                    adder_status: response.data.data
                })

            });
        }

    }

    update_target = () => {
        axios({
            url: this.props.auth.JALBaseUrl + `addremove/update_target?progress_no=${this.state.current_progress_no}&Target_channel=${this.state.target_channel}`,
            headers: this.props.auth.config.headers,
            method: 'GET',
        }).then((response) => {
            this.select_progress()
        });
    }

    start_join = (action) => {

        const pattern = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
        const result = pattern.test(this.state.target_channel);
        if (result === true) {
            Swal.fire({
                title: `Are you sure start the ${action} process with the    current target`,
                showDenyButton: true,
                // showCancelButton: true,
                confirmButtonText: `Start`,
                denyButtonText: `Cancel`,
            }).then((result) => {
                if (result.isConfirmed) {
                    
                    axios({
                        url: this.props.auth.JALBaseUrl + `addremove/addremove_init?Target_channel=${this.state.target_channel}&action=${action}&number_of_process=${this.state.number_of_process}&spam=${this.state.spam}&max_session=${this.state.max_session}&chat_id=${JSON.stringify(this.state.message_select)}&countries=${JSON.stringify(this.state.counties)}&proxy=${JSON.stringify(this.state.proxy)}&channel_id=${this.state.channel_id}&progress_no=${this.state.current_progress_no}&category=${this.state.selected_category!=-1?this.state.categories[this.state.selected_category].id:null}`,
                        headers: this.props.auth.config.headers,
                        method: 'GET',
                    }).then((response) => {
                        Swal.fire(response.data.message, '', 'success')
                        this.select_progress()

                    });
                }
                else if (result.isDenied) {
                    Swal.fire('Canceled', '', 'info')
                }

            });
        } else {
            Swal.fire({
                title: 'Please enter a valid url',
                icon: 'error'
                // showCancelButton: true,
            })
        }


    }
    prefetch = () => {

        const pattern = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
        const result = pattern.test(this.state.target_channel);
        if (result === true) {

                    this.setState({loading:true})
                    axios({
                        url: this.props.auth.JALBaseUrl + `addremove/addremove_init?Target_channel=${this.state.target_channel}&action=prefetch&progress_no=${this.state.current_progress_no}`,
                        headers: this.props.auth.config.headers,
                        method: 'GET',
                    }).then((response) => {
                        Swal.fire(response.data.message, '', 'success')

                        this.setState({
                            loading:false,
                            messages:response.data.messages?response.data.messages:[]
                        })

                    });


        } else {
            Swal.fire({
                title: 'Please enter a valid url',
                icon: 'error'
                // showCancelButton: true,
            })
        }


    }






    Auto_refresh_flag = () => {
        alert('called')
        return this.state.adder_running
    }

    render() {
        return (
            <>
                <div className="content">

                    <Row>
                        <NotificationAlert ref={this.notificationAlert} /> 
                        <VisibilitySensor
                    partialVisibility
                onChange={(isVisible) => {

                    this.setState({count_visibility: isVisible})
                    

                }}
            >
                <>
                        <Col lg="4" md="6" sm="6">
                            <Card className="card-stats card border-green joinleave">
                                <CardBody>
                                    <Row>
                                        <Col md="3" xs="3">
                                            <div className="icon-big text-center icon-warning">
                                            <a><img className="join-joined-icon" /></a>
                                            </div>
                                        </Col>

                                        <Col md="9" xs="9">
                                            <div className="numbers">
                                                <p className="card-category">joined</p>
                                                
                                                <CardTitle className="tle-joinleave" tag="p">{this.state.loading?<i className='fa fa-refresh icon-spin'></i>:this.state.count_added}</CardTitle>
                                                <p />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <hr />
                                    <div className="stats">
                                        <i className="fas fa-sync-alt" /> {this.state.status}
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>

                        <Col lg="4" md="6" sm="6">
                            <Card className="card-stats card border-green joinleave">
                                <CardBody>
                                    <Row>
                                        <Col md="3" xs="3">
                                            <div className="icon-big text-center icon-warning">
                                            <a><img className="join-settings-icon" /></a>
                                            </div>
                                        </Col>
                                        <Col md="9" xs="9">
                                            <div className="numbers">
                                                <p className="card-category lft">Left </p>
                                                <CardTitle className="tle-lft" tag="p">{this.state.loading?<i className='fa fa-refresh icon-spin'></i>:this.state.count_removed}</CardTitle>
                                                <p />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <hr />
                                    <div className="stats">
                                        <i className="sas" />
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>

                        <Col lg="4" md="6" sm="6">
                            <Card className="card-stats card border-green joinleave">
                                <CardBody>
                                    <Row>
                                        <Col md="3" xs="3">
                                            <div className="icon-big text-center icon-warning">
                                            <a><img className="join-dead-icon" /></a>
                                            </div>
                                        </Col>
                                        <Col md="9" xs="9">
                                            <div className="numbers">
                                                <p className="card-category atve">Reported</p>
                                                <CardTitle className="tle-actve" tag="p">{this.state.loading?<i className='fa fa-refresh icon-spin'></i>:this.state.count_reported}</CardTitle>
                                                <p />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <hr />
                                    <div className="stats">
                                    <i className="sas" />
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>

                        <Col lg="6" md="6" sm="6">
                            <Card className="card-stats card border-green joinleave">
                                <CardBody>
                                    <Row>
                                        <Col md="3" xs="3">
                                            <div className="icon-big text-center icon-warning">
                                            <a><img className="join-settings-icon" /></a>
                                            </div>
                                        </Col>
                                        <Col md="9" xs="9">
                                            <div className="numbers">
                                                <p className="card-category atve">Active Users In</p>
                                                <CardTitle className="tle-actve" tag="p">{this.state.loading?<i className='fa fa-refresh icon-spin'></i>:this.state.category_count_load?<i className='fa fa-refresh icon-spin'></i>:this.state.active_session_cat}</CardTitle>
                                                <p />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <hr />
                                    <div className="stats text-center">
                                    <i className="sas" />Category : {this.state.categories[this.state.selected_category]?this.state.categories[this.state.selected_category].category:""}
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>

                        <Col lg="6" md="6" sm="6">
                            <Card className="card-stats card border-green joinleave">
                                <CardBody>
                                    <Row>
                                        <Col md="3" xs="3">
                                            <div className="icon-big text-center icon-warning">
                                            <a><img className="join-settings-icon" /></a>
                                            </div>
                                        </Col>
                                        <Col md="9" xs="9">
                                            <div className="numbers">
                                                <p className="card-category atve">Active Users</p>
                                                <CardTitle className="tle-actve" tag="p">{this.state.loading?<i className='fa fa-refresh icon-spin'></i>:this.state.active_session}</CardTitle>
                                                <p />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <hr />
                                    <div className="stats">
                                    <i className="sas" />
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                        </>
                        </VisibilitySensor>
                        <Col lg="12" md="12" sm="12">
                            <Card className="card-stats border-info">
                                <CardBody className="p-3">
                                    <>
                                        <Row>
                                            <Col lg="6" md="6" sm="12">
                                                {this.state.form_load?<div id="loader"></div>:<></>}
                                                <div className="my-3 ">
                                                    <label>Progress:</label>
                                                    <select className='form-control square col-sm-12 col-lg-12 col-12 m-1' readOnly={this.state.form_load} value={this.state.current_progress_no} onChange={(e) => { this.setState({ current_progress_no: e.target.value,loading_url:true}, () => this.select_progress())}}>
                                                        {new Array(11).fill("", 1, 11).map( (p, i) => <option value={i} >Progress {i} - {this.state.progress_list[i]}</option>)}
                                                    </select>
                                                    <label className="labelfont">Enter the group link:</label> {this.state.loading_url?<i className='fa fa-refresh icon-spin'></i>:'' }
                                                    <input type="url" className='form-control square col-sm-12 col-lg-12 col-12 m-1' readOnly={this.state.status==='joining'?true:this.state.form_load} value={this.state.target_channel} onChange={(e) => { this.setState({ target_channel: e.target.value }) }} placeholder="Insert the Group to be added" />
                                                    <label className="labelfont">Channel id:</label>
                                                    <input type="text" className='form-control square col-sm-12 col-lg-12 col-12 m-1' readOnly={this.state.status==='joining'?true:this.state.form_load} value={this.state.channel_id} onChange={(e) => { this.setState({ channel_id: e.target.value }) }}    placeholder="This will be fetched automatically" />
                                                    <label>Enter the Maximum user count:</label>
                                                    <input type="number" className='form-control square col-sm-12 col-lg-12 col-12 m-1' readOnly={this.state.form_load} value={this.state.max_session} onChange={(e) => { this.setState({ max_session: e.target.value }) }} placeholder="Enter Max number of users" />
                                                    <label>Category:</label>
                                                    <select className='form-control square col-sm-12 col-lg-12 col-12 m-1' readOnly={this.state.form_load} value={this.state.selected_category} onChange={(e) => { this.setState({ selected_category: e.target.value, category_count_load: true}); this.get_input_count()}}>
                                                        {/* <option value={-1} >Default</option> */}
                                                        {Object.keys(this.state.categories).map( key => <option value={this.state.categories[key].id} >{this.state.categories[key].category}</option>)}
                                                        {/* {this.state.categories.map(function(category, idx){return(<option value={idx} >{category.category}</option>)})} */}
                                                    </select>
                                                    {this.state.selected_category!=-1 ? 
                                                        <>
                                                            <label>Description:</label>
                                                            <textarea type="text" className='form-control square col-sm-12 col-lg-12 col-12 m-1 p-2' readOnly={true} placeholder={this.state.categories[this.state.selected_category]?this.state.categories[this.state.selected_category].description:"No Description"} />
                                                            <label>Price:</label>
                                                            <input type="number" className='form-control square col-sm-12 col-lg-12 col-12 m-1' readOnly={true} placeholder={this.state.categories[this.state.selected_category]?this.state.categories[this.state.selected_category].price:0} />
                                                        </>
                                                        :
                                                        <></>
                                                    }

                                                    <label>Select Countries:</label>
                                                    <Select className='form-control square black col-sm-12 col-lg-12 col-12 m-1' disabled={this.state.form_load} onChange={(value) => { this.setState({ counties: value,error:'' }) }} name='country' id='country' options={this.counties()} isMulti closeMenuOnSelect={false}    />
                                                    <label>Report Channel or Group(Optional):</label>
                                                    <select className='form-control square col-sm-12 col-lg-12 col-12 m-1' readOnly={this.state.form_load} value={this.state.spam} onChange={(e) => { this.setState({ spam: e.target.value }) }}>
                                                        <option value={'No'} >Don't Report</option>
                                                        <option value={'InputReportReasonSpam'} >Spam</option>
                                                        <option value={'InputReportReasonFake'} >Fake Account</option>
                                                        <option value={'InputReportReasonViolence'} >Violence</option>
                                                        <option value={'InputReportReasonPornography'} >Pornography</option>
                                                        <option value={'InputReportReasonChildAbuse'} >ChildAbuse</option>
                                                        <option value={'InputReportReasonGeoIrrelevant'} >Geo Irrelevant</option>
                                                        <option value={'InputReportReasonCopyright'} >Copyright</option>
                                                        <option value={'InputReportReasonIllegalDrugs'} >Illegal Drugs</option>
                                                        <option value={'InputReportReasonOther'} >Other</option>
                                                    </select>
                                                    <label>Proxy:</label>
                                                    <select className='form-control square col-sm-12 col-lg-12 col-12 m-1' readOnly={this.state.form_load} value={this.state.proxy} onChange={(e) => { this.setState({ proxy: e.target.value }) }}>
                                                        <option value={'True'} >Enable Proxy</option>
                                                        <option value={'False'} >Disable Proxy</option>
                                                    </select>
                                                    <label>Number of process:</label>
                                                    <select className='form-control square col-sm-12 col-lg-12 col-12 m-1' readOnly={this.state.form_load} value={this.state.number_of_process} onChange={(e) => { this.setState({ number_of_process: e.target.value }) }}>
                                                        {new Array(51).fill("", 1, 51).map((p, i) => 
                                                        <>
                                                        <option value={i} >{i} Process</option>
                                                        </>
                                                        )}
                                                    </select>
                                                    
                                                 
                                                    {this.state.status !== 'stopped' ?
                                                        <>
                                                            <div className='row'>
                                                                <div className="col-2">
                                                                    <button className='btn btn-danger' onClick={(e) => this.stop_process()} >Stop</button>
                                                                </div>
                                                                <div className="col-2">
                                                                    <button className='btn btn-info bg-blue' onClick={(e) => this.restart_process()} >Restart</button>
                                                                </div>
                                                                <div className="col-8 text-center pt-3 text-muted text-white-50">
                                                                    status : {this.state.status}...
                                                                                    </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <row>
                                                                
                                                                <button onClick={() => this.start_join('join')} disabled={this.state.form_load} className="btn btn-lg btn-info    col-sm-12 col-lg-12 col-12 bg-green"> <a><img className="join-start-icon" /></a> Start join</button>
                                                                <button onClick={() => this.start_join('leave')} disabled={this.state.form_load} className="btn btn-lg btn-info    col-sm-12 col-lg-12 col-12 bg-red"> <a><img className="join-end-icon" /></a> Start leaving</button>
{/* 
                                                                <button onClick={() => this.update_target()} disabled={this.state.form_load} className="btn btn-lg btn-info    col-sm-12 col-lg-12 col-12 bg-blue"> <a><img className="join-start-icon" /></a>Update Channel</button>
                                                                <button onClick={() => this.start_join('join_instant')} className="btn btn-lg btn-info    col-sm-12 col-lg-12 col-12 bg-green"> <a><img className="join-start-icon" /></a> Start join instant via active users</button>
                                                                <button onClick={() => this.start_join('leave_instant')} className="btn btn-lg btn-info    col-sm-12 col-lg-12 col-12 bg-red"> <a><img className="join-end-icon" /></a> Start leaving instant via active users</button> */}

                                                            </row>
                                                        </>
                                                    }

                                                </div>
                                            </Col>
                                            <Col lg="6" md="6" sm="12">
                                            <label>Messages:</label>
                                                    <div style={{'height':'500px','overflow-y':'auto'}} className='border border-info bg-grey bg-lighten-3 rounded card-stats col-sm-12 col-lg-12 col-12 '>
                                                        {this.state.loading?
                                                        <>
                                                        <img src={Loader_gif} height='400px' alt="Loading" />
                                                        <h4 className='text-center'>Updating...</h4>
                                                        </>
                                                        :
                                                            <>
                                                        
                                                        {this.state.messages?this.state.messages.map((value, count) =>{

                                                             return(
                                                                    <>
                                                                <Card className="mt-2 messages">
                                                                    <CardHeader>
                                                                        {this.state.message_select.indexOf(value.chat_id)===-1?
                                                                        <button className='btn btn-info btn-sm' disabled={this.state.form_load} onClick={()=>
                                                                            this.setState(prevState=>({
                                                                                message_select:[...prevState.message_select,value.chat_id]
                                                                            }))
                                                                            
                                                                            } >Add</button>:
                                                                            <button className='btn btn-danger btn-sm' disabled={this.state.form_load} onClick={()=>{

                                                                                var array = [...this.state.message_select];
                                                                                var index = array.indexOf(value.chat_id)
                                                                                array.splice(index, 1);
                                                                                this.setState({message_select: array});
                                                                            }
                                                                        }>remove</button>}
                                                                    
                                                                    <span className='float-right text-muted'>Message id:{value.chat_id}</span>
                                                                    </CardHeader>
                                                                    <CardBody>
                                                                        {value.message?value.message:<i className='fa fa-image'>Image</i>}
                                                                        </CardBody>
                                                                     <CardFooter>
                                                                         <div className='float-right text-muted'>
                                                                             <i className='fa fa-eye '></i>:{value.views}
                                                                             </div>
                                                                         </CardFooter>
            
                                                                    </Card>
                                                                    </>
                                                                    )



                                                    }):''}
                                                    </>
                                                    }
                                                    </div>
                                                    <button className='btn btn-info col-sm-12 col-lg-12 col-12' disabled={this.state.form_load} onClick={(e) => this.prefetch()} >fetch messages and views</button><br/>
                                                    <small><b>Note:</b>You need to click this to get updated views counts this is <b>Not</b> automatically updated</small>

                                            </Col>

                                        </Row>

                                    </>

                                </CardBody>

                            </Card>
                        </Col>
                        <VisibilitySensor
                    partialVisibility
                onChange={(isVisible) => {

                    this.setState({history_visibility: isVisible})
                    if (this.state.history_visibility){
                        this.state.history?console.log('Loaded Already'):this.get_history()
                    }
                    

                }}
            >
                        <Col lg="12" md="12" sm="12" >
                            <Card className="card-stats border-info">
                                <CardHeader>
                                    <CardTitle>
                                    Channel History <Button onClick={this.get_history} className="btn btn-outline-green float-right font-small-2">Refresh <i className={`fa fa-refresh ${this.state.loading_history?'icon-spin':''} `}></i> </Button> 
                                    </CardTitle>
                                    </CardHeader>
                                <CardBody className="px-3 my-0" style={{
                        display: 'block',
                        maxWidth: '100%',
                        width: '100%',
                        height: 'auto',
                        opacity: this.state.history_visibility ? 1 : 0.25,
                        transition: 'opacity 1000ms linear'
                    }}>
                                {/* <JsonTable data={this.state.history} /> */}
                                {this.state.history?<AddremoveTable data={this.state.history} categories={this.state.categories} />:'Loading..'}
                                
                                    </CardBody>
                                    </Card>
                        </Col>
                        </VisibilitySensor>


                    </Row>
                    <Row>


                    </Row>
                </div>
            </>
        );
    }
}
const mapStateToProps = state => ({
    auth: state.auth
});
export default connect(mapStateToProps)(AddRemove);

