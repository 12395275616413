
import axios from "axios.js";
import React from "react";
import { connect } from "react-redux";
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import Adder_table from 'views/Adder_table.js'
import NotificationAlert from "react-notification-alert";
import Swal from 'sweetalert2'
// react plugin used to create charts
import { Line, Pie } from "react-chartjs-2";
import Loader_gif from "../assets/img/loader.gif"
import Load from "../assets/img/Untitled-2.png"
import startadding from "../assets/img/startadding.png";
import home from "../assets/img/home.png";
import deleteuser from "../assets/img/deleteuser.png";
import msgr from "assets/img/msgr.png";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
// core components


class Adder extends React.Component {
  constructor(){
    super()
    
    this.state={
      has_data_in_adder:false,
      target_channel:'',
      max_user_count:10,
      number_of_process:1,
      user_count:'Loading...',
      added_count:'Loading...',
      live_user_count:'Loading...',
      reason_stopped:null,
      skipped_count:'Loading...',
      adder_running:false,
      process_id:1,
      target_channels:[],
      current_session:'loading...',
      adder_status:{
        counter:0,
        error_count:0
      }
    }
  }
  componentDidMount=()=>{
    console.log('Adder mounted',this.props.auth)
    this.get_input_count()
    this.getFullStatus('inti')
    
    this.interval = setInterval(() => this.getFullStatus(''), 2000);
    this.interval2 = setInterval(() => this.get_input_count(), 10000);
    
}
    componentWillUnmount() {
      clearInterval(this.interval);
      clearInterval(this.interval2);
    }

notificationAlert = React.createRef();



  // specify upload params and url for your files
  getUploadParams = ({ meta }) => {
    var config
    config={ 
        ...this.props.auth.config,
        url: this.props.auth.BaseUrl+'Adder/upload_adder_input' }
    config.headers['Content-Type']='multipart/form-data'
    config.headers['media_type']='*/*'
    config.headers['Content-Disposition']=`attachment; filename=${meta.name}`
    return config 
}
  deleteAdderData= ()=>{
    Swal.fire({
        title: 'Do you want to delete and stop the adder?',
        showDenyButton: true,
        background:`#fff url(${Load})`,
        // showCancelButton: true,
        icon:'warning',
        background:`#fff url(${Load})`,
        confirmButtonText: `Delete all`,
        denyButtonText: `Cancel it`,
      }).then((result) => {
        if (result.isConfirmed) {
            axios({
                url: this.props.auth.BaseUrl+`Adder/adder_data_count?option=delete&process_id=process_${this.state.process_id}`,
                headers:this.props.auth.config.headers,
                method: 'GET',
                }).then((response) => {
                    this.setState({
                        has_data_in_adder:response.data.adder_count>0?true:false,
                        user_count:response.data.adder_count,
                        added_count:response.data.added_count,
                        adder_running:response.data.adder_running,
                        target_channel:response.data.target_channel,
                        skipped_count:response.data.skipped_count,
                    })
                });

          Swal.fire('Deleted!', '', 'success')
        } else if (result.isDenied) {
          Swal.fire({
          title:'Canceled',
          background:`#fff url(${Load})`,
          icon:'error',
          timer:1000,

        })
        }
      })
  }
  // called every time a file's `status` changes
   handleChangeStatus = ({ meta, file }, status) => {  }
  
  // receives array of files that are done uploading when submit button is clicked
    UploadExcelSubmit = (files, allFiles) => {
        var config
        config = {
            ...this.props.auth.config,
        }
        config.headers['Content-Type'] = 'multipart/form-data'
        var files_all = []
        const formData = new FormData();
        files.map(f => {
            formData.append('files', f.file)
            formData.append('process_id', `process_${this.state.process_id}`)
        })
        Swal.fire({title:'Loading your user...',
              background:`#fff url(${Load})`,
              text:'This may take time, based on the users in the file',
              showConfirmButton: false,

            })
        axios({
            url: this.props.auth.BaseUrl + 'Adder/upload_adder_input', data: formData, headers: config.headers,
            method: 'POST',

        }).then((response) => {
              
              Swal.fire({title:'Imported the CSV ',background:`#fff url(${Load})`,closeButton:'ok',timer:1000, icon:'success',timerProgressBar:true})
            this.get_input_count()
            this.getFullStatus('inti')
        }).catch(
            function (error) {
              Swal.fire({title:'File formate is wrong use the correct template ',background:`#fff url(${Load})`,closeButton:'ok',timer:2000, icon:'error',timerProgressBar:true})
                return Promise.reject(error)
            }
        )
    }
    get_input_count=(v='default')=>{
        console.log(this.props.auth.config)
        axios({
            url: this.props.auth.BaseUrl+`Adder/adder_data_count?option=count&process_id=process_${this.state.process_id}`,
            headers:this.props.auth.config.headers,
            method: 'GET',
          }).then((response) => {
            this.setState({
              has_data_in_adder:response.data.adder_count>0?true:false,
              user_count:response.data.adder_count,
              added_count:response.data.added_count,
              adder_running:response.data.adder_running,
              target_channels:response.data.target_channels,
              skipped_count:response.data.skipped_count,
              current_session:response.data.Current_session,
              live_user_count:response.data.live_user_count,
              reason_stopped:response.data.reason_stopped,
              
            })
            if (v==='default'){

                if (this.state.target_channel == '') {
                  this.setState({
                    target_channel: response.data.target_channel,
                  })
                }
                if (this.state.max_user_count == 10) {
                  this.setState({
                    max_user_count:response.data.max_user_count,
                  })
                }
            }
            else{
              this.setState({
                target_channel: response.data.target_channel,
                max_user_count:response.data.max_user_count,
                
              })

            }
              
            
          });
    }
    set_key_values=(key,value)=>{

      axios({
          url: this.props.auth.BaseUrl+`api/auth/status?key=${key}&value=${value}&process_id=process_${this.state.process_id}`,
          headers:this.props.auth.config.headers,
          method: 'GET',
        }).then((response) => {
            this.get_input_count()
            
          
        });
  }
    getFullStatus=(v) =>{
      if (this.state.adder_running | v=='inti'){
        axios({
          url: this.props.auth.BaseUrl+`api/auth/status?key=full_status&process_id=process_${this.state.process_id}`,
          headers:this.props.auth.config.headers,
          method: 'GET',
        }).then((response) => {
            console.log(response.data)
            this.setState({
                  adder_status:response.data.data
            })
            
        });
      }
      
    }


    start_adder_username=()=>{
      Swal.fire({
      icon:'warning',
        title: 'Are you sure start the adding process with the  current Username',
        background:`#000 url(${Load})`,
        showDenyButton: true,

        confirmButtonText: `Start`,
        denyButtonText: `Cancel`,
          

      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({title:'Processing your request',
          background:`#fff url(${Load})`,
          text:'Sending user data to workers to start the adding process',
          showConfirmButton: false,

        })
            axios({
                url: this.props.auth.BaseUrl+`Adder/adder_username_init?Target_channel=${this.state.target_channel}&number_of_process=${this.state.number_of_process}&max_user_count=${this.state.max_user_count}&process_id=process_${this.state.process_id}`,
                headers:this.props.auth.config.headers,
                method: 'GET',
              }).then((response) => {
                if (response.data.status==200){

                  Swal.fire(response.data.message, '', 'success')
                  this.get_input_count()
                }
                else{
                  Swal.fire(response.data.message, '', 'error')
                }
                  
              }).catch(
                function (error) {
                  Swal.fire('Processing Error', 'error', 'error')
                }
              )            }
            else if (result.isDenied) {
              Swal.fire({
          title:'Canceled',
          background:`#fff url(${Load})`,
          icon:'error',
          timer:1000,

        })
            }
          
  });
}

  get_export=()=>{
    axios({
      url: this.props.auth.BaseUrl+'Master_sessions/export_master',config:this.props.auth.config,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      console.log(response)
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
    });

  }
  import_user_from_scraper=()=>{

    Swal.fire({
      icon:'warning',
      title: 'Confirm',
      background:`#fff url(${Load})`,
      text:`Are you sure to import all users from scrapper to adder?`,
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: `Yes import`,
      denyButtonText: `Cancel`,
    }).then((result) => {
      if (result.isConfirmed) {
          axios({
              url: this.props.auth.BaseUrl+`Adder/import_user_from_scraper?process_id=process_${this.state.process_id}`,
              headers:this.props.auth.config.headers,
              method: 'GET',
            }).then((response) => {
              Swal.fire(response.data.message, '', 'success')
                this.get_input_count()
                
            });
          }
          else if (result.isDenied) {
            Swal.fire({
          title:'Canceled',
          background:`#fff url(${Load})`,
          icon:'error',
          timer:1000,

        })
          }
        
});
}
  onChange_process_worker=(e)=>{
    this.setState({ 
      process_id: e.target.value,
      has_data_in_adder:false,

     },()=>{
      this.componentWillUnmount()
      this.componentDidMount()
      this.get_input_count('init')
    })
  }
  Auto_refresh_flag=()=>{
    
    return this.state.adder_running
  }

  render() {
    return (
      <>
        <div className="content">
          <Row>
          <NotificationAlert ref={this.notificationAlert} />

            <Col lg="12" md="12" sm="12">
            <div className="content">
                        {/* <Row>
                          <Col lg="3" md="6" sm="6">
                            <Card className="card-stats">
                              <CardBody>
                                <Row>
                                  <Col md="4" xs="5">
                                    <div className="icon-big text-center icon-warning">
                                      <i className="nc-icon nc-globe text-warning" />
                                    </div>
                                  </Col>
                                  <Col md="8" xs="7">
                                    <div className="numbers">
                                      <p className="card-category">Total Username</p>
                                      <CardTitle tag="p">{this.state.user_count}</CardTitle>
                                      <p />
                                    </div>
                                  </Col>
                                </Row>
                              </CardBody>
                              <CardFooter>
                                <hr />
                                <div className="stats">
                                  <i className="fas fa-sync-alt" /> Update Now
                                </div>
                              </CardFooter>
                            </Card>
                          </Col>
                          <Col lg="3" md="6" sm="6">
                            <Card className="card-stats">
                              <CardBody>
                                <Row>
                                  <Col md="4" xs="5">
                                    <div className="icon-big text-center icon-warning">
                                      <i className="nc-icon nc-money-coins text-success" />
                                    </div>
                                  </Col>
                                  <Col md="8" xs="7">
                                    <div className="numbers">
                                      <p className="card-category">User Added</p>
                                      <CardTitle tag="p">{this.state.added_count}</CardTitle>
                                      <p />
                                    </div>
                                  </Col>
                                </Row>
                              </CardBody>
                              <CardFooter>
                                <hr />
                                <div className="stats">
                                  <i className="far fa-calendar" /> Last day
                                </div>
                              </CardFooter>
                            </Card>
                          </Col>
                          <Col lg="3" md="6" sm="6">
                            <Card className="card-stats">
                              <CardBody>
                                <Row>
                                  <Col md="4" xs="5">
                                    <div className="icon-big text-center icon-warning">
                                      <i className="nc-icon nc-vector text-danger" />
                                    </div>
                                  </Col>
                                  <Col md="8" xs="7">
                                    <div className="numbers">
                                      <p className="card-category">User skipped</p>
                                      <CardTitle tag="p">{this.state.skipped_count}</CardTitle>
                                      <p />
                                    </div>
                                  </Col>
                                </Row>
                              </CardBody>
                              <CardFooter>
                                <hr />
                                <div className="stats">
                                  <i className="far fa-clock" /> In the last hour
                                </div>
                              </CardFooter>
                            </Card>
                          </Col>
                          
                        </Row> */}
          <Row>
            <Col lg="3" md="6" sm="6" >
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="3" xs="3">
                      <div className="icon-big text-center icon-warning">
                      <a><img className="join-joined-icon" /></a>
                      </div>
                    </Col>
                    <Col md="9" xs="9">
                      <div className="numbers">
                      <p className="card-category font-small-2">Total Username</p>
                        <CardTitle className="tle-joinleave" tag="p">{this.state.user_count}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>

              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="3" xs="3">
                      <div className="icon-big text-center icon-warning">
                      <a><img className="join-settings-icon" /></a>
                      </div>
                    </Col>
                    <Col md="9" xs="9">
                      <div className="numbers">
                      <p className="card-category font-small-2">User Added</p>
                        <CardTitle className="tle-joinleave" tag="p">{this.state.added_count}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
               
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="3" xs="3">
                      <div className="icon-big text-center icon-warning">
                      <a><img className="join-dead-icon" /></a>
                      </div>
                    </Col>
                    <Col md="9" xs="9">
                      <div className="numbers">
                      <p className="card-category font-small-2">User skipped</p>
                        <CardTitle className="tle-joinleave" tag="p">{this.state.skipped_count}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>

              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="3" xs="3">
                      <div className="icon-big text-center icon-warning">
                      <a><img className="join-settings-icon" /></a>
                      </div>
                    </Col>
                    <Col md="9" xs="9">
                      <div className="numbers ">
                      <p className="card-category font-small-2">Current User in Group</p>
                        <CardTitle className="tle-joinleave" tag="p">{this.state.live_user_count}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>

              </Card>
            </Col>
            
          </Row>




                        </div>
              <Card className="card-stats">
                <CardHeader className="p-3">
                <Row>
                  <Col lg="6" md="6" sm="6">
                    <h5>Add the Users to group in Bulk</h5>
                  </Col>
                </Row>

                  <Col lg="6" md="12" sm="12">
                    <Row>
                    <Col lg="10" md="12" sm="12">
                      <label>Multi group Worker {this.state.process_id}</label>
                        <select className='form-control col-sm-12 col-lg-12 col-12 m-1 multiuser' value={this.state.process_id} onChange= {(e) => {this.onChange_process_worker(e)} }>
                          {[1,2,3,4,5,6,7,8,9,10].map(id => (
                            <option value={id} >{this.state.target_channels[id]?this.state.target_channels[id]:`Group ${id}`}</option>
                          ))}
                      </select>
                      </Col>
                      
                    </Row>
                  </Col>
                </CardHeader>
                <CardBody className="p-3">
                  {!this.state.has_data_in_adder?
                  <>
                        <Dropzone
                        // getUploadParams={this.getUploadParams}
                        onChangeStatus={this.handleChangeStatus}
                        onSubmit={this.UploadExcelSubmit}
                        accept="*"
                        multiple={true}
                        inputContent={'Apload the Username CSV with format/username/user or the Json format with Phone Numbers'}
                        submitButtonContent={'Upload'}
                        
                        />
                          <div className="center-btn">

                        <button onClick={this.import_user_from_scraper} className="btn btn-msgr btn-md"><img class="img-responsive-left-icon" src={msgr}  alt="logo "/>Import All Scraped user</button>
                        </div>
                        </>
                  :
                        <>
                        
                            <Row>
                                
                                <Col lg="5" md="4" sm="12">
                                    <div className="my-3">
                                    {/* <label>Multi group Worker</label>
                        <select className='form-control col-sm-12 col-lg-12 col-12 m-1 multiuser' value={this.state.process_id} onChange= {(e) => {this.onChange_process_worker(e)} }>
                          {[1,2,3,4,5,6,7,8,9,10].map(id => (
                            <option value={id} >{this.state.target_channels[id]?this.state.target_channels[id]:`Group ${id}`}</option>
                          ))}
                      </select> */}
                                    <label>Insert the Group to be added:</label>
                                    
                                      <input type="url" className='form-control col-sm-12 col-lg-12 col-12 m-1' value={this.state.target_channel} onChange={(e) => { this.setState({ target_channel: e.target.value }) }} placeholder="Insert the Group to be added" />
                                      <br/>
                                      <label>Maximum User to Add:</label>
                                      <input type="number" className='form-control col-sm-12 col-lg-12 col-12 m-1' value={this.state.max_user_count} onChange={(e) => { this.setState({ max_user_count: e.target.value }) }} placeholder="Enter maximum user count to be added" />
                                      <br/>
                                      <label>Number of process:</label>
                                      <select className='form-control col-sm-12 col-lg-12 col-12 m-1 ' value={this.state.number_of_process} onChange={(e) => { this.setState({ number_of_process: parseInt(e.target.value) }) }}>
                                        {new Array(21).fill("", 1, 21).map((p, i) => {return <>
                                          <option value={i} >{i} Process</option>
                                        </>})}
                                      </select>
                                    </div>
                                </Col>
                                <Col lg="3" md="4" sm="12"  className='list-group '>
                                  
                                    {/* <li className="my-3 ml-3 list-inline-item  float-left">
                                        <small className='h6 text-muted'>Total Username :</small><span className='text-primary'> {this.state.user_count}</span>
                                    </li>     
                                    <li className="my-3 ml-3 list-inline-item  float-left">
                                        <small className='h6 text-muted'>User Added :</small><span className='text-primary'> {this.state.added_count}</span>
                                    </li>  
                                    <li className="my-3 ml-3 list-inline-item  float-left">
                                        <small className='h6 text-muted'>User skipped :</small><span className='text-primary'> {this.state.skipped_count}</span>
                                    </li>                     */}
                                    </Col>
                                    <Col lg="4" md="4" sm="12">

                                    <button onClick={this.deleteAdderData} className='btn btn-adder-deleteuser btn-md' ><img class="img-responsive-left-icon" src={deleteuser}  alt="logo " />Delete Input username</button>
                                    <br/>
                                    <br/>
                                    <button onClick={this.import_user_from_scraper} className="btn btn-importuser glowing-border btn-md "><img class="img-responsive-left-icon" src={home}  alt="logo " />Import user for  adder</button>
                                    <br/>
                                    <br/>
                                    {this.state.adder_running?
                                        <>
                                        <div className='row'>
                                          <div className="col-4">
                                            <button className='btn btn-danger' onClick={(e) => this.set_key_values('status','stopped')} >Stop </button>
                                          </div>
                                          <div className="col-8 text-center pt-3 text-muted ">
                                            status : Adding currently with {this.state.current_session} ...
                                          </div>
                                        </div>
                                        </>
                                        :
                                        <>
                                        {this.state.reason_stopped?<>stopped:{this.state.reason_stopped}</>:''}
                                        <button onClick={this.start_adder_username} className="btn btn-adder-startadding btn-md"><img class="img-responsive-adder-icon" src={startadding}  alt="logo " />Start Adding</button>
                                        </>}
                                </Col>
                            </Row>
                            <Adder_table config={this.props.auth.config} auto_refresh={this.state.adder_running} process_id={this.state.process_id}/>
                        </>
                  }
                </CardBody>
                
              </Card>
            </Col>
            
          </Row>
          <Row>


          </Row>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps)(Adder);

