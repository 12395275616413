
import axios from "axios.js";
import React from "react";
import { connect } from "react-redux";
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'

import Loader_gif from "../assets/img/loader.gif"
import delete_icon from "../assets/img/delete.png";
import dnldfile_icon from "../assets/img/dwnld.png";
import leave_icon from "../assets/img/leave.png";
import ProfilePicture from "profile-picture"
import "profile-picture/build/ProfilePicture.css"
import JsonTable from "views/Simple_table.js";
import Groups from 'views/GroupCreator_table.js'


import ReactTable from "react-table";  

import Swal from 'sweetalert2'
// react plugin used to create charts
import { Line, Pie } from "react-chartjs-2";
import withReactContent from 'sweetalert2-react-content'
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
// core components
const MySwal = withReactContent(Swal)

class messenger extends React.Component {
  constructor(props){
    super(props)
    this.profilePictureRef = React.createRef();
    
    this.state={
      Group_name:'',
      Group_username:'',
      Group_bio:'',
      pro_pic:'',
      groups:[{'loading':'loading...'}],
      created_count:'Loading..',
      table_refresh:false
      

    }
  }
  

  handleUpload() {
    const PP = this.profilePicture.current;
    const imageData = PP.getData();
    const file = imageData.file;
    const imageAsDataURL = PP.getImageAsDataUrl();

    //add here the upload logic...
  }
  componentDidMount=()=>{    
    this.get_input_count()
    
}
    refresh_table=()=>{

      this.setState({
        table_refresh:true
      },()=>{
        setTimeout(()=>{

          this.setState({
          table_refresh:false
        })},3000)
      })
    }
    componentWillUnmount() {
      // clearInterval(this.interval);
      // clearInterval(this.interval2);
    }
    get_export=()=>{
      console.log(this.props.auth.config)
      axios({
        url: this.props.auth.BaseUrl+'groupcreator/export_csv',
        headers:this.props.auth.config.headers,
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        console.log(response.headers['content-type'].split(';')[1])
        link.setAttribute('download', response.headers['content-type'].split(';')[1]); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  
    }

    get_input_count=()=>{
      
      // axios({
      //     url: this.props.auth.BaseUrl+`groupcreator/get_groups_created`,
      //     headers:this.props.auth.config.headers,
      //     method: 'GET',
      //   }).then((response) => {
      //     console.log(response.data.data)
      //     this.setState({
      //       groups:response.data.data,
      //       created_count:response.data.created_count,
           
      //     })
          
          
      //   });
  }
    
  isUserNameValid=(username)=> {
    /*
      Usernames can only have:
      - Lowercase Letters (a-z)
      - Numbers (0-9)
      - Dots (.)
      - Underscores (_)
    */
    const res = /^[a-z0-9_]+$/.exec(username);
    const valid = !!res;
    return valid;
  }

    send_group_creator_request=()=>{
      Swal.fire({
        title: 'Are you sure to create a Group with the input provided?',
        showDenyButton: true,
        // showCancelButton: true,
        confirmButtonText: `Start`,
        denyButtonText: `Cancel`,
      }).then((result) => {
        if (result.isConfirmed && (typeof this.state.Group_name  === 'string' ) && this.isUserNameValid(this.state.Group_username)) {
              const data = new FormData() 

              data.append('group_name', this.state.Group_name)
              data.append('group_bio', this.state.Group_bio)
              data.append('Group_username', this.state.Group_username)
              const PP = this.profilePictureRef.current;
              const imageData = PP.getData();
              const file = imageData.file;

              data.append('profile_pic', PP.state.file)
              console.log(data);
              Swal.fire({title:'Group has be creating... please wait...... ',closeButton:'ok', icon:'success'})

          
              var config
              config = {
                  ...this.props.auth.config,
              }
              config.headers['Content-Type'] = 'multipart/form-data'
              axios({
                  url:  this.props.auth.BaseUrl+`groupcreator/groupCreatorInput`, data: data, headers: config.headers,
                  method: 'POST',
          
              }).then((response) => {
                  this.refresh_table()
                  console.log(response)
                  Swal.fire({title:'Group Created',closeButton:'ok',icon:'success',timerProgressBar:true})

              }).catch(
                  function (error) {
                      Swal.fire('Not Valid', 'Please enter Valid username/Group Name', 'error')
                      return Promise.reject(error)
                  }
              )
      
            }
        else if (result.isDenied) {
          Swal.fire('Canceled', '', 'info')
        }
        else{
          Swal.fire('Not Valid', 'Please enter Valid username/Group Name', 'error')
        }
        this.get_input_count()
          
  });
}


  transfer_ownership=(value,transfer,admin=true)=>{
    var config
    config = {
        ...this.props.auth.config,
    }
    Swal.fire({
      title: 'Submit your Telegram username (@username)',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Look up',
      showLoaderOnConfirm: true,
      preConfirm: (login) => {

        if (login.isConfirmed===false){
          
          return false
        }
        const data = new FormData() 
        data.append('Group_creator_id',value)
        data.append('username', login)
        if (transfer){

          data.append('transfer', 'transfer')
        }
        if (!admin){
          data.append('remove', 'admin')
        }
        return axios({
          url:  this.props.auth.BaseUrl+`groupcreator/transfer_ownership`, data:data, headers: config.headers,
          method: 'POST',
  
      }).then((response) => {
          console.log(response)
          if (response && !response.data.success) {
            throw new Error(response.data.message)
          }
          else{
            this.refresh_table()

            return response.data      
          }
          
          
        }).catch(error => {
            Swal.showValidationMessage(
              `${error}`
            )
          })
      },
      allowOutsideClick: () => false
    }).then((result) => {
      console.log(result)
      if (!result.value){
        Swal.fire({
          title: `Canceled`,
          icon:'error',


        })

      }
      else{

      
      if (result.value.status==200) {
        Swal.fire({
          title: `${result.value.message}`,
          icon:'success',


        })
      }
      else{
        Swal.fire({
          title: `${result.value.message}`,
          icon:'error',


        })

      }
    }
    })
  }
  
  
  delete_group=(value)=>{
    var config
    config = {
        ...this.props.auth.config,
    }
    Swal.fire({
      title: 'Delete this group?',
      showCancelButton: true,
      confirmButtonText: 'delete',
      showLoaderOnConfirm: true,
      preConfirm: (login) => {
        const data = new FormData() 
        data.append('Group_creator_id',value)

        return axios({
          url:  this.props.auth.BaseUrl+`groupcreator/delete_group`, data:data, headers: config.headers,
            method: 'POST',
  
      }).then((response) => {
          console.log(response)
          this.refresh_table()
          if (!response.data.success) {
            throw new Error(response.data.message)
          }
          else{

            return response.data      
          }
          
          
        }).catch(error => {
            Swal.showValidationMessage(
              `${error}`
            )
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      console.log(result)
      if (result.value.status==200) {
        Swal.fire({
          title: `${result.value.message}`,
          icon:'success',


        })
      }
      else{
        Swal.fire({
          title: `${result.value.message}`,
          icon:'error',


        })

      }
    })
  }

  render() {
    return (
      <>
        <div className="content">
        {JSON.stringify(this.state)}
          <Row>
            <Col lg="12" md="12" sm="12">
              <Card className="card-fetchuser card-stats p-3">
                <CardHeader>
                <Row>
                  <Col lg="6" md="6" sm="6">
                    <h5 className="fectuser">Create Group /Channel</h5>
                  </Col>
                </Row>
                </CardHeader>
                <CardBody className="p-3">

                        <>
                        <Row className=''>
                        <Col lg="12" md="12" sm="12" className='my-2'>
                          
                          <Row>
                          <Col md="6"size="sm" s='12'>
                          <label htmlFor="Group_name">Group Name:</label>
                          <input type="text"  className='form-control square input-sm mb-1' id='Group_name' value={this.state.Group_name} onChange={(e) => { this.setState({ Group_name: e.target.value }) }} placeholder="Enter the Groups Name" />
                          <label htmlFor="Group_name">Desired Public Username(Suggestion):</label>
                          <input type="text"  className='form-control square input-sm mb-1' id='Group_username' value={this.state.Group_username} onChange={(e) => { this.setState({ Group_username: e.target.value }) }} placeholder="Please suggest a username" />
                          
                          <label htmlFor="scraper_date">Enter the Group Bio :</label>
                          <textarea   className='form-text square col-12 ' rows={10} id='Group_bio'  onChange={(e) => { this.setState({ Group_bio: e.target.value }) }} placeholder="Enter the group Bio">{this.state.Group_bio}</textarea>
                         
                          </Col>
                          <Col md="6" size="sm" s='12'>
                          <label htmlFor="scraper_date">Profile picture :</label>
                          <ProfilePicture
                                  ref={this.profilePictureRef}
                                  useHelper={true}
                                  debug={true}
                                  frameFormat={'rounded-square'}
                                  
                                    
                                />
                          </Col>

                          <Col md="6" size="sm" s='12'>
                         
                          </Col>
                          
                          <Col md="12"size="sm" s='12'>
                          <button onClick={this.send_group_creator_request}  className='btn btn-lg btnclr btn-lg btn-block' ><img class="img-responsive-left-icon"  src={leave_icon} alt="logo " />Create Group</button>
                          </Col>
                          
                          </Row>

                          <Col lg="6" md="6" sm="12" xl='6'  className=''>
                        {/* <Row className='mt-lg-4'>
                        <Col lg="6" md="6" sm="12" xl='6'  >
                          <button onClick={this.deleteScraperData} className='btn mx-2 btn-danger square col-md-12 col-lg-12 col-sm-6' ><i className='fa fa-trash'></i> <br/>Delete All users</button>
                        </Col>
                        <Col lg="6" md="6" sm="12" xl='6' >
                          <button onClick={this.get_export} className='btn mx-2 square btn-info col-md-12 col-lg-12 col-sm-6' > <i className='fa fa-download'></i> <br/>Download CSV</button>
                        </Col>

                        </Row> */}
                        </Col>
                        
                        </Col>


                       

                        </Row>
                        <div >
                            
                            </div>
                        </>

                </CardBody>
                
              </Card>
            </Col>
            <button onClick={this.get_export} className='btn col-2 btn-new btn-click float-right  glowing-border btn-md btn-block' ><img class="img-responsive-left-icon"  src={dnldfile_icon} alt="logo " />Download CSV</button>
            <Groups config={this.props.auth.config} delete_group={this.delete_group} ownership={this.transfer_ownership} auto_refresh={this.state.table_refresh} />
          </Row>
          
        </div>
      </>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps)(messenger);

