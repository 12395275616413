import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { loginUser } from "../../actions/auth";
// import logo from "logo.svg";
import ReCAPTCHA from "react-recaptcha";
import logo from "assets/img/logo.png";
import bird_logo from "assets/img/bird.png";
import fb from "assets/img/fb.png";
import msgr from "assets/img/telemsgr.png";
import insta from "assets/img/teleinsta.png";
import signin from "assets/img/signinNpng.png";
import login from "assets/img/signinpng.png";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
class Login extends Component {
  state = {
    username: "",
    password: "",
    verified:true,
    // verified:false,
  };

  static propTypes = {};
  componentDidMount=()=>{

  }
  handleChange = e => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  };
  handleCapacha= ()=>{
    console.log('Captcha Loaded')
  }
  handleVerifyCaptcha= (response)=>{

    this.setState({verified:true})

  }

  onSubmit = e => {
    e.preventDefault();
    
    this.props.loginUser(this.state.username, this.state.password)

  };

  render() {
    if (this.props.auth.isAuthenticated) {
      return <Redirect to="/dashboard" />;
    }
    if(this.props.auth.isLoading){
      // if(1){
      return (
        <>
        <div  style={{
          "background": "#212329",
         'minHeight':'100vh',
         "position":"fixed",
         "bottom": "0",
         "right":"0",
         "width":"100%",
         
       }}>
               </div>
        <div className="app-content container center-layout mt-2 ">
            <div className="content-wrapper">
                <div className="content-header row">

                </div>
                <div className="content-body">
                    <section className="flexbox-container">
                        <div className=" d-flex align-items-center justify-content-center min-vh-100">
                            <div className="col-sm-10 col-md-6 col-lg-4 col-xl-4 col-12 box-shadow-5 p-0">
      <div
        className=" card py-2 my-2"
        style={{ justifySelf: "center", alignSelf: "center" }}
      >
        <div className="card-header border-1">
          
          <h3 className='card-title text-center text-muted' style={{ justifySelf: "center", alignSelf: "center" }}>
              Loading...

          <span className="spinner-border spinner-border-lg "></span></h3>
        </div>
        
      </div>
        </div>
        </div>
        </section>
        </div>
        </div>
        </div>
        
        </>
      );
    }
    const { username, password } = this.state;
    return (
      <>
      <div  style={{
                    "background": "#212329",
                    'minHeight':'100vh',
                    "position":"fixed",
                    "bottom": "0",
                    "right":"0",
                    "width":"100%",
                                      
                                    }}>
                                            </div>
        <div className="app-content container center-layout mt-2 " >
          <div className="content-wrapper ">
         
            <div className="content-body mt-5 ">
              <section className="flexbox-container ">
                <div className="col-12 d-flex align-items-center justify-content-center min-vh-50 ">
                  <div className="col-sm-8 col-md-4 col-lg-4 col-xl-4  col-10 box-shadow-5 p-0 " >
                    <div
                      className="card login"
                      style={{ justifySelf: "center", alignSelf: "center" }}
                    >
                      <div className="card-header border-1">
                        <h5
                          className="card-title text-center text-muted"
                          style={{ justifySelf: "center", alignSelf: "center" }}
                        >
                          <div className="" class="d-flex align-items-start border-login logo  m-2 py-2 rounded rounded-5 ">
                            <a
                              href="/"
                              className="simple-text logo-mini "
                            >
                              <div className="logo-img">
                                <img src={logo} className="login-image" alt="logo" />
                              </div>
                              
                            </a>
                            <h5
                              href="/"
                              className="simple-text primary login-1 login-header logo-normal "
                            >
                              TeleBooster
                            </h5>
                          </div>
                        </h5>
                      </div>
                      <div className="card-body mt-4 p-4">
                        <form onSubmit={this.onSubmit}>
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Username</label>
                            <input
                              type="text"
                              className="form-control"
                              name="username"
                              placeholder="Enter Username"
                              onChange={this.handleChange}
                              value={username}
                            />
                            <small
                              id="usernameHelp"
                              className="form-text text-muted"
                            >
                              Enter your username.
                            </small>
                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleInputPassword1">
                              Password
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              name="password"
                              placeholder="Password"
                              onChange={this.handleChange}
                              value={password}
                            />
                          </div>
                          <div className="form-group form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="remember-me"
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="width-300 font-small-1">

                          
                              </div>

                              <Row>
                          <Col md="6"size="sm" s='6'>
                          <button type="submit" 
                            className="btn user-btn btn-block"  disabled={this.state.verified?false:true}><img class="img-responsive-left-icon"  src={login} alt="logo " /> {this.state.verified?'Login':<>
                          Please Verify Captcha to Login
                          </>
                          }</button>
                          </Col>
                          
                          <Col md="6"size="sm" s='6'>
                          <button onClick={this.get_export} className='btn btn-new btn-click fectchuser glowing-border btn-md btn-block' ><img class="img-responsive-left-icon"  src={signin} alt="logo " />Sign in</button>
                          </Col>
                         
                          </Row>
                          <ReCAPTCHA 
                                sitekey="6LdG3igbAAAAABDgg4ejGYMpaVdgki2IdleqG6CB"

                                onloadCallback={this.handleCapacha}
                                verifyCallback={this.handleVerifyCaptcha}
                                expiredCallback={()=> this.setState({verified:false})}
                                theme='dark'


                              />
                        </form>
                      <div className="card-body mt-4 p-4">
            <Col lg="12" md="12" sm="12">

                      <Row>

                      <Col lg="4" md="6" sm="6" >
                      <Row>
                      <div>
                      <input type="checkbox" /><label htmlFor="exampleTerms">Terms and Use</label>
                      </div>
                      </Row>

                      </Col>
                      <Col lg="4" md="6" sm="6" >
                      </Col>

                      <Col lg="4" md="6" sm="6" >
                      <Row>
                      <div className="divlogin" >
                        <span><img  src={bird_logo}  alt="logo " /></span>
                      </div>
                      <div>

                      </div>
                      <div className="divlogin"  >
                      <span><img  src={msgr}  alt="logo " /></span>
                      </div>
                      <div className="divlogin" >
                      <span><img  src={insta}  alt="logo " /></span>
                      </div>
                      <div className="divlogin" >
                        <span><img  src={fb}  alt="logo " /></span>
                      </div>
                      </Row>
                      </Col>
                      </Row>


                      </Col>
   
                        
                      </div>
                        {/* <div className="text-center">
            <small className="form-text text-muted">
              Don't have an account. <Link to="/register">Register Here</Link>
            </small>
          </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps, {
  loginUser
})(Login);
