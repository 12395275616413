import axios from "axios.js";
import React from "react";
import { connect } from "react-redux";
import Select from 'react-select';
import VisibilitySensor from 'react-visibility-sensor';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
    Button,
} from "reactstrap";

const MySwal = withReactContent(Swal)

// Core Component
class FolderJAL extends React.Component {
    constructor() {
        super()
        this.state = {
            counts_loading: false,
            form_load: false,

            active_session: 15,
            ready_to_regenerate: 10,
            hold_before_regenerate: 50,
            tt_active_users: new Date(),

            target_url: '',
            max_user: 0,
            individual_channel_link: false
        }
    }

    componentDidMount() {
        console.log(this.props)
        this.getActiveSessions()
    }

    componentWillUnmount() {}

    validatePrivateLink = (request_func) => {
        if (this.state.target_url.startsWith("https://t.me/+")) {
            this.setState({target_url: this.state.target_url.replace("https://t.me/+", "https://t.me/joinchat/")}, request_func)
        } else {
            request_func()
        }
    }

    validateUrl = (request_func) => {
        if (this.state.target_url.startsWith("@")) {
            this.setState({target_url: this.state.target_url.replace("@", "https://t.me/")}, () => {this.validatePrivateLink(request_func)})
        } else if (this.state.target_url.startsWith("t.me/")) {
            this.setState({target_url: this.state.target_url.replace("t.me/", "https://t.me/")}, () => {this.validatePrivateLink(request_func)})
        } else if (this.state.target_url.startsWith("http://t.me/")) {
            this.setState({target_url: this.state.target_url.replace("http://t.me/", "https://t.me/")}, () => {this.validatePrivateLink(request_func)})
        } else if (!this.state.target_url.startsWith("https://t.me/")){
            Swal.fire("Improper Format", "The Target URL is not matched with any supported format (https://t.me/channel or https://t.me/channel or @channel)")
            return
        } else {
            this.validatePrivateLink(request_func)
        }
    }

    checkBoolVal(cond) {
        if (cond == 'true'){
            return true
        } else if (cond == 'false') {
            return false
        } else {
            return undefined
        }
    }

    makeCall(url, method="GET", data={}) {
        return axios({
            url: url,
            method: method
        })
    }

    getActiveSessions() {
        this.setState({counts_loading: true})
        this.makeCall(
            this.props.auth.FJALBaseUrl + 'get_active_sessions',
            'GET'
        ).then((response) => {
            let data = response.data
            console.log("RESP :", response.data)
            this.setState({
                active_session: data['active_session'],
                ready_to_regenerate: data['ready_to_regenerate'],
                hold_before_regenerate: data['hold_before_regenerate'],
                counts_loading: false
            })
        })
    }

    addFolderLink() {
        this.makeCall(
            this.props.auth.FJALBaseUrl + 'add_folder_link',
            'POST',
            {
                link: this.state.target_url,
                count: this.state.max_user,
                channel_link: this.state.individual_channel_link
            }
        )
    }

    render() {
        return (<>
            <div className="content">
                <Card className="card-stats">
                    <CardBody>
                        <Row>
                            <Col lg="4" md="4" sm="4">
                                <Card className="card-stats card border-green joinleave">
                                    <CardBody>
                                        <Row>
                                            <Col md="3" xs="3">
                                                <div className="icon-big text-center icon-warning">
                                                <a><img className="join-settings-icon" /></a>
                                                </div>
                                            </Col>
                                            <Col md="9" xs="9">
                                                <div className="numbers text-center my-auto">
                                                    <p className="card-category">Active Sessions</p>
                                                    <CardTitle className="tle-actve" tag="p">{this.state.counts_loading?<i className='fa fa-refresh icon-spin'></i>:this.state.active_session}</CardTitle>
                                                    <p />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <hr />
                                        <div className="stats text-center">
                                            <i className="sas" />
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Col>
                            <Col lg="4" md="4" sm="4">
                                <Card className="card-stats card border-green joinleave">
                                    <CardBody>
                                        <Row>
                                            <Col md="3" xs="3">
                                                <div className="icon-big text-center icon-warning">
                                                    <a><img className="join-settings-icon" /></a>
                                                </div>
                                            </Col>
                                            <Col md="9" xs="9">
                                                <div className="numbers">
                                                    <p className="card-category text-center">Ready to Regenerate</p>
                                                    <CardTitle className="tle-actve" tag="p">{this.state.counts_loading?<i className='fa fa-refresh icon-spin'></i>:this.state.ready_to_regenerate}</CardTitle>
                                                    <p />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <hr />
                                        <div className="stats text-center">
                                            <i className="sas" />
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Col>
                            <Col lg="4" md="4" sm="4">
                                <Card className="card-stats card border-green joinleave">
                                    <CardBody>
                                        <Row>
                                            <Col md="3" xs="3">
                                                <div className="icon-big text-center icon-warning">
                                                    <a><img className="join-settings-icon" /></a>
                                                </div>
                                            </Col>
                                            <Col md="9" xs="9">
                                                <div className="numbers">
                                                    <p className="card-category text-center">Hold before Regenerate</p>
                                                    <CardTitle className="tle-actve" tag="p">{this.state.counts_loading?<i className='fa fa-refresh icon-spin'></i>:this.state.hold_before_regenerate}</CardTitle>
                                                    <p />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <hr />
                                        <div className="stats text-center">
                                            <i className="sas" />
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardTitle>
                        <Button disabled={true} className="btn btn-purple float-left ml-3">Updated on : {this.state.tt_active_users?this.state.tt_active_users.toUTCString():'Not Yet'}</Button>
                        <Button onClick={this.getActiveSessions} disabled={this.state.counts_loading} className="btn btn-outline-green float-right mr-3">{this.state.tt_active_users?<>Reload Count <i className={`fa fa-refresh ${this.state.counts_loading?'icon-spin':''} `}></i></>:<>Get Count</>}</Button>
                    </CardTitle>
                </Card>

                <Row>
                    <Col className="mx-auto" lg="12" md="12" sm="12">
                        <Card className="card-stats border-info">
                            <CardBody className="p-3"><>
                                <Row>
                                    <Col className="mx-auto" lg="8" md="8" sm="12">
                                        <div>
                                            <label className="labelfont">Enter the group link:</label>
                                            <input type="url" className='form-control square col-sm-12 col-lg-12 col-12 my-2 m-1' readOnly={this.state.form_load} value={this.state.target_url} onChange={(e) => { this.setState({ target_url: e.target.value }) }} placeholder="Insert the Group to be added" />

                                            <label>Enter the Maximum user count:</label>
                                            <input type="number" className='form-control square col-sm-12 col-lg-12 col-12 m-1' disabled={this.state.form_load} value={this.state.max_user} onChange={(e) => { this.setState({ max_user: parseInt(e.target.value) }) }} placeholder="Enter Max number of users" />

                                            <label className="my-2">Individual Channel:</label>
                                            <select className='form-control square col-sm-12 col-lg-12 col-12 my-2 m-1' readOnly={this.state.form_load} value={this.state.individual_channel_link} onChange={(e) => { this.setState({ individual_channel_link: this.checkBoolVal(e.target.value) }) }}>
                                                <option value={false} >No</option>
                                                <option value={true} >Yes</option>
                                            </select>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="my-2 mt-4">
                                    <Col className="mx-auto" lg="6" md="6" sm="6">
                                        <button onClick={() => {this.validateUrl(this.addFolderLink)}} disabled={this.state.form_load} className={`btn btn-lg btn-info col-sm-12 col-lg-12 col-12 bg-green`}><a><img className="join-start-icon" /></a>Add Process</button>
                                    </Col>
                                </Row>
                            </></CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>);
    }
}
const mapStateToProps = state => ({
    auth: state.auth
});
export default connect(mapStateToProps)(FolderJAL);

