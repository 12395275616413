import axios from "axios.js";
import React from "react";
import { connect } from "react-redux";
import Select from 'react-select'
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import UsersStatusFollowupTable from "./UsersStatusFollowupTable.js";
import NotificationAlert from "react-notification-alert";
import Swal from "sweetalert2";
// react plugin used to create charts
import { Line, Pie } from "react-chartjs-2";
import Loader_gif from "../assets/img/loader.gif";
import Load from "../assets/img/Untitled-2.png";
import startadding from "../assets/img/startadding.png";
import home from "../assets/img/home.png";
import deleteuser from "../assets/img/deleteuser.png";
import msgr from "assets/img/msgr.png";
import VisibilitySensor from 'react-visibility-sensor';
import JsonTable from "views/Simple_table.js";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
    Button,
} from "reactstrap";
// core components

class UserStatusFollowup extends React.Component {
    constructor() {
        super();
        this.state = {
            count_visibility: false,
            session_counts_loading: false,
            tt_session_counts: null,
            ready_to_use_session_count: 0,
            muted_session_count: 0,
            alive_session_count: 0,
            dead_session_last_week: 0,
            form_load: false,
            slot_number: 1,
            source_channel: '',
            target_group: '',
            report_channel_id: '',
            message: '',
            photo: '',
            count: 0,
            slots_data: {},
            slots_data_loading: false,
            history_type: 'orders',

            orders_history: {},
            orders_history_loading: false,
            orders_list: [],
            orders_list_loading: false,
            history_order_id: 0,

            source_channel_history: {},
            source_channel_history_loading: false,
            source_channel_list: [],
            source_channel_list_loading: false,
            history_source_channel_link: '',

            target_group_history: {},
            target_group_history_loading: false,
            target_group_list: [],
            target_group_list_loading: false,
            history_target_group_link: '',

            report_id: '',
            report_id_new: '',
            tt_report_id: null,
            report_id_loading: false,
            report_id_updating: false,
        };
    }

    make_call(request_event, payloads={}) {
        let payload_keys = Object.keys(payloads)
        let payload_str = '?'
        if (payload_keys.length > 0) {
            payload_keys.forEach(function(key) {
                payload_str += key + "=" + payloads[key] + "&"
            })
        }

        axios.defaults.withCredentials = false;
        return axios({
            url: this.props.auth.BaseUrl + `user_status_followup/${request_event}${payload_str.slice(0, -1)}`,
            headers: this.props.auth.config.headers,
            method: 'GET',
        })
    }

    make_post_call(request_event, form_data) {
        axios.defaults.withCredentials = false;
        var config
        config = {
            ...this.props.auth.config,
        }
        config.headers['Content-Type'] = 'multipart/form-data'
        return axios({
            url: this.props.auth.BaseUrl + `user_status_followup/${request_event}`,
            headers: config.headers,
            data: form_data,
            method: 'POST',
        })
    }


    get_session_counts = () => {
        this.setState({session_counts_loading: true})
        this.make_call("followup_get_counts").then((response) => {
            this.setState({
                muted_session_count: response.data["data"]["muted_sessions"],
                alive_session_count: response.data["data"]["alive_sessions"],
                ready_to_use_session_count: response.data["data"]["ready_to_use_sessions"],
                dead_session_last_week: response.data["data"]["dead_last_7_days"],
                session_counts_loading: false,
                tt_session_counts: new Date()
            })
        }).catch((error) => {
            this.setState({session_counts_loading: false})
            Swal.fire({
                title: "Something Went Wrong",
                html: error.response.data["error_msg"],
                icon: "danger",
            })
        })
    }


    get_orders_history = () => {
        this.setState({orders_history_loading: true})
        this.make_call("followup_get_orders_history", {
            "order_id": this.state.history_order_id
        }).then((response) => {
            let orders_history = {...this.state.orders_history}
            let request = response.data["data"]["request"]
            request["created_on"] = this.format_time(request["created_on"])
            request["completed_on"] = this.format_time(request["completed_on"])
            orders_history[this.state.history_order_id] = {
                history: response.data["data"]["history"],
                request: request,
                tt_orders_history: new Date(),
            }
            this.setState({
                orders_history: orders_history,
                orders_history_loading: false
            })
        }).catch((error) => {
            this.setState({orders_history_loading: false})
            Swal.fire({
                title: "Something Went Wrong",
                html: error.response.data["error_msg"],
                icon: "danger",
            })
        })
    }


    get_source_channel_history = () => {
        this.setState({source_channel_history_loading: true})
        this.make_call("followup_get_linkwise_history", { "source_channel": this.state.history_source_channel_link }).then((response) => {
            console.log(response)
            let source_channel_history = {...this.state.source_channel_history}
            source_channel_history[this.state.history_source_channel_link] = {
                history: response.data["data"]["history"],
                details: response.data["data"]["details"],
                tt_source_channel_history: new Date(),
            }
            this.setState({
                source_channel_history: source_channel_history,
                source_channel_history_loading: false
            })
        }).catch((error) => {
            this.setState({source_channel_history_loading: false})
            Swal.fire({
                title: "Something Went Wrong",
                html: error.response.data["error_msg"],
                icon: "danger",
            })
        })
    }


    get_target_group_history = () => {
        this.setState({target_group_history_loading: true})
        this.make_call("get_linkwise_history", { "target_group": this.state.history_target_group_link }).then((response) => {
            let target_group_history = {...this.state.target_group_history}
            target_group_history[this.state.history_target_group_link] = {
                history: response.data["data"]["history"],
                details: response.data["data"]["details"],
                tt_target_group_history: new Date(),
            }
            this.setState({
                target_group_history: target_group_history,
                target_group_history_loading: false
            })
        }).catch((error) => {
            this.setState({target_group_history_loading: false})
            Swal.fire({
                title: "Something Went Wrong",
                html: error.response.data["error_msg"],
                icon: "danger",
            })
        })
    }


    get_orders_list = () => {
        this.setState({orders_list_loading: true})
        this.make_call("followup_get_orders_list").then((response) => {
            let orders_list = []
            response.data["data"].map((element, index) => {
                orders_list = [...orders_list, {label: `${element["id"]} - ${element["target_group"]}`, value: element["id"]}]
            })
            this.setState({
                orders_list: orders_list,
                orders_list_loading: false
            })
        }).catch((error) => {
            this.setState({orders_list_loading: false})
            Swal.fire({
                title: "Something Went Wrong",
                html: error.response.data["error_msg"],
                icon: "danger",
            })
        })
    }


    get_source_channel_list = () => {
        this.setState({source_channel_list_loading: true})
        this.make_call("get_source_channel_list_user_status").then((response) => {
            let source_channel_list = []
            response.data["data"].map((element, index) => {
                source_channel_list = [...source_channel_list, {label: element, value: element}]
            })
            this.setState({
                source_channel_list: source_channel_list,
                source_channel_list_loading: false
            })
        }).catch((error) => {
            this.setState({source_channel_list_loading: false})
            Swal.fire({
                title: "Something Went Wrong",
                html: error.response.data["error_msg"],
                icon: "danger",
            })
        })
    }


    get_target_group_list = () => {
        this.setState({target_group_list_loading: true})
        this.make_call("get_target_group_list_user_status").then((response) => {
            let target_group_list = []
            response.data["data"].map((element, index) => {
                target_group_list = [...target_group_list, {label: element, value: element}]
            })
            this.setState({
                target_group_list: target_group_list,
                target_group_list_loading: false
            })
        }).catch((error) => {
            this.setState({target_group_list_loading: false})
            Swal.fire({
                title: "Something Went Wrong",
                html: error.response.data["error_msg"],
                icon: "danger",
            })
        })
    }


    get_slot_list = () => {
        this.setState({slots_data_loading: true})
        this.make_call("get_slot_details_user_status").then((response) => {
            let data = {}
            response.data["data"].map((element, index) => {
                element["completed_on"] = this.format_time(element["completed_on"])
                element["created_on"] = this.format_time(element["created_on"])
                data[element["slot_number"]] = element
            });
            this.setState({slots_data: data, slots_data_loading: false}, () => {this.update_slot(this.state.slot_number)})
        }).catch((error) => {
            this.setState({slots_data_loading: false})
            Swal.fire({
                title: "Something Went Wrong",
                html: error.response.data["error_msg"],
                icon: "danger",
            })
        })
    };

    // get_report_id = () => {
    //     this.setState({report_id_loading: true})
    //     this.make_call("followup_get_report_id").then((response) => {
    //         this.setState({report_id: response.data["report_id"], report_id_new: '', tt_report_id: new Date(), report_id_loading: false, report_id_updating: false})
    //     }).catch((error) => {
    //         this.setState({report_id_loading: false})
    //         Swal.fire({
    //             title: "Something Went Wrong",
    //             html: error.response.data["error_msg"],
    //             icon: "danger",
    //         })
    //     })
    // }

    save_report_id = () => {
        this.setState({report_id_loading: true})
        var payload = {}
        if (this.state.report_id_new){
            payload = {"report_id": this.state.report_id_new}
        }
        this.make_call("followup_update_report_id", payload).then((response) => {
            this.setState({report_id: response.data["report_id"], report_id_new: '', tt_report_id: new Date(), report_id_loading: false, report_id_updating: false})
            Swal.fire({
                title: "Success Note",
                html: response.data["message"],
                icon: "info",
            })
        }).catch((error) => {
            this.setState({report_id_loading: false})
            Swal.fire({
                title: "Something Went Wrong",
                html: error.response.data["error_msg"],
                icon: "danger",
            })
        })

    }


    format_time = (time) => {
        if(time){
            var date = time.split(' ')[0].split('/')
            var time = time.split(' ')[1].split(':')
            return new Date(Date.UTC(date[2], date[1]-1, date[0], time[0], time[1], time[2], 0)).toUTCString()
        }
        else{
            return time
        }
    }


    // Input On Change Event Handlers ===>
    update_slot = (slot_number) => {
        if (this.state.slots_data[slot_number]) {
            this.setState({
                slot_number: slot_number,
                source_channel: this.state.slots_data[slot_number]["source_channel"],
                target_group: this.state.slots_data[slot_number]["target_group"],
                message: this.state.slots_data[slot_number]["message"],
                report_channel_id: this.state.slots_data[slot_number]["report_channel_id"],
                photo: this.state.slots_data[slot_number]["photo"]
            })
        } else {
            this.setState({
                slot_number: slot_number,
                source_channel: '',
                target_group: '',
                message: '',
                report_channel_id: '',
                photo: ''
            })
        }
    }


    validate_url = (target_link) => {
        if (target_link.startsWith("@")) {
            target_link = target_link.replace("@", "https://t.me/")
        } else if (target_link.startsWith("t.me/")) {
            target_link = target_link.replace("t.me/", "https://t.me/")
        } else if (target_link.startsWith("http://t.me/")) {
            target_link = target_link.replace("http://t.me/", "https://t.me/")
        } else if (!target_link.startsWith("https://t.me/")){
            Swal.fire("Improper Format", `The ${target_link} is not matched with any supported format (https://t.me/channel or https://t.me/channel or @channel)`)
            return
        }
        if (target_link.startsWith("https://t.me/+")) {
            target_link = target_link.replace("https://t.me/+", "https://t.me/joinchat/")
        }
        return target_link
    }

    // Button Click Event Handlers ===>
    start_new_process = () => {
        this.setState({form_load: true})
        let source_channel = this.validate_url(this.state.source_channel)
        let target_group = this.validate_url(this.state.target_group)
        if ((!source_channel) || (!target_group)) {
            this.setState({form_load: false})
            return
        }
        const formData = new FormData();
        formData.append("slot_number", this.state.slot_number)
        formData.append("source_channel", source_channel)
        formData.append("target_group", target_group)
        formData.append("message", this.state.message)
        formData.append("report_channel_id", this.state.report_channel_id)
        if (this.state.photo instanceof Blob) {
            formData.append("photo", this.state.photo, this.state.photo.name)
        }
        this.make_post_call("initiate_user_status_request", formData).then((response) => {

            let data = {...this.state.slots_data}
            let element = response.data["data"]
            element["completed_on"] = this.format_time(element["completed_on"])
            element["created_on"] = this.format_time(element["created_on"])
            data[element["slot_number"]] = element

            this.setState({slots_data: data}, () => {this.update_slot(element["slot_number"])})

            Swal.fire({
                title: "Success Note",
                html: response.data["message"],
                icon: "info",
            })
            this.setState({form_load: false})
        }).catch((error) => {
            this.setState({form_load: false})
            Swal.fire({
                title: "Something Went Wrong",
                html: error.response.data["error_msg"],
                icon: "danger",
            })
        })
    }


    resume_process = () => {
        this.setState({form_load: true})
        this.make_call("start_process_user_status", {"slot_number": this.state.slot_number}).then((response) => {

            let data = {...this.state.slots_data}
            let element = response.data["data"]
            element["completed_on"] = this.format_time(element["completed_on"])
            element["created_on"] = this.format_time(element["created_on"])
            data[element["slot_number"]] = element
            this.setState({slots_data: data}, () => {this.update_slot(element["slot_number"])})

            Swal.fire({
                title: "Success Note",
                html: response.data["message"],
                icon: "info",
            })
            this.setState({form_load: false})
        }).catch((error) => {
            this.setState({form_load: false})
            Swal.fire({
                title: "Something Went Wrong",
                html: error.response.data["error_msg"],
                icon: "danger",
            })
        })
    }


    stop_process = () => {
        this.setState({form_load: true})
        this.make_call("stop_process_user_status", {"slot_number": this.state.slot_number}).then((response) => {

            let data = {...this.state.slots_data}
            let element = response.data["data"]
            element["completed_on"] = this.format_time(element["completed_on"])
            element["created_on"] = this.format_time(element["created_on"])
            data[element["slot_number"]] = element
            this.setState({slots_data: data}, () => {this.update_slot(element["slot_number"])})

            Swal.fire({
                title: "Success Note",
                html: response.data["message"],
                icon: "info",
            })
            this.setState({form_load: false})
        }).catch((error) => {
            this.setState({form_load: false})
            Swal.fire({
                title: "Something Went Wrong",
                html: error.response.data["error_msg"],
                icon: "danger",
            })
        })
    }


    mark_process_done = () => {
        this.setState({form_load: true})
        this.make_call("mark_process_done_user_status", {"slot_number": this.state.slot_number}).then((response) => {

            let data = {...this.state.slots_data}
            let element = response.data["data"]
            element["completed_on"] = this.format_time(element["completed_on"])
            element["created_on"] = this.format_time(element["created_on"])
            data[element["slot_number"]] = element
            this.setState({slots_data: data}, () => {this.update_slot(element["slot_number"])})

            Swal.fire({
                title: "Success Note",
                html: response.data["message"],
                icon: "info",
            })
            this.setState({form_load: false})
        }).catch((error) => {
            this.setState({form_load: false})
            Swal.fire({
                title: "Something Went Wrong",
                html: error.response.data["error_msg"],
                icon: "danger",
            })
        })
    }

    componentDidMount = () => {
        this.get_slot_list()
        this.get_session_counts()
        this.get_orders_list()
        this.get_source_channel_list()
        this.get_target_group_list()
        // this.get_report_id()

    };


    componentWillUnmount = () => {};

    render() {
        return (<>
            <div className="content">
                {/* <NotificationAlert ref={this.notificationAlert} />  */}
                {/* <VisibilitySensor partialVisibility onChange={(isVisible) => {this.setState({count_visibility: isVisible})}}> */}
                    <Row>
                        <Col lg="3" md="6" sm="12">
                            <Card className="card-stats card border-green joinleave">
                                <CardBody>
                                    <Row>
                                        <Col md="3" xs="3">
                                            <div className="icon-big text-center icon-warning">
                                            <a><img className="join-joined-icon" /></a>
                                            </div>
                                        </Col>

                                        <Col md="9" xs="9">
                                            <div className="numbers">
                                                <p className="card-category">Ready To Use Sessions</p>

                                                <CardTitle className="tle-joinleave" tag="p">{this.state.session_counts_loading?<i className='fa fa-refresh icon-spin'></i>:this.state.ready_to_use_session_count}</CardTitle>
                                                <p />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <hr />
                                    <div className="stats">
                                        <i className="fas fa-sync-alt" /> {this.state.status}
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>

                        <Col lg="3" md="6" sm="12">
                            <Card className="card-stats card border-green joinleave">
                                <CardBody>
                                    <Row>
                                        <Col md="3" xs="3">
                                            <div className="icon-big text-center icon-warning">
                                            <a><img className="join-settings-icon" /></a>
                                            </div>
                                        </Col>
                                        <Col md="9" xs="9">
                                            <div className="numbers">
                                                <p className="card-category lft">Muted Session</p>
                                                <CardTitle className="tle-lft" tag="p">{this.state.session_counts_loading?<i className='fa fa-refresh icon-spin'></i>:this.state.muted_session_count}</CardTitle>
                                                <p />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <hr />
                                    <div className="stats">
                                        <i className="sas" />
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>

                        <Col lg="3" md="6" sm="12">
                            <Card className="card-stats card border-green joinleave">
                                <CardBody>
                                    <Row>
                                        <Col md="3" xs="3">
                                            <div className="icon-big text-center icon-warning">
                                            <a><img className="join-dead-icon" /></a>
                                            </div>
                                        </Col>
                                        <Col md="9" xs="9">
                                            <div className="numbers">
                                                <p className="card-category lft">Alive Session</p>
                                                <CardTitle className="tle-actve" tag="p">{this.state.session_counts_loading?<i className='fa fa-refresh icon-spin'></i>:this.state.alive_session_count}</CardTitle>
                                                <p />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <hr />
                                    <div className="stats">
                                    <i className="sas" />
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>

                        <Col lg="3" md="6" sm="12">
                            <Card className="card-stats card border-green joinleave">
                                <CardBody>
                                    <Row>
                                        <Col md="3" xs="3">
                                            <div className="icon-big text-center icon-warning">
                                            <a><img className="join-settings-icon" /></a>
                                            </div>
                                        </Col>
                                        <Col md="9" xs="9">
                                            <div className="numbers">
                                                <p className="card-category lft">Dead In Last 7 Days</p>
                                                <CardTitle className="tle-actve" tag="p">{this.state.session_counts_loading?<i className='fa fa-refresh icon-spin'></i>:this.state.dead_session_last_week}</CardTitle>
                                                <p />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <hr />
                                    <div className="stats">
                                    <i className="sas" />
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>


                {/* </VisibilitySensor> */}

                <Row className="mb-3">
                    {this.state.tt_session_counts?<>
                        <Col className="mr-auto" lg="6" md="6" sm="6">
                            <button disabled={true} className="btn btn-lg btn-outline-warning col-sm-12 col-lg-12 col-12">Counts Updated on : {this.state.tt_session_counts.toUTCString()}</button>
                        </Col>
                    </>:<></>}
                    <Col className="ml-auto" lg="2" md="2" sm="2">
                        <button onClick={this.get_session_counts} disabled={this.state.form_load || this.state.session_counts_loading} className="btn btn-lg col-sm-12 col-lg-12 col-12 bg-green"> {this.state.session_counts_loading?<i className='fa fa-refresh icon-spin'></i>:''} Reset Count </button>
                    </Col>
                </Row>

                <Row>
                    <Col className="mx-auto" lg="12" md="12" sm="12">
                        <Card className="card-stats border-info">
                            <CardBody className="p-3"><>
                                <Row className="mb-3">
                                    <Col className="m-auto text-center" lg="10" md="10" sm="10">
                                        <p className="h3 my-auto">Users's status Followup</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="mx-auto" lg="8" md="8" sm="12">
                                        {this.state.form_load?<div id="loader"></div>:<></>}
                                        <div className="my-3">
                                            <Row>
                                                <label className="my-auto">Slot Number :</label>
                                                <button onClick={this.get_slot_list} className="ml-2 btn btn-outline-green float-right"><i className={`fa fa-refresh${this.state.slots_data_loading?" icon-spin":""}`}></i></button>
                                                <select className='form-control square col-sm-12 col-lg-12 col-12 m-1' readOnly={this.state.form_load} value={this.state.slot_number} onChange={(e) => { this.update_slot(e.target.value) }}>
                                                    {new Array(51).fill("", 1, 11).map((p, i) =><>
                                                        <option value={i} >{i} Slot{(this.state.slots_data[i])?" - "+this.state.slots_data[i]["target_group"]:""}</option>
                                                    </>)}
                                                </select>
                                            </Row>

                                            <Row className="my-3">
                                                <label className="labelfont">Enter the Source Channel:</label>
                                                <input type="url" className='form-control square col-sm-12 col-lg-12 col-12 my-2 m-1' readOnly={this.state.form_load || (this.state.slots_data[this.state.slot_number]?!this.state.slots_data[this.state.slot_number]["completed"]:false)} value={this.state.source_channel} onChange={(e) => { this.setState({ source_channel: e.target.value }) }} placeholder="Enter the Source Channel" />
                                            </Row>

                                            <Row className="my-3">
                                                <label className="labelfont">Enter the Target Group to Add:</label>
                                                <input type="url" className='form-control square col-sm-12 col-lg-12 col-12 my-2 m-1' readOnly={this.state.form_load || (this.state.slots_data[this.state.slot_number]?!this.state.slots_data[this.state.slot_number]["completed"]:false)} value={this.state.target_group} onChange={(e) => { this.setState({ target_group: e.target.value }) }} placeholder="Enter the Target Group to Add" />
                                            </Row>

                                            <Row className="my-3">
                                                <label className="labelfont">Enter the Chat ID to send Online Report:</label>
                                                <input type="text" className='form-control square col-sm-12 col-lg-12 col-12 my-2 m-1' readOnly={this.state.form_load || (this.state.slots_data[this.state.slot_number]?!this.state.slots_data[this.state.slot_number]["completed"]:false)} value={this.state.report_channel_id} onChange={(e) => { this.setState({ report_channel_id: e.target.value }) }} placeholder="Enter the Chat ID to send Online Report" />
                                            </Row>

                                            <Row className="my-3">
                                                <label className="labelfont">Enter the Message:</label>
                                                <textarea rows="4" className='form-control square col-sm-12 col-lg-12 col-12 my-2 m-1' readOnly={this.state.form_load || (this.state.slots_data[this.state.slot_number]?!this.state.slots_data[this.state.slot_number]["completed"]:false)} value={this.state.message} onChange={(e) => { this.setState({ message: e.target.value }) }} placeholder="Enter the message"></textarea>
                                            </Row>

                                            <>
                                            <Row className="my-3">
                                                <label className="labelfont">Upload photo:</label>
                                                <input type="file" name="photo" accept="image/png, image/jpeg" className='form-control square col-sm-12 col-lg-12 col-12 my-2 m-1' onChange={(e) => { this.setState({ photo: e.target.files[0] }) }} disabled={this.state.form_load || (this.state.slots_data[this.state.slot_number]?!this.state.slots_data[this.state.slot_number]["completed"]:false)}/>
                                            </Row>
                        </>

                                            {this.state.slots_data[this.state.slot_number]?<>
                                                <Row className="my-3">
                                                    <label>Current Status:</label>
                                                    <input type="text" className='form-control square col-sm-12 col-lg-12 col-12 m-1' disabled={true} placeholder={this.state.slots_data[this.state.slot_number]["reason"]} />
                                                </Row>
                                                {this.state.slots_data[this.state.slot_number]["created_on"]?<>
                                                    <Row className="my-3">
                                                        <label>Created On:</label>
                                                        <input type="text" className='form-control square col-sm-12 col-lg-12 col-12 m-1' disabled={true} placeholder={this.state.slots_data[this.state.slot_number]["created_on"]} />
                                                    </Row>
                                                </>:<></>}

                                                {this.state.slots_data[this.state.slot_number]["completed_on"]?<>
                                                    <Row className="my-3">
                                                        <label>Completed On:</label>
                                                        <input type="text" className='form-control square col-sm-12 col-lg-12 col-12 m-1' disabled={true} placeholder={this.state.slots_data[this.state.slot_number]["completed_on"]} />
                                                    </Row>
                                                </>:<></>}
                                            </>:<></>}

                                            <Row className="my-2 mt-4">
                                                {(!this.state.slots_data[this.state.slot_number] || this.state.slots_data[this.state.slot_number]["completed"])?<>
                                                    <Col className="mx-auto" lg="5" md="5" sm="5">
                                                        <button onClick={this.start_new_process} disabled={this.state.form_load} className={"btn btn-lg col-sm-12 col-lg-12 col-12 bg-green"}> {this.state.form_load?<i className='fa fa-refresh icon-spin'></i>:''} <a><img className="join-start-icon" /></a> Start New Process </button>
                                                    </Col>
                                                </>:<>
                                                    {this.state.slots_data[this.state.slot_number]["running"]?<>
                                                        <Col className="mx-auto" lg="5" md="5" sm="5">
                                                            <button onClick={this.stop_process} disabled={this.state.form_load} className="btn btn-lg col-sm-12 col-lg-12 col-12 bg-danger"> {this.state.form_load?<i className='fa fa-refresh icon-spin'></i>:''} Stop Process </button>
                                                        </Col>
                                                    </>:<>
                                                        <Col className="mx-auto" lg="5" md="5" sm="5">
                                                            <button onClick={this.resume_process} disabled={this.state.form_load} className={"btn btn-lg col-sm-12 col-lg-12 col-12 bg-green"}> {this.state.form_load?<i className='fa fa-refresh icon-spin'></i>:''} <a><img className="join-start-icon" /></a> Start Process </button>
                                                        </Col>
                                                    </>}
                                                    <Col className="mx-auto" lg="5" md="5" sm="5">
                                                        <button onClick={this.mark_process_done} disabled={this.state.form_load} className="btn btn-lg col-sm-12 col-lg-12 col-12 bg-success"> {this.state.form_load?<i className='fa fa-refresh icon-spin'></i>:''} Mark as Done </button>
                                                    </Col>
                                                </>}
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </></CardBody>
                        </Card>
                    </Col>
                </Row>

                {/* <VisibilitySensor partialVisibility onChange={(isVisible) => { this.setState({order_history_visibility: isVisible}) }}> */}
                <Row>
                    <Col className="mx-auto" lg="12" md="12" sm="12" >
                        <Card className="card-stats border-info">
                            <CardHeader>
                                <CardTitle>
                                    {/* <Row className="mx-auto">
                                        <label className="m-auto">Select Order :</label>
                                        <Col classname="mx-auto" lg="10" md="6" sm="6">
                                            <Select className='black my-2' disabled={this.state.form_load} onChange={(value) => {this.setState({history_order_id: value["value"]})}} name='selected_order' id='selected_order' options={this.state.orders_list} closeMenuOnSelect={true}/>
                                        </Col>
                                    </Row> */}
                                    <Row className="text-center" lg="12" md="12" sm="12" >
                                        {/* <Col lg="2" md="2" sm="2">
                                            <label>History Type:</label>
                                        </Col> */}
                                        <Col classname="mx-auto" lg="12" md="12" sm="12">
                                            <select className='form-control square col-sm-12 col-lg-12 col-12' readOnly={this.state.form_load} value={this.state.history_type} onChange={(e) => { this.setState({ history_type: e.target.value }) }}>
                                                <option value="orders" >Orders Based</option>
                                                <option value="target_group" >Target Group</option>
                                                <option value="source_channel" >Source Channel</option>
                                            </select>
                                        </Col>
                                    </Row>
                                </CardTitle>
                            </CardHeader>
                            {this.state.history_type === 'orders'?<>
                                <CardHeader>
                                    <CardTitle>
                                        <Row className="mb-3">
                                            <label className="m-auto">Select Order :</label>
                                            <Col lg="9" md="9" sm="9">
                                                <Select className='black my-2' disabled={this.state.form_load} onChange={(value) => {this.setState({history_order_id: value["value"]})}} name='selected_order' id='selected_order' options={this.state.orders_list} closeMenuOnSelect={true}/>
                                            </Col>
                                            <button onClick={this.get_orders_list} className="mx-3 my-auto btn btn-outline-green float-right"><i className={`fa fa-refresh${this.state.orders_list_loading?" icon-spin":""}`}></i></button>
                                        </Row>
                                        <Row className="mb-3">
                                            {(this.state.orders_history[this.state.history_order_id] && this.state.orders_history[this.state.history_order_id].tt_orders_history)?<>
                                                <Col className="my-auto mr-auto" lg="4" md="6" sm="8">
                                                    <button disabled={true} className="btn btn-lg btn-outline-warning col-sm-12 col-lg-12 col-12">History Updated on : {this.state.orders_history[this.state.history_order_id].tt_orders_history.toUTCString()}</button>
                                                </Col>
                                            </>:<></>}
                                            <Col className="ml-auto" lg="2" md="2" sm="2">
                                                <button onClick={this.get_orders_history} disabled={this.state.form_load || this.state.orders_history_loading || !this.state.history_order_id} className="btn btn-lg col-sm-12 col-lg-12 col-12 bg-green"> {this.state.orders_history_loading?<i className='fa fa-refresh icon-spin'></i>:''} {this.state.orders_history[this.state.history_order_id]?"Reload":"Get"} History </button>
                                            </Col>
                                        </Row>
                                    </CardTitle>
                                    {this.state.orders_history[this.state.history_order_id]?<>
                                        <CardTitle>
                                            <Row className="mx-auto">
                                                <Col className="my-auto" lg="12" md="12" sm="12" >
                                                    <Row>
                                                        <Col className="my-auto" lg="4" md="4" sm="4" >
                                                            <span className="large m-auto">Slot Used</span>
                                                        </Col>
                                                        <Col className="my-auto" lg="1" md="1" sm="1" >
                                                            <span className="large m-auto">:</span>
                                                        </Col>
                                                        <Col className="my-auto" lg="6" md="6" sm="6" >
                                                            <span className="large m-auto">{this.state.orders_history[this.state.history_order_id].request["slot_number"]}</span>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="my-auto" lg="4" md="4" sm="4" >
                                                            <span className="large m-auto">Created On</span>
                                                        </Col>
                                                        <Col className="my-auto" lg="1" md="1" sm="1" >
                                                            <span className="large m-auto">:</span>
                                                        </Col>
                                                        <Col className="my-auto" lg="6" md="6" sm="6" >
                                                            <span className="large m-auto">{this.state.orders_history[this.state.history_order_id].request["created_on"]}</span>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="my-auto" lg="4" md="4" sm="4" >
                                                            <span className="large m-auto">Online users Count</span>
                                                        </Col>
                                                        <Col className="my-auto" lg="1" md="1" sm="1" >
                                                            <span className="large m-auto">:</span>
                                                        </Col>
                                                        <Col className="my-auto" lg="6" md="6" sm="6" >
                                                            <span className="large m-auto">{this.state.orders_history[this.state.history_order_id].request["online_users_count"]}</span>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="my-auto" lg="4" md="4" sm="4" >
                                                            <span className="large m-auto">Target Group</span>
                                                        </Col>
                                                        <Col className="my-auto" lg="1" md="1" sm="1" >
                                                            <span className="large m-auto">:</span>
                                                        </Col>
                                                        <Col className="my-auto" lg="6" md="6" sm="6" >
                                                            <span className="large m-auto">{this.state.orders_history[this.state.history_order_id].request["target_group"]}</span>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="my-auto" lg="4" md="4" sm="4" >
                                                            <span className="large m-auto">Target Group ID</span>
                                                        </Col>
                                                        <Col className="my-auto" lg="1" md="1" sm="1" >
                                                            <span className="large m-auto">:</span>
                                                        </Col>
                                                        <Col className="my-auto" lg="6" md="6" sm="6" >
                                                            <span className="large m-auto">{this.state.orders_history[this.state.history_order_id].request["target_group_id"]}</span>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="my-auto" lg="4" md="4" sm="4" >
                                                            <span className="large m-auto">Source Channel</span>
                                                        </Col>
                                                        <Col className="my-auto" lg="1" md="1" sm="1" >
                                                            <span className="large m-auto">:</span>
                                                        </Col>
                                                        <Col className="my-auto" lg="6" md="6" sm="6" >
                                                            <span className="large m-auto">{this.state.orders_history[this.state.history_order_id].request["source_channel"]}</span>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="my-auto" lg="4" md="4" sm="4" >
                                                            <span className="large m-auto">Source Channel ID</span>
                                                        </Col>
                                                        <Col className="my-auto" lg="1" md="1" sm="1" >
                                                            <span className="large m-auto">:</span>
                                                        </Col>
                                                        <Col className="my-auto" lg="6" md="6" sm="6" >
                                                            <span className="large m-auto">{this.state.orders_history[this.state.history_order_id].request["source_channel_id"]}</span>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="my-auto" lg="4" md="4" sm="4" >
                                                            <span className="large m-auto">Online Report Chat ID</span>
                                                        </Col>
                                                        <Col className="my-auto" lg="1" md="1" sm="1" >
                                                            <span className="large m-auto">:</span>
                                                        </Col>
                                                        <Col className="my-auto" lg="6" md="6" sm="6" >
                                                            <span className="large m-auto">{this.state.orders_history[this.state.history_order_id].request["report_channel_id"]}</span>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="my-auto" lg="4" md="4" sm="4" >
                                                            <span className="large m-auto">Status</span>
                                                        </Col>
                                                        <Col className="my-auto" lg="1" md="1" sm="1" >
                                                            <span className="large m-auto">:</span>
                                                        </Col>
                                                        <Col className="my-auto" lg="6" md="6" sm="6" >
                                                            <span className="large m-auto">{this.state.orders_history[this.state.history_order_id].request["reason"]?this.state.orders_history[this.state.history_order_id].request["reason"]:"-"}</span>
                                                        </Col>
                                                    </Row>
                                                    {this.state.orders_history[this.state.history_order_id].request["completed"]?
                                                        <Row>
                                                            <Col className="my-auto" lg="4" md="4" sm="4" >
                                                                <span className="large m-auto">Completed On</span>
                                                            </Col>
                                                            <Col className="my-auto" lg="1" md="1" sm="1" >
                                                                <span className="large m-auto">:</span>
                                                            </Col>
                                                            <Col className="my-auto" lg="6" md="6" sm="6" >
                                                                <span className="large m-auto">{this.state.orders_history[this.state.history_order_id].request["completed_on"]}</span>
                                                            </Col>
                                                        </Row>
                                                    :<></>}
                                                </Col>
                                            </Row>
                                        </CardTitle>
                                    </>:<></>}
                                </CardHeader>
                                {this.state.orders_history[this.state.history_order_id]?<>
                                    <CardBody className="p-3 my-0" style={{display: 'block', maxWidth: '100%', width: '100%', height: 'auto', opacity: 1, transition: 'opacity 1000ms linear'}}>
                                        <UsersStatusFollowupTable data={this.state.orders_history[this.state.history_order_id].history} history_type="orders"/>
                                    </CardBody>
                                </>:<></>}
                            </>:this.state.history_type === 'source_channel'?<>
                                <CardHeader>
                                    <CardTitle>
                                        <Row className="mb-3">
                                            <label className="m-auto">Select Source Channel :</label>
                                            <Col lg="9" md="9" sm="9">
                                                <Select className='black my-2' disabled={this.state.form_load} onChange={(value) => {this.setState({history_source_channel_link: value["value"]})}} name='selected_source_channel' id='selected_source_channel' options={this.state.source_channel_list} closeMenuOnSelect={true}/>
                                            </Col>
                                            <button onClick={this.get_source_channel_list} className="mx-3 my-auto btn btn-outline-green float-right"><i className={`fa fa-refresh${this.state.source_channel_list_loading?" icon-spin":""}`}></i></button>
                                        </Row>
                                        <Row className="mb-3">
                                            {(this.state.source_channel_history[this.state.history_source_channel_link] && this.state.source_channel_history[this.state.history_source_channel_link].tt_source_channel_history)?<>
                                                <Col className="my-auto mr-auto" lg="4" md="6" sm="8">
                                                    <button disabled={true} className="btn btn-lg btn-outline-warning col-sm-12 col-lg-12 col-12">History Updated on : {this.state.source_channel_history[this.state.history_source_channel_link].tt_source_channel_history.toUTCString()}</button>
                                                </Col>
                                            </>:<></>}
                                            <Col className="ml-auto" lg="2" md="2" sm="2">
                                                <button onClick={this.get_source_channel_history} disabled={this.state.form_load || this.state.source_channel_history_loading || !this.state.history_source_channel_link} className="btn btn-lg col-sm-12 col-lg-12 col-12 bg-green"> {this.state.source_channel_history_loading?<i className='fa fa-refresh icon-spin'></i>:''} {this.state.source_channel_history[this.state.history_source_channel_link]?"Reload":"Get"} History </button>
                                            </Col>
                                        </Row>
                                    </CardTitle>
                                </CardHeader>
                                {this.state.source_channel_history[this.state.history_source_channel_link]?<>
                                    <CardBody className="p-3 my-0" style={{display: 'block', maxWidth: '100%', width: '100%', height: 'auto', opacity: 1, transition: 'opacity 1000ms linear'}}>
                                        {(this.state.source_channel_history[this.state.history_source_channel_link].details.length > 0)?<>
                                            <JsonTable data={this.state.source_channel_history[this.state.history_source_channel_link].details} />
                                        </>:<></>}
                                        <UsersStatusFollowupTable data={this.state.source_channel_history[this.state.history_source_channel_link].history} history_type="source_channel"/>
                                    </CardBody>
                                </>:<></>}
                            </>:this.state.history_type === 'target_group'?<>
                                <CardHeader>
                                    <CardTitle>
                                        <Row className="mb-3">
                                            <label className="m-auto">Select Target Group :</label>
                                            <Col lg="9" md="9" sm="9">
                                                <Select className='black my-2' disabled={this.state.form_load} onChange={(value) => {this.setState({history_target_group_link: value["value"]})}} name='selected_target_group' id='selected_target_group' options={this.state.target_group_list} closeMenuOnSelect={true}/>
                                            </Col>
                                            <button onClick={this.get_target_group_list} className="mx-3 my-auto btn btn-outline-green float-right"><i className={`fa fa-refresh${this.state.target_group_list_loading?" icon-spin":""}`}></i></button>
                                        </Row>
                                        <Row className="mb-3">
                                            {(this.state.target_group_history[this.state.history_target_group_link] && this.state.target_group_history[this.state.history_target_group_link].tt_target_group_history)?<>
                                                <Col className="my-auto mr-auto" lg="4" md="6" sm="8">
                                                    <button disabled={true} className="btn btn-lg btn-outline-warning col-sm-12 col-lg-12 col-12">History Updated on : {this.state.target_group_history[this.state.history_target_group_link].tt_target_group_history.toUTCString()}</button>
                                                </Col>
                                            </>:<></>}
                                            <Col className="ml-auto" lg="2" md="2" sm="2">
                                                <button onClick={this.get_target_group_history} disabled={this.state.form_load || this.state.target_group_history_loading || !this.state.history_target_group_link} className="btn btn-lg col-sm-12 col-lg-12 col-12 bg-green"> {this.state.target_group_history_loading?<i className='fa fa-refresh icon-spin'></i>:''} {this.state.target_group_history[this.state.history_target_group_link]?"Reload":"Get"} History </button>
                                            </Col>
                                        </Row>
                                    </CardTitle>
                                </CardHeader>
                                {this.state.target_group_history[this.state.history_target_group_link]?<>
                                    <CardBody className="p-3 my-0" style={{display: 'block', maxWidth: '100%', width: '100%', height: 'auto', opacity: 1, transition: 'opacity 1000ms linear'}}>
                                        {(this.state.target_group_history[this.state.history_target_group_link].details.length > 0)?<>
                                            <JsonTable data={this.state.target_group_history[this.state.history_target_group_link].details} />
                                        </>:<></>}
                                        <UsersStatusFollowupTable data={this.state.target_group_history[this.state.history_target_group_link].history} history_type="target_group"/>
                                    </CardBody>
                                </>:<></>}
                            </>:<></>}
                        </Card>
                    </Col>
                </Row>
                {/* </VisibilitySensor> */}

                {/* <Row>
                    <Col className="mx-auto" lg="12" md="12" sm="12" >
                        <Card className="card-stats border-info">
                            <CardHeader>
                                <Row className="mb-3">
                                    {this.state.report_id_updating?<>
                                        <Col className="mr-auto my-auto" lg="6" md="6" sm="6">
                                            <input type="text" className='form-control square col-sm-12 col-lg-12 col-12 my-2 m-1' readOnly={this.state.form_load || this.state.report_id_loading} value={this.state.report_id_new} onChange={(e) => { this.setState({ report_id_new: e.target.value }) }} placeholder="Please Enter The New Report ID" />
                                        </Col>
                                        <Col className="ml-auto my-auto" lg="4" md="4" sm="4">
                                            <Row>
                                            <Col className="mr-auto my-auto" lg="6" md="6" sm="6">
                                                <button onClick={this.save_report_id} disabled={this.state.form_load || this.state.report_id_loading} className="btn btn-lg col-sm-12 col-lg-12 col-12 bg-green"> {this.state.report_id_loading?<i className='fa fa-refresh icon-spin'></i>:''} Save Report ID </button>
                                            </Col>
                                            <Col className="mr-auto my-auto" lg="6" md="6" sm="6">
                                                <button onClick={() => {this.setState({report_id_updating:false})}} disabled={this.state.form_load || this.state.report_id_loading} className="btn btn-lg col-sm-12 col-lg-12 col-12 bg-red"> {this.state.report_id_loading?<i className='fa fa-refresh icon-spin'></i>:''} Cancel </button>
                                            </Col>
                                            </Row>
                                        </Col>
                                    </>:<>
                                        <Col className="mr-auto my-auto" lg="6" md="6" sm="6">
                                            <button disabled={true} className="btn btn-lg btn-outline-warning col-sm-12 col-lg-12 col-12">Current Report ID : {this.state.report_id?this.state.report_id:"NO REPORT ID"}{(this.state.tt_report_id && this.state.report_id)?" Updated on " + this.state.tt_report_id.toUTCString():''}</button>
                                        </Col>
                                        <Col className="ml-auto my-auto" lg="2" md="2" sm="2">
                                            <button onClick={() => {this.setState({report_id_updating:true})}} disabled={this.state.form_load || this.state.report_id_loading} className="btn btn-lg col-sm-12 col-lg-12 col-12 bg-green"> {this.state.report_id_loading?<i className='fa fa-refresh icon-spin'></i>:''} Update Report ID </button>
                                        </Col>
                                    </>}
                                </Row>
                            </CardHeader>
                        </Card>
                    </Col>
                </Row> */}
                {/* {console.log(this.props.auth.user.group.find(obj => {return obj === 'adder'}))} */}

            </div>
        </>);
    }
}
const mapStateToProps = (state) => ({
    auth: state.auth,
});
export default connect(mapStateToProps)(UserStatusFollowup);
