import axios from "axios.js";
import React from "react";
import { connect } from "react-redux";
import Select from 'react-select'
import VisibilitySensor from 'react-visibility-sensor';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import WalletTransactionTable from "views/WalletTransaction.js";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
    Button,
} from "reactstrap";

const MySwal = withReactContent(Swal)

// Core Component
class Wallet extends React.Component {
    constructor() {
        super()
        this.state = {
            summary: {
                credit: 0,
                debit: 0,
                pend_credit: 0,
                pend_debit: 0,
            },
            summary_loading: true,
            summary_ts: null,
            transaction_loading: false,
            transaction: null,
            transaction_ts: null,
            payments_loading: true,
            payments: null,
            payment_selected: null,
            currency_type_selected: null,

            transaction_section: false,
            add_money_section: true,
            payment_qr_section: false,

            adding_money: false,
            add_amount: 0,
            add_description: '',
        }
    }

    makeCall(request_event, payloads={}) {
        let payload_keys = Object.keys(payloads)
        let payload_str = '?'
        if (payload_keys.length > 0) {
            payload_keys.forEach(function(key) {
                payload_str += key + "=" + payloads[key] + "&"
            })
        }

        axios.defaults.withCredentials = false;
        return axios({
            url: this.props.auth.BaseUrl + `wallet/${request_event}${payload_str.slice(0, -1)}`,
            headers: this.props.auth.config.headers,
            method: 'GET',
        })
    }

    getBalance = (first_run=false) => {
        this.setState({summary_loading: true})
        this.makeCall("get_wallet").then((response) => {
            if (first_run){
                this.getPendingPayments()
            }
            this.setState({summary: response.data, summary_loading: false, summary_ts: new Date()})
        })
    }

    getTransaction = () => {
        this.setState({transaction_loading: true})
        this.makeCall("get_transaction").then((response) => {
            this.setState({transaction: response.data, transaction_loading: false, transaction_ts: new Date()})
        })
    }

    getPendingPayments = () => {
        this.setState({payments_loading: true})
        this.makeCall("get_pending_payments").then((response) => {
            this.setState({payments: response.data, payments_loading: false})
        })
    }

    toggleSection = (section) => {
        if (section === "transaction") {
            if (!this.state.transaction_ts){
                this.getTransaction()
            }
            this.setState({transaction_section: true, add_money_section: false, payment_qr_section: false})
        } else if (section === "add_money") {
            this.setState({transaction_section: false, add_money_section: true, payment_qr_section: false})
        } else if (section === "payment_qr") {
            this.setState({transaction_section: false, add_money_section: false, payment_qr_section: true})
        }
    }

    addMoney = () => {
        this.setState({adding_money: true})
        this.makeCall("add_amount", {amount: this.state.add_amount, description: this.state.add_description}).then((response) => {
            let payments = {...this.state.payments}
            payments[response.data["transact_id"]] = response.data
            this.setState({payments: payments, adding_money: false})
            this.toggleSection("payment_qr")
        })
    }

    capitalise = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    copyToClipboard = (str) => {
        navigator.clipboard.writeText(str);
        window.alert(`'${str}' Copied to Clipboard...!`)
    }

    componentDidMount = () => {
        this.getBalance(true)
    }

    componentWillUnmount() {}

    checkBoolVal(cond) {
        if (cond == 'true'){
            return true
        } else if (cond == 'false') {
            return false
        } else {
            return undefined
        }
    }

    notificationAlert = React.createRef();

    render() {
        return (<>
            <div className="content">
                <Row>
                    <Col lg="3" md="6" sm="12">
                        <Card className="card-stats card border-green">
                            <CardBody>
                                <Row>
                                    <Col md="3" xs="3"><div className="icon-big text-center icon-warning"><a><img className="join-settings-icon" /></a></div></Col>
                                    <Col className="my-auto" md="9" xs="9">
                                        <div className="numbers">
                                            <p className="card-category">Balance</p>
                                            <p>{this.state.summary_loading?<i className='fa fa-refresh icon-spin'></i>:this.state.summary["credit"] - this.state.summary["debit"]}</p>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <hr />
                                <div className="stats">
                                    <i className="fas fa-sync-alt" />
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col lg="3" md="6" sm="12">
                        <Card className="card-stats card border-green">
                            <CardBody>
                                <Row>
                                    <Col md="3" xs="3"><div className="icon-big text-center icon-warning"><a><img className="join-joined-icon" /></a></div></Col>
                                    <Col className="my-auto" md="9" xs="9">
                                        <div className="numbers">
                                            <p className="card-category">Pending Balance</p>
                                            <p>{this.state.summary_loading?<i className='fa fa-refresh icon-spin'></i>:`${this.state.summary["pend_credit"]?this.state.summary["pend_credit"]:''}${this.state.summary["pend_debit"]?' - ' + this.state.summary["pend_debit"]:''}${(this.state.summary["pend_debit"] || this.state.summary["pend_credit"])?'':'0'}`}</p>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <hr />
                                <div className="stats">
                                    <i className="fas fa-sync-alt" />
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col lg="3" md="6" sm="12">
                        <Card className="card-stats card border-green">
                            <CardBody>
                                <Row>
                                    <Col md="3" xs="3"><div className="icon-big text-center icon-warning"><a><img className="join-settings-icon" /></a></div></Col>
                                    <Col className="my-auto" md="9" xs="9">
                                        <div className="numbers">
                                            <p className="card-category">Credit</p>
                                            <p>{this.state.summary_loading?<i className='fa fa-refresh icon-spin'></i>:this.state.summary["credit"]}</p>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <hr />
                                <div className="stats">
                                    <i className="fas fa-sync-alt" />
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col lg="3" md="6" sm="12">
                        <Card className="card-stats card border-green">
                            <CardBody>
                                <Row>
                                    <Col md="3" xs="3"><div className="icon-big text-center icon-warning"><a><img className="join-joined-icon" /></a></div></Col>
                                    <Col className="my-auto" md="9" xs="9">
                                        <div className="numbers">
                                            <p className="card-category">Debit</p>
                                            <p>{this.state.summary_loading?<i className='fa fa-refresh icon-spin'></i>:this.state.summary["debit"]}</p>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <hr />
                                <div className="stats">
                                    <i className="fas fa-sync-alt" />
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col className="mr-auto" lg="2" md="2" sm="2">
                        <button onClick={this.getBalance} disabled={this.state.summary_loading} className="btn btn-lg col-12 bg-green"> {this.state.summary_loading?<i className='fa fa-refresh icon-spin'></i>:''} {this.state.summary_ts?"Reload":"Get"} Counts </button>
                    </Col>
                    {this.state.summary_ts?<>
                        <Col className="my-auto ml-auto" lg="6" md="8" sm="10">
                            <button disabled={true} className="btn btn-lg btn-outline-warning col-12">Counts Updated on : {this.state.summary_ts.toUTCString()}</button>
                        </Col>
                    </>:<></>}
                </Row>
                <Row className="mb-3">
                    <Col className="mx-auto" lg="3" md="3" sm="3">
                        <button disabled={true} className="btn btn-lg btn-outline-warning col-12 bg-success"> Open Sections : </button>
                    </Col>
                    <Col className="mx-auto" lg="3" md="3" sm="3">
                        <button onClick={() => {this.toggleSection("transaction")}} disabled={this.state.transaction_section} className="btn btn-lg col-12 bg-success"> {this.state.transaction_section?"":"Open"} Transactions </button>
                    </Col>
                    <Col className="mx-auto" lg="3" md="3" sm="3">
                        <button onClick={() => {this.toggleSection("add_money")}} disabled={this.state.add_money_section} className="btn btn-lg col-12 bg-success"> {this.state.add_money_section?"":"Open"} Add Money </button>
                    </Col>
                    <Col className="mx-auto" lg="3" md="3" sm="3">
                        <button onClick={() => {this.toggleSection("payment_qr")}} disabled={this.state.payment_qr_section} className="btn btn-lg col-12 bg-success"> {this.state.payment_qr_section?"":"Open"} Payment QR </button>
                    </Col>
                </Row>
                {this.state.transaction_section?<>
                    {this.state.transaction_ts?<>
                        <Row>
                            <Col lg="12" md="12" sm="12">
                                <Card>
                                    <CardTitle>
                                        <Row className="mt-3">
                                            <Col className="m-auto text-center" lg="10" md="10" sm="10">
                                                <p className="h3 my-auto">Transaction History</p>
                                            </Col>
                                        </Row>
                                    </CardTitle>
                                    <CardBody>
                                        {this.state.transaction?<>
                                            <WalletTransactionTable data={this.state.transaction} />
                                        </>:<></>}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </>:<></>}
                    <Row className="mb-3">
                        <Col className="mr-auto" lg="2" md="2" sm="2">
                            <button onClick={this.getTransaction} disabled={this.state.transaction_loading} className="btn btn-lg col-12 bg-green"> {this.state.transaction_loading?<i className='fa fa-refresh icon-spin'></i>:''} {this.state.transaction_ts?"Reload":"Open"} Transaction </button>
                        </Col>
                        {this.state.transaction_ts?<>
                            <Col className="my-auto ml-auto" lg="6" md="8" sm="10">
                                <button disabled={true} className="btn btn-lg btn-outline-warning col-12">Transactions Updated on : {this.state.transaction_ts.toUTCString()}</button>
                            </Col>
                        </>:<></>}
                    </Row>
                </>:<></>}

                {this.state.payment_qr_section?<>
                    {this.state.payments_loading?<div id="loader"></div>:<></>}
                    {this.state.payments?<>
                        <Row>
                            <Col lg="6" md="8" sm="10" className="mx-auto">
                                <Card>
                                    <CardTitle>
                                        <Row className="mt-3">
                                            <Col className="m-auto text-center" lg="10" md="10" sm="10">
                                                <p className="h3 my-auto">Pending Payments</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="m-auto text-center" lg="10" md="10" sm="10">
                                                <Row className="mx-auto my-2">
                                                    <Col className="m-auto text-center" lg="12" md="12" sm="12">
                                                        <select className='form-control square col-12 my-2 m-1' value={this.state.payment_selected} onChange={(e) => {this.setState({payment_selected: e.target.value, currency_type_selected: ''})}}>
                                                            {this.state.payment_selected?<></>:<>
                                                                <option value={''} >Select</option>
                                                            </>}
                                                            {Object.keys(this.state.payments).map( key => <option value={key} >{this.state.payments[key].amount} - {this.state.payments[key].currency_symbol}</option>)}
                                                        </select>
                                                        {this.state.payment_selected?<>
                                                            <select className='form-control square col-12 my-2 m-1' value={this.state.currency_type_selected} onChange={(e) => {this.setState({currency_type_selected: e.target.value})}}>
                                                                {this.state.currency_type_selected?<></>:<>
                                                                    <option value={''} >Select</option>
                                                                </>}
                                                                {Object.keys(this.state.payments[this.state.payment_selected].addresses).map( key => <option value={key} >{this.capitalise(this.state.payments[this.state.payment_selected].addresses[key].currency_type)} ({this.state.payments[this.state.payment_selected].addresses[key].currency_symbol})</option>)}
                                                            </select>
                                                        </>:<></>}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </CardTitle>
                                    {(this.state.payment_selected && this.state.currency_type_selected)?<>
                                        <CardBody className="mx-auto col-12">
                                            <CardTitle>
                                                <Row className="mx-auto col-12">
                                                    <img className="mx-auto my-2" src={this.props.auth.BaseUrl + `wallet/generate_qr/?address=${this.state.payments[this.state.payment_selected].addresses[this.state.currency_type_selected].address}&currency_type=${this.state.payments[this.state.payment_selected].addresses[this.state.currency_type_selected].currency_type}&amount=${this.state.payments[this.state.payment_selected].addresses[this.state.currency_type_selected].amount}`} alt="QR Scanner is Loading...!" width="75%"/>
                                                </Row>
                                                <Row className="col-12 mx-auto my-2">
                                                    <Col className="text-right" lg="4" md="4" sm="4"><p className="h5">Address</p></Col>
                                                    <Col className="mx-auto text-center" lg="1" md="1" sm="1"><p className="h5">:</p></Col>
                                                    <Col className="my-auto" lg="7" md="7" sm="7" style={{cursor: "pointer"}} onClick={() => {this.copyToClipboard(this.state.payments[this.state.payment_selected].addresses[this.state.currency_type_selected].address)}}><p className="h5"><b>{this.state.payments[this.state.payment_selected].addresses[this.state.currency_type_selected].address}</b> (Click to Copy)</p></Col>
                                                </Row>
                                                <Row className="col-12 mx-auto my-2">
                                                    <Col className="text-right" lg="4" md="4" sm="4"><p className="h5">Crypto Amount</p></Col>
                                                    <Col className="mx-auto text-center" lg="1" md="1" sm="1"><p className="h5">:</p></Col>
                                                    <Col className="my-auto" lg="7" md="7" sm="7" style={{cursor: "pointer"}} onClick={() => {this.copyToClipboard(this.state.payments[this.state.payment_selected].addresses[this.state.currency_type_selected].amount)}}><p className="h5"><b>{this.state.payments[this.state.payment_selected].addresses[this.state.currency_type_selected].amount} {this.state.payments[this.state.payment_selected].addresses[this.state.currency_type_selected].currency_symbol}</b> (Click to Copy)</p></Col>
                                                </Row>
                                                <Row className="col-12 mx-auto my-2">
                                                    <Col className="text-right" lg="4" md="4" sm="4"><p className="h5">Amount</p></Col>
                                                    <Col className="mx-auto text-center" lg="1" md="1" sm="1"><p className="h5">:</p></Col>
                                                    <Col className="my-auto" lg="7" md="7" sm="7"><p className="h5">{this.state.payments[this.state.payment_selected].amount} ({this.state.payments[this.state.payment_selected].currency_symbol})</p></Col>
                                                </Row>
                                                <Row className="col-12 mx-auto my-2">
                                                    <Col className="text-right" lg="4" md="4" sm="4"><p className="h5">Description</p></Col>
                                                    <Col className="mx-auto text-center" lg="1" md="1" sm="1"><p className="h5">:</p></Col>
                                                    <Col className="my-auto" lg="7" md="7" sm="7"><p className="h5">{this.state.payments[this.state.payment_selected].description}</p></Col>
                                                </Row>
                                            </CardTitle>
                                        </CardBody>
                                    </>:<></>}
                                </Card>
                            </Col>
                        </Row>
                    </>:<></>}
                </>:<></>}

                {this.state.add_money_section?<>
                    <Row>
                        <Col lg="6" md="8" sm="10" className="mx-auto">
                            <Card>
                                <CardTitle>
                                    <Row className="mt-3">
                                        <Col className="m-auto text-center" lg="10" md="10" sm="10">
                                            <p className="h3 my-auto">Add Money to Wallet</p>
                                        </Col>
                                    </Row>
                                </CardTitle>
                                <CardBody className="mx-auto col-12">
                                    <Row className="col-12 mx-auto">
                                        <Col className="text-right my-auto" lg="4" md="4" sm="4"><p className="h5 my-auto">Amount</p></Col>
                                        <Col className="m-auto text-center" lg="1" md="1" sm="1"><p className="h5 my-auto">:</p></Col>
                                        <Col className="my-auto" lg="4" md="4" sm="4">
                                            <input type="number" disabled={this.state.adding_money} className='form-control square col-12 my-auto m-1' onChange={(e) => {if(e.target.value>=0){this.setState({add_amount: e.target.value})}}} value={this.state.add_amount} placeholder="Place Holder Text" />
                                        </Col>
                                        <Col lg="3" md="3" sm="3" className="text-left my-auto"><p className="h5 my-auto">$ USD</p></Col>
                                    </Row>
                                    <br/>
                                    <Row className="col-12 mx-auto">
                                        <Col className="text-right my-auto" lg="4" md="4" sm="4"><p className="h5 my-auto">Description</p></Col>
                                        <Col className="m-auto text-center" lg="1" md="1" sm="1"><p className="h5 my-auto">:</p></Col>
                                        <Col className="my-auto" lg="7" md="7" sm="7">
                                            <textarea disabled={this.state.adding_money} className='form-control square col-12 my-auto m-1 p-2' style={{fontSize: "16px"}} onChange={(e) => {this.setState({add_description: e.target.value})}} value={this.state.add_description} placeholder="Please Provide the Description" />
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row className="col-8 mx-auto">
                                        <button onClick={this.addMoney} disabled={this.state.adding_money} className="btn btn-lg col-12 bg-green"> {this.state.adding_money?<i className='fa fa-refresh icon-spin'></i>:''} Make Payment </button>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </>:<></>}

            </div>
        </>);
    }
}
const mapStateToProps = state => ({
    auth: state.auth
});
export default connect(mapStateToProps)(Wallet);

