import axios from "axios.js";
import React from "react";
import { connect } from "react-redux";
import Select from 'react-select'
import VisibilitySensor from 'react-visibility-sensor';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import JALLambdaChannelTable from './JALUserTransactionTable'
import JsonTable from "views/Simple_table.js";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
    Button,
} from "reactstrap";

const MySwal = withReactContent(Swal)

// Core Component
class JALLambdaAdmin extends React.Component {
    constructor() {
        super()
        this.state = {
            PGLIMIT: 6,
            old_target_url: '',
            new_target_url: '',
            channels: [],

            updating_link: false,
            channel_lists_loading: false,
            edit_category: false,

            open_auto_leave: false,
            open_url_updater: false,
            open_category: false,
            open_admin_req_cont: false,
            open_user_control: false,
            open_minimal_left_group: false,
            page_no_user_panel: 0,

            url_update_spinner: true,
            category_spinner: true,
            auto_leave_spinner: true,
            request_control_spinner: false,
            run_scheduled_spinner: false,
            schedule_running_spinner: false,
            reschedule_all_spinner: false,
            user_control_spinner: false,
            user_transaction_spinner: false,

            categories: {},
            selected_cat: {
                category_id: 0,
                category: 'Please Select Category',
                description: 'Please Select Category',
                service_name: 'Please Select Category',
                price: 0,
                count: 0,
                no_of_process: 0,
                active: false
            },
            change_in_sel_cat: false,

            auto_leave_data_cat: 0,
            auto_leave_data: {},
            selected_auto_leave: {
                auto_leave_id: 0,
                category_id: 0,
                minutes: 0,
                hours: 0,
                days: 0,
                percentage: 0
            },

            req_cont_minutes: 0,
            req_cont_hours: 0,
            req_cont_days: 0,
            req_cont_down_time: false,
            req_cont_auto_leave: false,

            edit_auto_leave: false,
            change_in_auto_leave: false,

            jal_users: {},

            add_balance_amnt: 0,
            open_enter_balance: 0,
            open_transaction: 0,

            transaction_data: [],
            transaction_user: 0,

            open_user_access_controls: 0,
            open_user_categories: 0,
            user_categories_user_id: 0,
            user_categories_data: {},
            user_categories_data_changed: false,

            minimal_left_group: [],
            selected_min_left_group: null,
            minimal_left_group_loading: false,
            new_min_left_group_link: '',
            new_min_left_group_id: null,
            delete_min_left_group_id: '',
            new_minimal_left_group_loading: false,
        }
    }

    make_call(request_event, data={}, admin=true) {
        console.log("Making Request : ", admin?'admin/':'', request_event)
        axios.defaults.withCredentials = false;
        return axios({
            url: `https://jalgateway.telegram-software.com/api/${admin?'admin/':''}${request_event}`,
            method: 'POST',
            data: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'X-Api-Key': localStorage.getItem('jal_api_key')
            },
        })
    }

    get_minimal_left_groups = () => {
        this.setState({minimal_left_group_loading: true})
        this.make_call("all_minimal_left_group", {}, false).then((response) => {
            console.log(response.data["data"])
            if (response.data["data"]){
                this.setState({minimal_left_group: response.data["data"]})
            } else if (response.data["message"]){
                Swal.fire({
                    title: "Message",
                    html: response.data["message"],
                    icon: "info",
                })
            }
        })
        this.setState({minimal_left_group_loading: false})
    }

    add_minimum_left_group = () => {
        this.setState({minimal_left_group_loading: true})
        let new_min_left_group_link = this.validate_url(this.state.new_min_left_group_link)
        let payload = {}
        if (new_min_left_group_link){
            payload["target_url"] = new_min_left_group_link
        }
        if (this.state.new_min_left_group_id){
            payload["channel_id"] = this.state.new_min_left_group_id
        }
        if (payload == {}){
            Swal.fire({
                title: "Please Choose atleast one",
                html: "You have to choose either the link or channel ID...",
                icon: "info",
            })
            return
        }
        this.make_call("add_minimal_left_group", payload, false).then((response) => {
            if (response.data["message"]){
                Swal.fire({
                    title: "Message",
                    html: response.data["message"],
                    icon: "info",
                })
            }
            if (response.data["data"]){
                this.setState({minimal_left_group: response.data["data"]})
            }
        })
        this.setState({minimal_left_group_loading: false, new_min_left_group_link: '', new_min_left_group_id: ''})
    }

    delete_minimum_left_group = () => {
        this.setState({minimal_left_group_loading: true})
        this.make_call("delete_minimal_left_group", {channel_id: this.state.delete_min_left_group_id}, false).then((response) => {
            if (response.data["message"]){
                Swal.fire({
                    title: "Message",
                    html: response.data["message"],
                    icon: "info",
                })
            }
            if (response.data["data"]){
                this.setState({minimal_left_group: response.data["data"]})
            }
        })
        this.setState({minimal_left_group_loading: false, delete_min_left_group_id: ''})
    }


    getChannels = () => {
        this.setState({channel_lists_loading: true, url_update_spinner: true})
        this.make_call("get_channels", {}, false).then((response) => {
            this.setState({channels: response.data, channel_lists_loading: false, url_update_spinner: false})
        })
    }

    update_target_url = () => {
        let new_target_url = this.validate_url(this.state.new_target_url)
        if (this.state.old_target_url == ''){
            Swal.fire({
                title: "Please Select old Channel",
                html: "The old Target URL should not be Empty...",
                icon: "info",
            })
            return
        } else if ((new_target_url == '') || (new_target_url == null)){
            Swal.fire({
                title: "Please Provide New Channel Link",
                html: `The new Target URL should ${(new_target_url == '')?'not be Empty':'be in proper format'}...`,
                icon: "info",
            })
            return
        }

        this.setState({updating_link: true})

        this.make_call("update_target_url", {"old_target_url": this.state.old_target_url, "new_target_url": new_target_url}).then((response) => {
            this.setState({updating_link: false, old_target_url: '', new_target_url: ''}, this.getChannels)
            Swal.fire({
                title: "Message",
                html: response.data["message"],
                icon: "info",
            })
        })
    }

    validate_url = (target_link) => {
        if (target_link.startsWith("@")) {
            target_link = target_link.replace("@", "https://t.me/")
        } else if (target_link.startsWith("t.me/")) {
            target_link = target_link.replace("t.me/", "https://t.me/")
        } else if (target_link.startsWith("http://t.me/")) {
            target_link = target_link.replace("http://t.me/", "https://t.me/")
        } else if (!target_link.startsWith("https://t.me/")){
            Swal.fire({
                title: "Improper Format",
                html: "The Target URL is not matched with any supported format (https://t.me/channel or https://t.me/channel or @channel)",
                icon: "warning",
            })
            return null
        }
        if (target_link.startsWith("https://t.me/+")) {
            target_link = target_link.replace("https://t.me/+", "https://t.me/joinchat/")
        }
        return target_link
    }

    getCategories = () => {
        this.setState({category_spinner: true, categories: {}})
        this.make_call("get_categories", {}, true).then((response) => {
            this.setState({categories: response.data, category_spinner: false})
            this.setCategory(0)
        })
    }

    setCategory = (cat_id) => {
        if (cat_id == 0){
            this.setState({
                selected_cat: {
                    category_id: 0,
                    category: 'Please Select Category',
                    description: 'Please Select Category',
                    service_name: 'Please Select Category',
                    price: 0,
                    count: 0,
                    no_of_process: 0,
                    active: false,
                },
                edit_category: false,
                change_in_sel_cat: false
            })
        } else if (cat_id == -1){
            this.setState({
                selected_cat: {
                    category_id: cat_id,
                    category: '',
                    description: '',
                    service_name: '',
                    price: 1,
                    count: 1000,
                    no_of_process: 20,
                    active: true,
                },
                edit_category: true,
                change_in_sel_cat: false
            })
        } else {
            this.setState({
                selected_cat: this.state.categories[cat_id],
                change_in_sel_cat: false,
                edit_category: false
            })
        }
    }

    setSelectedCat = (key, value) => {
        let cat = {...this.state.selected_cat};
        cat[key] = value;
        this.setState({selected_cat: cat, change_in_sel_cat: true})
    }

    saveCategoryChanges = () => {
        Swal.fire({
            title: `${(this.state.selected_cat["category_id"] == -1)?"Create":"Update"} Category`,
            html: `Are you sure to ${(this.state.selected_cat["category_id"] == -1)?"Create":"Update"} the Category.`,
            showDenyButton: true,
            confirmButtonText: `Yes, Save`,
            denyButtonText: `No, Cancel`,
            icon: "info",
        }).then((result) => {
            if (result.isConfirmed){
                this.setState({category_spinner: true})
                this.make_call((this.state.selected_cat["category_id"] == -1)?"create_category":"update_category", this.state.selected_cat, true).then((response) => {
                    if (response.data["status"] != 404){
                        let cat = {...this.state.categories};
                        cat[response.data["data"]["category_id"]] = response.data["data"];
                        this.setState({categories: cat, selected_cat: response.data["data"], category_spinner: false, edit_category: false})
                    }
                    Swal.fire({
                        title: "Message",
                        html: response.data["message"],
                        icon: "info",
                    })
                })
            }
        })
    }

    deleteCategory = (category_id) => {
        Swal.fire({
            title: `Delete Warning...⚠️`,
            html: "Are you sure to Delete the Category, All the request data Associated with this can be missed. This process is not reversible.!",
            showDenyButton: true,
            confirmButtonText: `No, Cancel`,
            denyButtonText: `Yes, Delete ⚠️`,
            icon: "warning",
        }).then((result) => {
            if (result.isDenied) {
                this.setState({category_spinner: true})
                this.make_call("delete_category", {category_id: category_id}, true).then((response) => {
                    if (response.data["status"] != 404){
                        let cat = {...this.state.categories};
                        if (cat[category_id]){
                            delete cat[category_id]
                        }
                        this.setState({categories: cat, category_spinner: false})
                    }
                    this.setCategory(0)
                    Swal.fire({
                        title: "Message",
                        html: response.data["message"],
                        icon: "info",
                    })
                })
            }
        })
    }

    getAutoLeaveCharts = (reload=false) => {
        if ((this.state.auto_leave_data_cat != this.state.selected_cat["category_id"]) || (reload)){
            this.setState({auto_leave_spinner: true, auto_leave_data: {}})
            this.make_call("get_auto_leave", {category_id: this.state.selected_cat["category_id"]}, true).then((response) => {
                if (response.data["status"] == 200){
                    this.setState({auto_leave_data: response.data["data"], auto_leave_spinner: false, auto_leave_data_cat: this.state.selected_cat["category_id"]})
                } else {
                    Swal.fire({
                        title: "Message",
                        html: response.data["message"],
                        icon: "info",
                    })
                }
            })
        }
    }

    setAutoLeave = (auto_leave_id) => {
        if (auto_leave_id == 0){
            this.setState({
                selected_auto_leave: {
                    auto_leave_id: 0,
                    category_id: 0,
                    minutes: 0,
                    hours: 0,
                    days: 0,
                    percentage: 0
                },
                edit_auto_leave: false,
                change_in_auto_leave: false
            })
            this.clearNewAutoLeave()
        } else if (auto_leave_id == -1){ 
            this.setState({
                selected_auto_leave: {
                    auto_leave_id: auto_leave_id,
                    category_id: this.state.selected_cat["category_id"],
                    minutes: 0,
                    hours: 0,
                    days: 0,
                    percentage: 0
                },
                edit_auto_leave: true,
                change_in_auto_leave: false
            }, () => {
                let autoLeave = {...this.state.auto_leave_data, "-1": this.state.selected_auto_leave}
                this.setState({auto_leave_data: autoLeave})
            })
        } else {
            this.setState({
                selected_auto_leave: this.state.auto_leave_data[auto_leave_id],
                edit_auto_leave: true,
                change_in_auto_leave: false
            })
        }
    }

    setSelectedAutoLeave = (key, value) => {
        let autoLeave = {...this.state.selected_auto_leave};
        autoLeave[key] = value;
        this.setState({selected_auto_leave: autoLeave, change_in_auto_leave: true})
    }

    saveSelAutoLeave = () => {
        if (this.state.selected_auto_leave["percentage"] == 0){
            return Swal.fire({
                title: "Message",
                html: "Percentage Can't be 0",
                icon: "warning",
            })
        } else if ((this.state.selected_auto_leave["minutes"] < 0) || (this.state.selected_auto_leave["hours"] < 0) || (this.state.selected_auto_leave["days"] < 0) || (this.state.selected_auto_leave["percentage"] < 0)){
            return Swal.fire({
                title: "Message",
                html: "Negative Values are not allowed",
                icon: "warning",
            })
        } else if ((this.state.selected_auto_leave["minutes"] + this.state.selected_auto_leave["hours"] + this.state.selected_auto_leave["days"]) == 0){
            return Swal.fire({
                title: "Message",
                html: "All the time fields can't allowed to be 0",
                icon: "warning",
            })
        } else if (this.state.selected_auto_leave["auto_leave_id"] == 0){
            return Swal.fire({
                title: "Reload the Page",
                html: "Please Reload the Page Something Went Wrong",
                icon: "Error",
            })
        }

        Swal.fire({
            title: `${(this.state.selected_auto_leave["auto_leave_id"] == -1)?"Create":"Update"} Auto Leave Criteria`,
            html: `Are you sure to ${(this.state.selected_auto_leave["auto_leave_id"] == -1)?"Create":"Update"} the Auto Leave Criteria. This Changes will refelect only in the requests made hereafter.`,
            showDenyButton: true,
            confirmButtonText: `Yes, Save`,
            denyButtonText: `No, Cancel`,
            icon: "info",
        }).then((result) => {
            if (result.isConfirmed){
                this.setState({auto_leave_spinner: true})
                this.make_call((this.state.selected_auto_leave["auto_leave_id"] == -1)?"create_auto_leave":"update_auto_leave", this.state.selected_auto_leave, true).then((response) => {
                    if (response.data["status"] != 404){
                        let autoLeave = {...this.state.auto_leave_data};
                        autoLeave[response.data["data"]["auto_leave_id"]] = response.data["data"];
                        this.setState({auto_leave_data: autoLeave, auto_leave_spinner: false, edit_auto_leave: false})
                    }
                    this.setAutoLeave(0)
                    Swal.fire({
                        title: "Message",
                        html: response.data["message"],
                        icon: "info",
                    })
                })
            }
        })
    }

    clearNewAutoLeave = () => {
        let autoLeave = {...this.state.auto_leave_data}
        if (autoLeave[-1] != null) {
            delete autoLeave[-1]
            this.setState({auto_leave_data: autoLeave})
        }
    }

    deleteAutoLeave = (auto_leave_id) => {
        Swal.fire({
            title: `Delete Warning...⚠️`,
            html: "Are you sure to Delete this Auto Leave Criteria. This Changes will refelect only in the requests made hereafter. This process is not reversible.!",
            showDenyButton: true,
            confirmButtonText: `No, Cancel`,
            denyButtonText: `Yes, Delete ⚠️`,
            icon: "warning",
        }).then((result) => {
            if (result.isDenied) {
                this.setState({auto_leave_spinner: true})
                this.make_call("delete_auto_leave", {auto_leave_id: auto_leave_id}, true).then((response) => {
                    if (response.data["status"] != 404){
                        let autoLeave = {...this.state.auto_leave_data};
                        if (autoLeave[auto_leave_id]){
                            delete autoLeave[auto_leave_id]
                        }
                        this.setState({auto_leave_data: autoLeave, auto_leave_spinner: false})
                    }
                    this.setAutoLeave(0)
                    Swal.fire({
                        title: "Message",
                        html: response.data["message"],
                        icon: "info",
                    })
                })
            }
        })
    }

    get_time_string = (minutes=0, hours=0, days=0) => {
        console.log(typeof(minutes), typeof(hours), typeof(days))
        let text = ''
        if (days){
            text += days + ` Day${(days>1)?'s':''}`
        }
        if (text != ''){
            if (hours && minutes){
                text += ', '
            } else if (hours || minutes){
                text += ' and '
            }
        }
        if (hours){
            text += hours + ` Hour${(hours>1)?'s':''}`
        }
        if ((text != '') && (minutes)){
            text += ' and '
        }
        if (minutes){
            text += minutes + ` Minute${(minutes>1)?'s':''}`
        }

        return text
    }

    run_scheduled_func = () => {
        Swal.fire({
            title: `Check Scheduled Request !`,
            html: `Are you want to check and start any scheduled request which is yet to be start`,
            showDenyButton: true,
            confirmButtonText: `Yes, Sure`,
            denyButtonText: `No, Cancel`,
            icon: "info",
        }).then((result) => {
            if (result.isConfirmed){
                this.setState({run_scheduled_spinner: true})
                this.make_call("check_scheduled_request", {}, true).then((response) => {
                    this.setState({run_scheduled_spinner: false})
                    Swal.fire({
                        title: "Message",
                        html: response.data["message"],
                        icon: "info",
                    })
                })
            }
        })
        
    }

    schedule_all_running_func = () => {
        Swal.fire({
            title: `Confirm Schedule All Running !`,
            html: `Are you sure to schedule all running requests for ${this.get_time_string(this.state.req_cont_minutes, this.state.req_cont_hours, this.state.req_cont_days)}`,
            showDenyButton: true,
            confirmButtonText: `Yes, Sure`,
            denyButtonText: `No, Cancel`,
            icon: "info",
        }).then((result) => {
            if (result.isConfirmed){
                this.setState({schedule_running_spinner: true})
                this.make_call("schedule_all_running", {
                    days: this.state.req_cont_days,
                    hours: this.state.req_cont_hours,
                    minutes: this.state.req_cont_minutes,
                }, true).then((response) => {
                    this.setState({schedule_running_spinner: false})
                    Swal.fire({
                        title: "Message",
                        html: response.data["message"],
                        icon: "info",
                    })
                })
            }
        })
    }
    
    reschedule_scheduled_func = () => {
        Swal.fire({
            title: `Confirm Schedule All Running !`,
            html: `Are you sure to schedule all running requests for ${this.get_time_string(this.state.req_cont_minutes, this.state.req_cont_hours, this.state.req_cont_days)}
            ${this.state.req_cont_down_time?"<br/> ⚠️ Since The <b>Down Time</b> is enabled, the schedule requests time can become lower than the already scheduled value":""}
            ${this.state.req_cont_auto_leave?"<br/> ⚠️ Since The <b>Auto Leave</b> is enabled, the Auto Leave Process also include in this Process of Re-scheduling":""}`,
            showDenyButton: true,
            confirmButtonText: `Yes, Sure`,
            denyButtonText: `No, Cancel`,
            icon: "info",
        }).then((result) => {
            if (result.isConfirmed){
                this.setState({reschedule_all_spinner: true})
                this.make_call("re_schedule_again", {
                    days: this.state.req_cont_days,
                    hours: this.state.req_cont_hours,
                    minutes: this.state.req_cont_minutes,
                    down_time: this.state.req_cont_down_time,
                    auto_leave: this.state.req_cont_auto_leave
                }, true).then((response) => {
                    this.setState({reschedule_all_spinner: false})
                    Swal.fire({
                        title: "Message",
                        html: response.data["message"],
                        icon: "info",
                    })
                })
            }
        })
    }

    getJALUsers = () => {
        this.setState({user_control_spinner: true})
        this.make_call("get_user", {}, true).then((response) => {
            this.setState({jal_users: response.data, user_control_spinner: false})
        })
    }

    openAPIKey = (key) => {
        Swal.fire({
            title: `API KEY !`,
            html: `API Key: ${this.state.jal_users[key]["api_key"]}`,
            confirmButtonText: `Copy Key`,
            icon: "info",
        }).then((result) => {
            if (result.isConfirmed){
                let api_key = this.state.jal_users[key]["api_key"]
                navigator.clipboard.writeText(api_key);
                this.setState({copy_btn_msg: "API KEY COPIED !"})
                Swal.fire({
                    title: `API KEY Copied !`,
                    html: `API Key is Copied to your Clipboard`,
                    icon: "warning",
                })
            }
        })
    }

    toggleUserActive = (jal_user_id) => {
        Swal.fire({
            title: `Make User ${this.state.jal_users[jal_user_id]["active"]?"Inactive":"Active"} ⚠️`,
            html: `Are you sure to Make the User ${this.state.jal_users[jal_user_id]["active"]?"Inactive":"Active"} ⚠️`,
            showDenyButton: true,
            confirmButtonText: `Yes, Sure`,
            denyButtonText: `No, Cancel`,
            icon: "warning",
        }).then((result) => {
            if (result.isConfirmed){
                this.setState({user_control_spinner: true})
                this.make_call("change_active", {jal_user_id: jal_user_id}, true).then((response) => {
                    if (response.data["status"] != 404){
                        let jal_users = {...this.state.jal_users};
                        jal_users[jal_user_id] = response.data["data"]
                        this.setState({jal_users: jal_users, user_control_spinner: false})
                    }
                    Swal.fire({
                        title: "Message",
                        html: response.data["message"],
                        icon: "info",
                    })
                })
            }
        })
    }

    toggleAccess = (jal_user_id, action) => {
        Swal.fire({
            title: `Make User You ${this.state.jal_users[jal_user_id][`${action}_access`]?"Disable":"Enable"} ${action} Access for ${this.state.jal_users[jal_user_id]['username']} ⚠️`,
            html: `Are you sure to Make the User ${this.state.jal_users[jal_user_id][`${action}_access`]?"Disable":"Enable"} Access for ${action} ?`,
            showDenyButton: true,
            confirmButtonText: `Yes, Sure`,
            denyButtonText: `No, Cancel`,
            icon: "warning",
        }).then((result) => {
            if (result.isConfirmed){
                this.setState({user_control_spinner: true})
                this.make_call("change_access", {jal_user_id: jal_user_id, action: action}, true).then((response) => {
                    if (response.data["status"] != 404){
                        let jal_users = {...this.state.jal_users};
                        jal_users[jal_user_id] = response.data["data"]
                        this.setState({jal_users: jal_users, user_control_spinner: false})
                    }
                    Swal.fire({
                        title: "Message",
                        html: response.data["message"],
                        icon: "info",
                    })
                })
            }
        })
    }

    addBalanceAmount = () => {
        Swal.fire({
            title: "Make Sure Amount...",
            html: `Please Make Sure the Amount, ${this.state.add_balance_amnt}$ to the user ${this.state.jal_users[this.state.open_enter_balance]["username"]}
            <br/><b>⚠️ Once the Amount is added it can't reversed<b>`,
            showDenyButton: true,
            confirmButtonText: `Confirm, Add ${this.state.add_balance_amnt}$ ⚠️`,
            denyButtonText: "Cancel",
            icon: "warning",
        }).then((result) => {
            if (result.isConfirmed){
                this.setState({user_control_spinner: true})
                this.make_call("add_balance", {jal_user_id: this.state.open_enter_balance, amount: this.state.add_balance_amnt}, true).then((response) => {
                    if (response.data["status"] != 404){
                        let jal_users = {...this.state.jal_users};
                        jal_users[this.state.open_enter_balance] = response.data["data"]
                        this.setState({jal_users: jal_users, user_control_spinner: false, add_balance_amnt: 0, open_enter_balance: 0})
                    }
                    Swal.fire({
                        title: "Message",
                        html: response.data["message"],
                        icon: "info",
                    })
                })
            }
        })
    }

    getUserTransaction = (jal_user_id) => {
        this.setState({user_transaction_spinner: true})
        this.make_call("get_transaction", {jal_user_id: jal_user_id}, true).then((response) => {
            this.setState({transaction_data: response.data, user_transaction_spinner: false, transaction_user: jal_user_id})
        })
    }


    getUsersCategories = (jal_user_id) => {
        this.setState({user_control_spinner: true, user_categories_data: {}})
        this.make_call("get_user_cats", {jal_user_id: jal_user_id}, true).then((response) => {
            if (response.data["status"] != 404){
                this.setState({user_categories_data: response.data, user_categories_data_changed: false, user_control_spinner: false, user_categories_user_id: jal_user_id})
            }
            else{
                Swal.fire({
                    title: "Message",
                    html: response.data["message"],
                    icon: "info",
                })
            }
        })
    }


    saveUserCategories = (jal_user_id) => {
        Swal.fire({
            title: "Save Category Changes...",
            html: `Please Make Sure the Changes, changes will be applied in the request made after save this changes⚠️`,
            showDenyButton: true,
            confirmButtonText: `Confirm, Save`,
            denyButtonText: "Cancel",
            icon: "warning",
        }).then((result) => {
            if (result.isConfirmed){
                this.setState({user_control_spinner: true})
                this.make_call("update_user_cats", {jal_user_id: jal_user_id, user_categories: this.state.user_categories_data}, true).then((response) => {
                    if (response.data["status"] != 404){
                        this.setState({user_categories_data: response.data["data"], user_categories_data_changed: false, user_control_spinner: false, user_categories_user_id: jal_user_id})
                    }
                    Swal.fire({
                        title: "Message",
                        html: response.data["message"],
                        icon: "info",
                    })
                })
            }
        })
    }


    componentDidMount = () => {
        // this.getChannels()
        // this.getCategories()
        this.setCategory(0)
        this.setAutoLeave(0)

        // this.setState({category_spinner: false})
    }

    componentWillUnmount() {
        // clearInterval(this.input_interval);
    }

    checkBoolVal(cond) {
        if (cond == 'true'){
            return true
        } else if (cond == 'false') {
            return false
        } else {
            return undefined
        }

    }

    notificationAlert = React.createRef();

    render() {
        return (<>
            <div className="content">
                {!(this.state.open_url_updater || this.state.open_category || this.state.open_admin_req_cont || this.state.open_user_control || this.state.open_minimal_left_group)?<>
                    <Row>
                        <Col className="m-auto text-center" lg="10" md="10" sm="10">
                            <p className="h1 my-3">Click Below to Open the Respective Panel</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="3" md="3" sm="3" className="mx-auto my-3"><button className="btn btn-lg col-sm-12 col-lg-12 col-12 bg-blue" onClick={() => {(this.state.channels.length != 0)?console.log("Channels already There...!"):this.getChannels(); this.setState({open_url_updater: !this.state.open_url_updater})}}>{this.state.open_url_updater?"Close":"Open"} URL Updater Panel</button></Col>
                    </Row>
                    <Row>
                        <Col lg="3" md="3" sm="3" className="mx-auto my-3"><button className="btn btn-lg col-sm-12 col-lg-12 col-12 bg-blue" onClick={() => {(Object.keys(this.state.categories).length != 0)?console.log("Categories already There...!"):this.getCategories(); this.setState({open_category: !this.state.open_category})}}>{this.state.open_category?"Close":"Open"} Category Panel</button></Col>
                    </Row>
                    <Row>
                        <Col lg="3" md="3" sm="3" className="mx-auto my-3"><button className="btn btn-lg col-sm-12 col-lg-12 col-12 bg-blue" onClick={() => {this.setState({open_admin_req_cont: !this.state.open_admin_req_cont})}}>{this.state.open_admin_req_cont?"Close":"Open"} Requests Control Panel</button></Col>
                    </Row>
                    <Row>
                        <Col lg="3" md="3" sm="3" className="mx-auto my-3"><button className="btn btn-lg col-sm-12 col-lg-12 col-12 bg-blue" onClick={() => {(Object.keys(this.state.jal_users).length != 0)?console.log("JAL Users already There...!"):this.getJALUsers(); this.setState({open_user_control: !this.state.open_user_control})}}>{this.state.open_user_control?"Close":"Open"} User's Control Panel</button></Col>
                    </Row>
                    <Row>
                        <Col lg="3" md="3" sm="3" className="mx-auto my-3"><button className="btn btn-lg col-sm-12 col-lg-12 col-12 bg-blue" onClick={() => {(this.state.minimal_left_group.length != 0)?console.log("Minimal Left Group Data is already There...!"):this.get_minimal_left_groups(); this.setState({open_minimal_left_group: !this.state.open_minimal_left_group})}}>{this.state.open_minimal_left_group?"Close":"Open"} Minimal Left Groups</button></Col>
                    </Row>
                </>:<></>}
                {this.state.open_url_updater?<>
                    <Row>
                        <Col className="mx-auto" lg="12" md="12" sm="12">
                            <Card className="card-stats border-success">
                                {this.state.url_update_spinner?<div id="loader"></div>:<></>}
                                <CardHeader>
                                    <CardTitle>
                                        <Row className="mb-3">
                                            <Col className="m-auto text-center" lg="10" md="10" sm="10">
                                                <p className="h3 my-auto">URL Updater</p>
                                            </Col>
                                            <Col className="m-auto" lg="1" md="1" sm="1">
                                                <button onClick={() => this.setState({open_url_updater: !this.state.open_url_updater})} className="btn btn-outline-red float-right">❌</button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="10" md="10" sm="10" className="mx-auto">
                                                <label className="my-2">Select the old Link to Update:</label> {this.state.loading_urls?<i className='fa fa-refresh icon-spin'></i>:''}
                                                <Select className='black my-2' disabled={this.state.url_update_spinner} onChange={(value) => {this.setState({old_target_url: value["value"]})}} name='old_target_url' id='old_target_url' options={this.state.channels} closeMenuOnSelect={true}/>

                                                <label className="labelfont">Insert the New Link to Update:</label>
                                                <input type="url" disabled={this.state.url_update_spinner} className='form-control square col-sm-12 col-lg-12 col-12 my-2 m-1' readOnly={this.state.url_update_spinner} value={this.state.new_target_url} onChange={(e) => { this.setState({ new_target_url: e.target.value }) }} placeholder="Insert the New Link to Update" />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="8" md="8" sm="8"></Col>
                                            <Col lg="3" md="3" sm="3">
                                                <button onClick={() => this.getChannels()} disabled={this.state.url_update_spinner || this.state.channel_lists_loading} className="btn btn-outline-blue float-right"><i className={`fa fa-refresh ${this.state.channel_lists_loading?'icon-spin':''} `}></i> Reload Channels </button>
                                                <button onClick={() => this.update_target_url()} disabled={this.state.url_update_spinner || this.state.updating_link} className="btn btn-outline-green float-left"><i className={`fa fa-refresh ${this.state.updating_link?'icon-spin':''} `}></i> Update URL </button>
                                            </Col>
                                        </Row>
                                    </CardTitle>
                                </CardHeader>
                                <CardBody className="p-3 my-0" style={{display: 'block', maxWidth: '100%', width: '100%', height: 'auto'}}>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </>:<></>}

                {this.state.open_category?<>
                    <Row>
                        <Col lg="12" md="12" sm="12" className="mx-auto">
                            <Card className="card-stats border-success">
                                {this.state.category_spinner?<div id="loader"></div>:<></>}
                                <CardHeader>
                                    <CardTitle>
                                        <Row>
                                            <Col className="m-auto text-center" lg="10" md="10" sm="10">
                                                <p className="h3 my-auto">{this.state.edit_category?"Update":"View"} Category</p>
                                            </Col>
                                            <Col className="m-auto" lg="1" md="1" sm="1">
                                                <button onClick={() => this.setState({open_category: !this.state.open_category})} className="btn btn-outline-red float-right">❌</button>
                                            </Col>
                                        </Row>
                                    </CardTitle>
                                </CardHeader>
                                
                                <CardBody className="mx-3 my-3 col-10 mx-auto">
                                    <Row className="mx-auto">
                                        <Col className="m-auto" lg="8" md="8" sm="8">
                                            <select className='form-control col-sm-12 col-lg-12 col-12 my-2 m-1' onChange={(e) => {this.setCategory(parseInt(e.target.value))}} disabled={(this.state.edit_category) || (!this.state.categories) || (this.state.open_auto_leave)}>
                                                {(this.state.selected_cat["category_id"] == 0)?<option value={0} >Select Category</option>:''}
                                                {Object.keys(this.state.categories).map( key => <option value={this.state.categories[key]["category_id"]} >{this.state.categories[key]["category"]}</option>)}
                                            </select>
                                        </Col>
                                        <Col className="m-auto" lg="2" md="2" sm="2">
                                            <button onClick={() => this.getCategories()} disabled={(this.state.category_spinner) || (this.state.edit_category) || (this.state.open_auto_leave)} className="btn btn-outline-green col-sm-12 col-lg-12 col-12 float-right"><i className={`fa fa-refresh ${this.state.category_spinner?'icon-spin':''} `}></i> Reload Categories </button>
                                        </Col>
                                        <Col className="m-auto" lg="2" md="2" sm="2">
                                            <button onClick={() => this.setCategory(-1)} disabled={(this.state.category_spinner) || (this.state.edit_category) || (this.state.open_auto_leave)} className="btn btn-outline-blue col-sm-12 col-lg-12 col-12 float-right"> Add New </button>
                                        </Col>
                                    </Row>
                                    <Row className="ml-3 my-3">
                                        <Col lg="4" md="4" sm="4" className="h4 mt-0">Category ID<span className="float-right">:</span></Col>
                                        <Col lg="8" md="8" sm="8" className="h4 mt-0"><p className="m-1">{(this.state.selected_cat["category_id"] != -1)?this.state.selected_cat["category_id"]:'<ID will Generate Automatically>'}</p></Col>
                                    </Row>
                                    <Row className="ml-3 my-3">
                                        <Col lg="4" md="4" sm="4" className="h4 mt-0">Category<span className="float-right">:</span></Col>
                                        <Col lg="8" md="8" sm="8" className="h4 mt-0">
                                            {this.state.edit_category?
                                                <input type="text" disabled={this.state.category_spinner} className='form-control square col-sm-10 col-lg-10 col-10 m-1' onChange={(e) => {this.setSelectedCat("category", e.target.value)}} value={this.state.selected_cat["category"]} placeholder={(this.state.selected_cat["category_id"] == -1)?'Please Give a New Unique Name of Category':this.state.selected_cat["category"]} />
                                                :
                                                <p className="m-1">{this.state.selected_cat["category"]}</p>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="ml-3 my-3">
                                        <Col lg="4" md="4" sm="4" className="h4 mt-0">Service Name<span className="float-right">:</span></Col>
                                        <Col lg="8" md="8" sm="8" className="h4 mt-0">
                                            {this.state.edit_category?
                                                <input type="text" disabled={this.state.category_spinner} className='form-control square col-sm-10 col-lg-10 col-10 m-1' onChange={(e) => {this.setSelectedCat("service_name", e.target.value)}} value={this.state.selected_cat["service_name"]} placeholder={(this.state.selected_cat["category_id"] == -1)?'Please Give the Service Name of the Category':this.state.selected_cat["service_name"]} />
                                                :
                                                <p className="m-1">{this.state.selected_cat["service_name"]?this.state.selected_cat["service_name"]:"-"}</p>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="ml-3 my-3">
                                        <Col lg="4" md="4" sm="4" className="h4 mt-0">Description<span className="float-right">:</span></Col>
                                        <Col lg="8" md="8" sm="8" className="h4 mt-0">
                                            {this.state.edit_category?
                                                <textarea disabled={this.state.category_spinner} className='form-control square col-sm-10 col-lg-10 col-10 m-1 p-2' style={{fontSize: "16px"}} onChange={(e) => {this.setSelectedCat("description", e.target.value)}} value={this.state.selected_cat["description"]} placeholder={(this.state.selected_cat["category_id"] == -1)?'Please Give the Description of the Category':this.state.selected_cat["description"]} />
                                                :
                                                <p className="m-1">{this.state.selected_cat["description"]?this.state.selected_cat["description"]:"-"}</p>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="ml-3 my-3">
                                        <Col lg="4" md="4" sm="4" className="h4 mt-0">Price USD / Count<span className="float-right">:</span></Col>
                                        <Col lg="8" md="8" sm="8" className="h4 mt-0">
                                            {this.state.edit_category?
                                                <Row className="col-6 mr-auto">
                                                    <Col lg="5" md="5" sm="5" className="p-0">
                                                        <input type="number" disabled={this.state.category_spinner} className='form-control square col-sm-12 col-lg-12 col-12 m-1 p-2' onChange={(e) => {(e.target.value<0)?this.setSelectedCat("price", 0):this.setSelectedCat("price", parseInt(e.target.value))}} value={this.state.selected_cat["price"]} placeholder={this.state.selected_cat["price"]} />
                                                    </Col>
                                                    <Col lg="1" md="1" sm="1" className="mx-auto text-left my-auto">$</Col>
                                                    <Col lg="1" md="1" sm="1" className="mx-auto text-center my-auto">/</Col>
                                                    <Col lg="5" md="5" sm="5" className="p-0">
                                                        <input type="number" disabled={this.state.category_spinner} className='form-control square col-sm-12 col-lg-12 col-12 m-1 p-2' onChange={(e) => {(e.target.value<0)?this.setSelectedCat("count", 0):this.setSelectedCat("count", parseInt(e.target.value))}} value={this.state.selected_cat["count"]} placeholder={this.state.selected_cat["count"]} />
                                                    </Col>
                                                </Row>
                                                :
                                                <p className="m-1">{this.state.selected_cat["price"]} $ / {this.state.selected_cat["count"]}</p>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="ml-3 my-3">
                                        <Col lg="4" md="4" sm="4" className="h4 mt-0">Number of Process<span className="float-right">:</span></Col>
                                        <Col lg="8" md="8" sm="8" className="h4 mt-0">
                                            {this.state.edit_category?
                                                <input type="number" disabled={this.state.category_spinner} className='form-control square col-sm-10 col-lg-10 col-10 m-1' onChange={(e) => {(e.target.value<0)?this.setSelectedCat("no_of_process", 0):this.setSelectedCat("no_of_process", parseInt(e.target.value))}} value={this.state.selected_cat["no_of_process"]} placeholder={this.state.selected_cat["no_of_process"]} />
                                                :
                                                <p className="m-1">{this.state.selected_cat["no_of_process"]}</p>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="ml-3 my-3">
                                        <Col lg="4" md="4" sm="4" className="h4 mt-0">Active Status<span className="float-right">:</span></Col>
                                        <Col lg="8" md="8" sm="8" className="h4 mt-0">
                                            {(this.state.edit_category && !this.state.category_spinner)?
                                                <span onClick={() => {this.setSelectedCat("active", !this.state.selected_cat["active"])}} style={{cursor: "pointer"}}>{this.state.selected_cat["active"]?"🟢":"⭕️"} (Click to {this.state.selected_cat["active"]?"Inactive":"Active"})</span>
                                                :
                                                <p className="m-1">{this.state.selected_cat["active"]?"🟢":"⭕️"}</p>
                                            }
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <Row>
                                        <Col lg="10" md="10" sm="10" className="mx-auto my-4">
                                            {this.state.edit_category?
                                            <>
                                                <Row>
                                                    <Col lg="4" md="4" sm="4" className="mx-auto"><button disabled={(!this.state.change_in_sel_cat)} className="btn btn-lg col-sm-12 col-lg-12 col-12 bg-green" onClick={() => {this.saveCategoryChanges()}}>Save</button></Col>
                                                    <Col lg="4" md="4" sm="4" className="mx-auto"><button className="btn btn-lg col-sm-12 col-lg-12 col-12 bg-red" onClick={() => {(this.state.selected_cat["category_id"] == -1)?this.setCategory(0):this.setCategory(this.state.selected_cat["category_id"])}}>Cancel</button></Col>
                                                </Row>
                                            </>
                                            :
                                            <>
                                                <Row>
                                                    <Col lg="3" md="3" sm="3" className="mx-auto"><button disabled={(this.state.selected_cat["category_id"] == 0) || (this.state.open_auto_leave)} className="btn btn-lg col-sm-12 col-lg-12 col-12 bg-blue" onClick={() => {this.setState({edit_category: !this.state.edit_category})}}>Update</button></Col>
                                                    <Col lg="3" md="3" sm="3" className="mx-auto"><button disabled={(this.state.selected_cat["category_id"] == 0) || (this.state.open_auto_leave)} className="btn btn-lg col-sm-12 col-lg-12 col-12 bg-red" onClick={() => {this.deleteCategory(this.state.selected_cat["category_id"])}}>Delete ⚠️</button></Col>
                                                    <Col lg="3" md="3" sm="3" className="mx-auto"><button disabled={(this.state.selected_cat["category_id"] == 0) || (this.state.open_auto_leave)} className="btn btn-lg col-sm-12 col-lg-12 col-12 bg-green" onClick={() => {this.setState({open_auto_leave: !this.state.open_auto_leave}); this.getAutoLeaveCharts()}}>Open Auto Leave Chart</button></Col>
                                                </Row>
                                            </>
                                            }
                                        </Col>
                                    </Row>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>

                    {this.state.open_auto_leave?<>
                        <Row>
                            <Col className="mx-auto" lg="12" md="12" sm="12">
                                <Card className="card-stats border-success">
                                    {this.state.auto_leave_spinner?<div id="loader"></div>:<></>}
                                    <CardHeader>
                                        <CardTitle>
                                            <Row className="mb-3">
                                                <Col className="m-auto text-center" lg="7" md="7" sm="7">
                                                    <p className="h3 my-auto p-3">Auto Leave Chart in {this.state.selected_cat["category"]} Category</p>
                                                </Col>
                                                <Col className="m-auto" lg="2" md="2" sm="2">
                                                    <button onClick={() => this.getAutoLeaveCharts(true)} disabled={(this.state.auto_leave_spinner) || (this.state.edit_auto_leave)} className="btn btn-outline-green col-sm-12 col-lg-12 col-12 float-left"><i className={`fa fa-refresh ${this.state.auto_leave_spinner?'icon-spin':''} `}></i> Reload </button>
                                                </Col>
                                                <Col className="m-auto" lg="2" md="2" sm="2">
                                                    <button onClick={() => this.setAutoLeave(-1)} disabled={(this.state.auto_leave_spinner) || (this.state.edit_auto_leave)} className="btn btn-outline-blue col-sm-12 col-lg-12 col-12 float-left"> Add New </button>
                                                </Col>
                                                <Col className="m-auto" lg="1" md="1" sm="1">
                                                    <button onClick={() => this.setState({open_auto_leave: !this.state.open_auto_leave})} className="btn btn-outline-red float-right">❌</button>
                                                </Col>
                                            </Row>
                                            <Row>
                                                {Object.keys(this.state.auto_leave_data).map(key => <>
                                                    <Col className="mx-auto" lg="4" md="6" sm="12">
                                                        <Card className="card-stats border-success">
                                                            <CardBody>
                                                                {(this.state.auto_leave_data[key]["auto_leave_id"] != -1)?
                                                                <>
                                                                    <Row>
                                                                        <Col lg="5" md="5" sm="5" className="h5 mt-0 ml-auto">ID<span className="float-right">:</span></Col>
                                                                        <Col lg="5" md="5" sm="5" className="h5 mt-0"><p className="m-1">{this.state.auto_leave_data[key]["auto_leave_id"]}</p></Col>
                                                                    </Row>
                                                                </>
                                                                :
                                                                <></>
                                                                }
                                                                <Row>
                                                                    <Col lg="5" md="5" sm="5" className="h5 mt-0 ml-auto">Minutes<span className="float-right">:</span></Col>
                                                                    <Col lg="5" md="5" sm="5" className="h5 mt-0">
                                                                        {((this.state.edit_auto_leave) && (this.state.selected_auto_leave["auto_leave_id"] == key))?
                                                                            <input type="number" disabled={this.state.auto_leave_spinner} className='form-control square col-sm-10 col-lg-10 col-10 m-1' onChange={(e) => {(e.target.value<0)?this.setSelectedAutoLeave("minutes", 0):this.setSelectedAutoLeave("minutes", parseInt(e.target.value))}} value={this.state.selected_auto_leave["minutes"]} placeholder={this.state.selected_auto_leave["minutes"]} />
                                                                            :
                                                                            <p className="m-1">{this.state.auto_leave_data[key]["minutes"]}</p>
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col lg="5" md="5" sm="5" className="h5 mt-0 ml-auto">Hours<span className="float-right">:</span></Col>
                                                                    <Col lg="5" md="5" sm="5" className="h5 mt-0">
                                                                        {((this.state.edit_auto_leave) && (this.state.selected_auto_leave["auto_leave_id"] == key))?
                                                                            <input type="number" disabled={this.state.auto_leave_spinner} className='form-control square col-sm-10 col-lg-10 col-10 m-1' onChange={(e) => {(e.target.value<0)?this.setSelectedAutoLeave("hours", 0):this.setSelectedAutoLeave("hours", parseInt(e.target.value))}} value={this.state.selected_auto_leave["hours"]} placeholder={this.state.selected_auto_leave["hours"]} />
                                                                            :
                                                                            <p className="m-1">{this.state.auto_leave_data[key]["hours"]}</p>
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col lg="5" md="5" sm="5" className="h5 mt-0 ml-auto">Days<span className="float-right">:</span></Col>
                                                                    <Col lg="5" md="5" sm="5" className="h5 mt-0">
                                                                        {((this.state.edit_auto_leave) && (this.state.selected_auto_leave["auto_leave_id"] == key))?
                                                                            <input type="number" disabled={this.state.auto_leave_spinner} className='form-control square col-sm-10 col-lg-10 col-10 m-1' onChange={(e) => {(e.target.value<0)?this.setSelectedAutoLeave("days", 0):this.setSelectedAutoLeave("days", parseInt(e.target.value))}} value={this.state.selected_auto_leave["days"]} placeholder={this.state.selected_auto_leave["days"]} />
                                                                            :
                                                                            <p className="m-1">{this.state.auto_leave_data[key]["days"]}</p>
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col lg="5" md="5" sm="5" className="h5 mt-0 ml-auto">Percentage<span className="float-right">:</span></Col>
                                                                    <Col lg="5" md="5" sm="5" className="h5 mt-0">
                                                                        {((this.state.edit_auto_leave) && (this.state.selected_auto_leave["auto_leave_id"] == key))?
                                                                        <>
                                                                            <Row className="col-12">
                                                                                <input type="number" disabled={this.state.auto_leave_spinner} className='form-control col-sm-8 col-lg-8 col-8 square m-1' onChange={(e) => {(e.target.value<0)?this.setSelectedAutoLeave("percentage", 0):this.setSelectedAutoLeave("percentage", parseInt(e.target.value))}} value={this.state.selected_auto_leave["percentage"]} placeholder={this.state.selected_auto_leave["percentage"]} />
                                                                                <span className="mx-auto">%</span>
                                                                            </Row>
                                                                        </>
                                                                            :
                                                                            <p className="m-1">{this.state.auto_leave_data[key]["percentage"]}<span className="ml-2">%</span></p>
                                                                        } 
                                                                    </Col>
                                                                </Row>
                                                                {((this.state.edit_auto_leave) && (this.state.selected_auto_leave["auto_leave_id"] == key))?
                                                                <>
                                                                    <Row>
                                                                        <Col lg="6" md="12" sm="12" className="mx-auto"><button disabled={!this.state.change_in_auto_leave} className="btn btn-lg col-sm-12 col-lg-12 col-12 bg-green" onClick={() => {this.saveSelAutoLeave()}}>Save</button></Col>
                                                                        <Col lg="6" md="12" sm="12" className="mx-auto"><button className="btn btn-lg col-sm-12 col-lg-12 col-12 bg-red" onClick={() => {this.setAutoLeave(0)}}>Cancel</button></Col>
                                                                    </Row>
                                                                </>
                                                                :
                                                                <>
                                                                    <Row>
                                                                        <Col lg="6" md="12" sm="12" className="mx-auto"><button disabled={this.state.edit_auto_leave} className="btn btn-lg col-sm-12 col-lg-12 col-12 bg-blue" onClick={() => {this.setAutoLeave(key)}}>Update</button></Col>
                                                                        <Col lg="6" md="12" sm="12" className="mx-auto"><button disabled={this.state.edit_auto_leave} className="btn btn-lg col-sm-12 col-lg-12 col-12 bg-red" onClick={() => {this.deleteAutoLeave(key)}}>Delete</button></Col>
                                                                    </Row>
                                                                </>
                                                                }
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </>)}
                                            </Row>
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody className="p-3 my-0" style={{display: 'block', maxWidth: '100%', width: '100%', height: 'auto'}}>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </>:<></>}
                </>:<></>}

                {this.state.open_admin_req_cont?<>
                    <Row>
                        <Col className="mx-auto" lg="12" md="12" sm="12">
                            <Card className="card-stats border-success">
                                {this.state.request_control_spinner?<div id="loader"></div>:<></>}
                                <CardHeader>
                                    <CardTitle>
                                        <Row className="mb-3">
                                            <Col className="m-auto text-center" lg="10" md="10" sm="10">
                                                <p className="h3 my-auto">Admin Request Controls</p>
                                            </Col>
                                            <Col className="m-auto" lg="1" md="1" sm="1">
                                                <button onClick={() => this.setState({open_admin_req_cont: !this.state.open_admin_req_cont})} className="btn btn-outline-red float-right">❌</button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Row className="col-12 my-2">
                                                <Col lg="8" md="8" sm="8" className="my-auto text-center ml-auto border border-grey bg-black rounded-lg p-3">
                                                    <p style={{fontSize: '25px'}} className="my-auto">This is to Immediately initialize the Scheduled Requests which are yet to be start<br/> and tagged as <b>"START SOON"</b>.</p>
                                                </Col>
                                                <Col lg="0.5" md="0.5" sm="0.5" className="my-auto ml-3 text-center">
                                                    <p className="h3 my-auto">➡️</p>
                                                </Col>
                                                <Col lg="3" md="3" sm="3" className="my-auto text-left">
                                                    <button disabled={this.state.request_control_spinner || this.state.run_scheduled_spinner || this.state.schedule_running_spinner || this.state.reschedule_all_spinner} className="btn btn-lg col-sm-12 col-lg-12 col-12 bg-green" onClick={this.run_scheduled_func}><i className={`fa fa-refresh ${this.state.run_scheduled_spinner?'icon-spin':''} `}></i> Run Scheduled </button>
                                                </Col>
                                            </Row>
                                            <Row className="col-12 my-2">
                                                <Col lg="8" md="8" sm="8" className="my-auto text-center ml-auto border border-grey bg-black rounded-lg p-3">
                                                    <p style={{fontSize: '25px'}} className="my-auto">This is to Schedule all the Running Request for the given time<br/> Use Below Schedule Timer for Inputs.</p>
                                                </Col>
                                                <Col lg="0.5" md="0.5" sm="0.5" className="my-auto ml-3 text-center">
                                                    <p className="h3 my-auto">➡️</p>
                                                </Col>
                                                <Col lg="3" md="3" sm="3" className="my-auto text-left">
                                                    <button disabled={this.state.request_control_spinner || this.state.run_scheduled_spinner || this.state.schedule_running_spinner || this.state.reschedule_all_spinner || ((this.state.req_cont_minutes<=0) && (this.state.req_cont_hours<=0) && (this.state.req_cont_days<=0))} className="btn btn-lg col-sm-12 col-lg-12 col-12 bg-green" onClick={() => {this.schedule_all_running_func()}}><i className={`fa fa-refresh ${this.state.schedule_running_spinner?'icon-spin':''} `}></i> Schedule All Running </button>
                                                </Col>
                                            </Row>
                                            <Row className="col-12 my-2">
                                                <Col lg="8" md="8" sm="8" className="my-auto text-center ml-auto border border-grey bg-black rounded-lg p-3">
                                                    <p style={{fontSize: '25px'}} className="my-auto">This is to Re-Schedule all the Scheduled Request for the given time<br/> Use Below Schedule Timer for Inputs.</p><br/>
                                                    <Row>
                                                        <Col>
                                                            <p className="my-2">⚠️ Enable <b>"DOWN TIME"</b> will allow you to change the schedule time with lower time then the already scheduled time.</p>
                                                        </Col>
                                                        <Col>
                                                            <p className="my-2">⚠️ Enable <b>"AUTO LEAVE"</b> will include the auto leave proces also in this.</p>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col lg="0.5" md="0.5" sm="0.5" className="my-auto ml-3 text-center">
                                                    <p className="h3 my-auto">➡️</p>
                                                </Col>
                                                <Col lg="3" md="3" sm="3" className="my-auto text-left">
                                                    <button disabled={this.state.request_control_spinner || this.state.run_scheduled_spinner || this.state.schedule_running_spinner || this.state.reschedule_all_spinner || ((this.state.req_cont_minutes<=0) && (this.state.req_cont_hours<=0) && (this.state.req_cont_days<=0))} className="btn btn-lg col-sm-12 col-lg-12 col-12 bg-green" onClick={() => {this.reschedule_scheduled_func()}}><i className={`fa fa-refresh ${this.state.reschedule_all_spinner?'icon-spin':''} `}></i> Reschedule All </button>
                                                </Col>
                                            </Row>
                                            <Row className="col-12">
                                                <Col className="m-auto text-center" lg="10" md="10" sm="10">
                                                    <p className="h3 mt-2">Schedule Timer Inputs</p>
                                                </Col>
                                            </Row>
                                            <Row className="col-6 col-md-9 col-sm-12 mx-auto">
                                                <Col lg="5" md="5" sm="5" className="h5 mt-0 ml-auto">Minutes <span className="float-right">:</span></Col>
                                                <Col lg="5" md="5" sm="5" className="h5 mt-0">
                                                    <input type="number" disabled={this.state.request_control_spinner} className='form-control square col-sm-10 col-lg-10 col-10 m-2' onChange={(e) => {(e.target.value<0)?this.setState({req_cont_minutes: 0}):this.setState({req_cont_minutes: parseInt(e.target.value)})}} value={this.state.req_cont_minutes} placeholder="0" />
                                                </Col>
                                                <Col lg="5" md="5" sm="5" className="h5 mt-0 ml-auto">Hours <span className="float-right">:</span></Col>
                                                <Col lg="5" md="5" sm="5" className="h5 mt-0">
                                                    <input type="number" disabled={this.state.request_control_spinner} className='form-control square col-sm-10 col-lg-10 col-10 m-2' onChange={(e) => {(e.target.value<0)?this.setState({req_cont_hours: 0}):this.setState({req_cont_hours: parseInt(e.target.value)})}} value={this.state.req_cont_hours} placeholder="0" />
                                                </Col>
                                                <Col lg="5" md="5" sm="5" className="h5 mt-0 ml-auto">Days <span className="float-right">:</span></Col>
                                                <Col lg="5" md="5" sm="5" className="h5 mt-0">
                                                    <input type="number" disabled={this.state.request_control_spinner} className='form-control square col-sm-10 col-lg-10 col-10 m-2' onChange={(e) => {(e.target.value<0)?this.setState({req_cont_days: 0}):this.setState({req_cont_days: parseInt(e.target.value)})}} value={this.state.req_cont_days} placeholder="0" />
                                                </Col>
                                                <Col lg="5" md="5" sm="5" className="h5 mt-0 ml-auto my-2">Down Time <span className="float-right">:</span></Col>
                                                <Col lg="5" md="5" sm="5" className="h5 mt-0 my-2">
                                                    <span className="ml-2" onClick={() => {this.setState({req_cont_down_time: !this.state.req_cont_down_time})}} style={{cursor: "pointer"}} title="⚠️ Enable this will allow you to change the schedule time with lower time then the already scheduled time.">{this.state.req_cont_down_time?"🟢":"⭕️"} (Click to {this.state.req_cont_down_time?"Inactive":"Active"})</span>
                                                </Col>
                                                <Col lg="5" md="5" sm="5" className="h5 mt-0 ml-auto my-2">Auto Leave <span className="float-right">:</span></Col>
                                                <Col lg="5" md="5" sm="5" className="h5 mt-0 my-2">
                                                    <span className="ml-2" onClick={() => {this.setState({req_cont_auto_leave: !this.state.req_cont_auto_leave})}} style={{cursor: "pointer"}} title="⚠️ Enable this will include the auto leave proces also in this.">{this.state.req_cont_auto_leave?"🟢":"⭕️"} (Click to {this.state.req_cont_auto_leave?"Inactive":"Active"})</span>
                                                </Col>
                                            </Row>
                                        </Row>
                                    </CardTitle>
                                </CardHeader>
                                <CardBody className="p-3 my-0" style={{display: 'block', maxWidth: '100%', width: '100%', height: 'auto'}}>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </>:<></>}

                {this.state.open_user_control?<>
                    <Row>
                        <Col className="mx-auto" lg="12" md="12" sm="12">
                            <Card className="card-stats border-success">
                                {this.state.user_control_spinner?<div id="loader"></div>:<></>}
                                <CardHeader>
                                    <CardTitle>
                                        <Row className="mb-3">
                                            <Col className="m-auto" lg="2" md="2" sm="2">
                                                <button onClick={() => {this.getJALUsers()}} disabled={this.state.user_control_spinner} className="btn btn-outline-green col-sm-12 col-lg-12 col-12 float-left"><i className={`fa fa-refresh ${this.state.user_control_spinner?'icon-spin':''} `}></i> Reload Users </button>
                                            </Col>
                                            <Col className="m-auto text-center" lg="8" md="8" sm="8">
                                                <p className="h3 my-auto">User's Control Panel</p>
                                            </Col>
                                            <Col className="m-auto" lg="1" md="1" sm="1">
                                                <button onClick={() => this.setState({open_user_control: !this.state.open_user_control})} className="btn btn-outline-red float-right">❌</button>
                                            </Col>
                                        </Row>
                                    </CardTitle>
                                </CardHeader>
                                <CardBody className="p-3 my-0" style={{display: 'block', maxWidth: '100%', width: '100%', height: 'auto'}}>
                                    <Row>
                                        {Object.keys(this.state.jal_users).slice(this.state.page_no_user_panel*this.state.PGLIMIT, (this.state.page_no_user_panel+1)*this.state.PGLIMIT).map( key => <>
                                            <Col lg="4" md="6" sm="12" className="mx-auto">
                                                <Card className="card-stats border-success">
                                                    <CardTitle>
                                                        <Row>
                                                            <Col lg="10" md="10" sm="10" className="mx-auto text-center">
                                                                <p className="h3 my-auto">JAL User ID : {key}</p>
                                                            </Col>
                                                        </Row>
                                                    </CardTitle>
                                                    <CardBody>
                                                        <Row>
                                                            <Col lg="5" md="5" sm="5" className="h4 mt-0 ml-auto">First Name<span className="float-right">:</span></Col>
                                                            <Col lg="5" md="5" sm="5" className="h4 mt-0">
                                                                <p className="m-1 my-auto">{this.state.jal_users[key]["first_name"]?this.state.jal_users[key]["first_name"]:"-"}</p>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="5" md="5" sm="5" className="h4 mt-0 ml-auto">Last Name<span className="float-right">:</span></Col>
                                                            <Col lg="5" md="5" sm="5" className="h4 mt-0">
                                                                <p className="m-1 my-auto">{this.state.jal_users[key]["last_name"]?this.state.jal_users[key]["last_name"]:"-"}</p>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="5" md="5" sm="5" className="h4 mt-0 ml-auto">Username<span className="float-right">:</span></Col>
                                                            <Col lg="5" md="5" sm="5" className="h4 mt-0">
                                                                <p className="m-1 my-auto">{this.state.jal_users[key]["username"]}</p>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="5" md="5" sm="5" className="h4 mt-0 ml-auto">Telebooster ID<span className="float-right">:</span></Col>
                                                            <Col lg="5" md="5" sm="5" className="h4 mt-0">
                                                                <p className="m-1 my-auto">{this.state.jal_users[key]["local_user_id"]}</p>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="5" md="5" sm="5" className="h4 mt-0 ml-auto">Balance<span className="float-right">:</span></Col>
                                                            <Col lg="5" md="5" sm="5" className="h4 mt-0">
                                                                <p className="m-1 my-auto">{this.state.jal_users[key]["balance"]} $</p>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="5" md="5" sm="5" className="h4 mt-0 ml-auto">Active Status<span className="float-right">:</span></Col>
                                                            <Col lg="5" md="5" sm="5" className="h4 mt-0">
                                                                <p className="m-1 my-auto">{this.state.jal_users[key]["active"]?"🟢":"⭕️"}</p>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="5" md="5" sm="5" className="h4 mt-0 ml-auto">Admin Status<span className="float-right">:</span></Col>
                                                            <Col lg="5" md="5" sm="5" className="h4 mt-0">
                                                                <p className="m-1 my-auto">{this.state.jal_users[key]["admin"]?"🟢":"⭕️"}</p>
                                                            </Col>
                                                        </Row>
                                                        {this.state.open_enter_balance == key?<>
                                                            <Card className="card-stats">
                                                                <CardBody>
                                                                    <Row className="my-3">
                                                                        <Col lg="6" md="6" sm="12" className="h5 my-auto text-center">Add Amount <span className="float-right">:</span></Col>
                                                                        <Col lg="6" md="6" sm="12" className="h5 my-auto">
                                                                            <input type="number" disabled={this.state.open_enter_balance != key} className='form-control square col-sm-10 col-lg-10 col-10 m-2' onChange={(e) => {(e.target.value<0)?this.setState({add_balance_amnt: 0}):this.setState({add_balance_amnt: parseInt(e.target.value)})}} value={this.state.add_balance_amnt} placeholder="0" />
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                            <Row>
                                                                <Col lg="6" md="8" sm="10" className="mx-auto"><button className="btn btn-lg col-sm-12 col-lg-12 col-12 btn-green" onClick={() => {this.addBalanceAmount()}}>Submit</button></Col>
                                                                <Col lg="6" md="8" sm="10" className="mx-auto"><button disabled={this.state.user_control_spinner} className="btn btn-lg col-sm-12 col-lg-12 col-12 btn-danger" onClick={() => {this.setState({open_enter_balance: 0, add_balance_amnt: 0})}}>Cancel</button></Col>
                                                            </Row>
                                                        </>:<>
                                                            {this.state.open_user_categories == key?<>
                                                                <Card className="card-stats border-success">
                                                                    <Row>
                                                                        <Col lg="6" md="8" sm="10" className="h3 mt-2 mx-auto">User's Categories</Col>
                                                                    </Row>
                                                                    {Object.keys(this.state.user_categories_data).map(cat_id => <>
                                                                        <Row>
                                                                            <Col lg="5" md="5" sm="5" className="h4 my-auto mx-auto">{this.state.user_categories_data[cat_id]["category"]}<span className="float-right">:</span></Col>
                                                                            <Col lg="5" md="5" sm="5" className="h4 my-auto mx-auto">
                                                                                <button disabled={this.state.user_control_spinner} className="btn btn-lg col-sm-12 col-lg-12 col-12 btn-outline-green"  title={`Click to ${this.state.user_categories_data[cat_id]["user_active"]?"Deactivate":"Activate"}`} onClick={() => {let user_cats = {...this.state.user_categories_data}; user_cats[cat_id]["user_active"] = !user_cats[cat_id]["user_active"]; this.setState({user_categories_data: user_cats, user_categories_data_changed: true})}}>{this.state.user_categories_data[cat_id]["user_active"]?"🟢":"⭕️"} (Click to {this.state.user_categories_data[cat_id]["user_active"]?"Deactivate":"Activate"})</button>
                                                                            </Col>
                                                                        </Row>
                                                                    </>)}
                                                                </Card>
                                                                <Row>
                                                                    <Col lg="6" md="8" sm="10" className="mx-auto"><button disabled={this.state.user_control_spinner || !this.state.user_categories_data_changed} className="btn btn-lg col-sm-12 col-lg-12 col-12 btn-green" onClick={() => {this.saveUserCategories(key)}}>Save Changes</button></Col>
                                                                    <Col lg="6" md="8" sm="10" className="mx-auto"><button disabled={this.state.user_control_spinner || !this.state.user_categories_data_changed} className="btn btn-lg col-sm-12 col-lg-12 col-12 btn-primary" onClick={() => {this.getUsersCategories(key)}}>Reset Changes</button></Col>
                                                                    <Col lg="6" md="8" sm="10" className="mx-auto"><button disabled={this.state.user_control_spinner} className="btn btn-lg col-sm-12 col-lg-12 col-12 btn-danger" onClick={() => {this.setState({open_user_categories: 0})}}>Back</button></Col>
                                                                </Row>
                                                            </>:<>
                                                                {this.state.open_user_access_controls == key?<>
                                                                    <Row>
                                                                        <Col lg="5" md="5" sm="5" className="h4 mt-0 ml-auto">Join Access<span className="float-right">:</span></Col>
                                                                        <Col lg="5" md="5" sm="5" className="h4 mt-0">
                                                                            <p className="m-1 my-auto">{this.state.jal_users[key]["join_access"]?"🟢":"⭕️"}</p>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col lg="5" md="5" sm="5" className="h4 mt-0 ml-auto">Leave Access<span className="float-right">:</span></Col>
                                                                        <Col lg="5" md="5" sm="5" className="h4 mt-0">
                                                                            <p className="m-1 my-auto">{this.state.jal_users[key]["leave_access"]?"🟢":"⭕️"}</p>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col lg="5" md="5" sm="5" className="h4 mt-0 ml-auto">Report Access<span className="float-right">:</span></Col>
                                                                        <Col lg="5" md="5" sm="5" className="h4 mt-0">
                                                                            <p className="m-1 my-auto">{this.state.jal_users[key]["report_access"]?"🟢":"⭕️"}</p>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col lg="6" md="8" sm="10" className="mx-auto"><button disabled={this.state.user_control_spinner} className={`btn btn-lg col-sm-12 col-lg-12 col-12 btn-${this.state.jal_users[key]["active"]?"info":"success"}`} onClick={() => {this.toggleUserActive(key)}}>Make User {this.state.jal_users[key]["active"]?"Inactive 🔴":"Active 🟢"}</button></Col>
                                                                        <Col lg="6" md="8" sm="10" className="mx-auto"><button disabled={this.state.user_control_spinner} className={`btn btn-lg col-sm-12 col-lg-12 col-12 btn-${this.state.jal_users[key]["join_access"]?"info":"success"}`} onClick={() => {this.toggleAccess(key, "join")}}>{this.state.jal_users[key]["join_access"]?"🔴 Disable":"🟢 Enable"} Join Acces</button></Col>
                                                                        <Col lg="6" md="8" sm="10" className="mx-auto"><button disabled={this.state.user_control_spinner} className={`btn btn-lg col-sm-12 col-lg-12 col-12 btn-${this.state.jal_users[key]["leave_access"]?"info":"success"}`} onClick={() => {this.toggleAccess(key, "leave")}}>{this.state.jal_users[key]["leave_access"]?"🔴 Disable":"🟢 Enable"} Leave Acces</button></Col>
                                                                        <Col lg="6" md="8" sm="10" className="mx-auto"><button disabled={this.state.user_control_spinner} className={`btn btn-lg col-sm-12 col-lg-12 col-12 btn-${this.state.jal_users[key]["report_access"]?"info":"success"}`} onClick={() => {this.toggleAccess(key, "report")}}>{this.state.jal_users[key]["report_access"]?"🔴 Disable":"🟢 Enable"} Report Access</button></Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col lg="6" md="8" sm="10" className="mx-auto"><button disabled={this.state.user_control_spinner} className="btn btn-lg col-sm-12 col-lg-12 col-12 btn-danger" onClick={() => {this.setState({open_user_access_controls: 0})}}>Back</button></Col>
                                                                    </Row>
                                                                </>:<>
                                                                    <Row>
                                                                        <Col lg="8" md="8" sm="10" className="mx-auto"><button disabled={this.state.user_control_spinner} className="btn btn-lg col-sm-12 col-lg-12 col-12 btn-success" onClick={() => {this.openAPIKey(key)}}>API Key</button></Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col lg="6" md="8" sm="10" className="mx-auto"><button disabled={this.state.user_control_spinner} className="btn btn-lg col-sm-12 col-lg-12 col-12 btn-green" onClick={() => {this.setState({open_enter_balance: key, add_balance_amnt: 0})}}>Add Amount</button></Col>
                                                                        <Col lg="6" md="8" sm="10" className="mx-auto"><button disabled={this.state.user_control_spinner} className="btn btn-lg col-sm-12 col-lg-12 col-12 btn-green" onClick={() => {this.setState({open_user_access_controls: key})}}>Edit Access</button></Col>
                                                                        <Col lg="6" md="8" sm="10" className="mx-auto"><button disabled={this.state.user_control_spinner} className="btn btn-lg col-sm-12 col-lg-12 col-12 btn-green" onClick={() => {this.state.user_categories_user_id==key?console.log("The Transaction Data is already There..."):this.getUsersCategories(key); this.setState({open_user_categories: key})}}>User's Categories</button></Col>
                                                                        <Col lg="6" md="8" sm="10" className="mx-auto"><button disabled={this.state.user_control_spinner || (this.state.open_transaction == key)} className="btn btn-lg col-sm-12 col-lg-12 col-12 btn-primary" onClick={() => {this.state.transaction_user==key?console.log("The Transaction Data is already There..."):this.getUserTransaction(key); this.setState({open_transaction: key})}}>Transactions</button></Col>
                                                                    </Row>
                                                                </>}
                                                            </>}
                                                        </>}
                                                        
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </>)}
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <Row className="col-sm-12 col-md-10 col-lg-6 mx-auto">
                                        <Col className="my-auto mx-auto text-center">
                                            <button className="btn btn-outline-green h3 my-auto" disabled={this.state.user_control_spinner} onClick={() => {if(this.state.page_no_user_panel!=0){this.setState({page_no_user_panel: 0})}}} style={{cursor: "pointer"}}>⏮</button>
                                        </Col>
                                        <Col className="my-auto mx-auto text-center">
                                            <button className="btn btn-outline-green h3 my-auto" disabled={this.state.user_control_spinner} onClick={() => {if(this.state.page_no_user_panel!=0){this.setState({page_no_user_panel: this.state.page_no_user_panel-1})}}} style={{cursor: "pointer"}}>⬅️</button>
                                        </Col>
                                        <Col className="my-auto mx-auto text-center">
                                            <p className="h3 my-auto">{this.state.page_no_user_panel+1} / {Math.ceil(Object.keys(this.state.jal_users).length/this.state.PGLIMIT)}</p>
                                        </Col>
                                        <Col className="my-auto mx-auto text-center">
                                            <button className="btn btn-outline-green h3 my-auto" disabled={this.state.user_control_spinner} onClick={() => {let last_pg = Math.ceil(Object.keys(this.state.jal_users).length/this.state.PGLIMIT)-1; if(this.state.page_no_user_panel!=(last_pg)){this.setState({page_no_user_panel: this.state.page_no_user_panel+1})}}} style={{cursor: "pointer"}}>➡️</button>
                                        </Col>
                                        <Col className="my-auto mx-auto text-center">
                                            <button className="btn btn-outline-green h3 my-auto" disabled={this.state.user_control_spinner} onClick={() => {let last_pg = Math.ceil(Object.keys(this.state.jal_users).length/this.state.PGLIMIT)-1; if(this.state.page_no_user_panel!=(last_pg)){this.setState({page_no_user_panel: last_pg})}}} style={{cursor: "pointer"}}>⏭</button>
                                        </Col>
                                    </Row>
                                    <Row className="col-sm-12 col-md-8 col-lg-4 mx-auto">
                                        <Col lg="8" md="8" sm="8" className="h4 mt-0 text-center">Users Per Page<span className="float-right">:</span></Col>
                                        <Col lg="4" md="4" sm="4" className="h4 mt-0">
                                            <select type="number" className='form-control square col-sm-10 col-lg-10 col-10 m-1' disabled={this.state.user_control_spinner} onChange={(e) => {this.setState({PGLIMIT: parseInt(e.target.value)}, () => {let last_pg = Math.ceil(Object.keys(this.state.jal_users).length/this.state.PGLIMIT)-1; if(this.state.page_no_user_panel>last_pg){this.setState({page_no_user_panel: last_pg})}}) }} value={this.state.PGLIMIT} placeholder={this.state.PGLIMIT} >
                                                {new Array(21).fill("", 6, 13).map((p, i) => <>
                                                    <option value={i} >{i} Users</option>
                                                </>)}
                                            </select>
                                        </Col>
                                    </Row>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>

                    {this.state.open_transaction != 0?<>
                        <Row>
                            <Col className="mx-auto" lg="12" md="12" sm="12" >
                                <Card className="card-stats border-info">
                                    <CardHeader>
                                        <CardTitle>
                                            <Row className="mb-3">
                                                <Col className="m-auto" lg="2" md="2" sm="2">
                                                    <button onClick={() => {this.getUserTransaction(this.state.open_transaction)}} disabled={this.state.user_transaction_spinner} className="btn btn-outline-green col-sm-12 col-lg-12 col-12 float-left"><i className={`fa fa-refresh ${this.state.user_transaction_spinner?'icon-spin':''} `}></i> Reload Transactions </button>
                                                </Col>
                                                <Col className="m-auto text-center" lg="8" md="8" sm="8">
                                                    <p className="h3 my-auto p-3">{this.state.jal_users[this.state.open_transaction]["username"]}'s Transaction History</p>
                                                </Col>
                                                <Col className="m-auto" lg="1" md="1" sm="1">
                                                    <button onClick={() => this.setState({open_transaction: 0})} className="btn btn-outline-red float-right">❌</button>
                                                </Col>
                                            </Row>
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody className="p-3 my-0" style={{display: 'block', maxWidth: '100%', width: '100%', height: 'auto', opacity: 1, transition: 'opacity 1000ms linear'}}>
                                        {this.state.user_transaction_spinner?<div id="loader"></div>:<></>}
                                        <JALLambdaChannelTable rows={this.state.transaction_data} />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </>:<></>}
                </>:<></>}

                {this.state.open_minimal_left_group?<>
                    {this.state.minimal_left_group_loading?<div id="loader"></div>:<></>}
                    <Row>
                        <Col className="mx-auto" lg="12" md="12" sm="12" >
                            <Card className="card-stats">
                                <CardHeader>
                                    <CardTitle>
                                        <Row className="mb-3">
                                            <Col className="m-auto" lg="1" md="1" sm="1">
                                                <button onClick={this.get_minimal_left_groups} className="btn btn-outline-red float-right">♻️</button>
                                            </Col>
                                            <Col className="m-auto text-center" lg="10" md="10" sm="10">
                                                <p className="h3 my-auto">Minimal Left Groups</p>
                                            </Col>
                                            <Col className="m-auto" lg="1" md="1" sm="1">
                                                <button onClick={() => this.setState({open_minimal_left_group: false})} className="btn btn-outline-red float-right">❌</button>
                                            </Col>
                                        </Row>
                                    </CardTitle>
                                </CardHeader>
                                <CardBody className="p-3 my-0" style={{display: 'block', maxWidth: '100%', width: '100%', height: 'auto', opacity: 1, transition: 'opacity 1000ms linear'}}>
                                    {(this.state.minimal_left_group.length > 0)?<>
                                        <JsonTable data={this.state.minimal_left_group} />
                                    </>:<></>}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mx-auto" lg="12" md="12" sm="12" >
                            <Card className="card-stats">
                                <CardHeader>
                                    <CardTitle>
                                        <Row className="mb-3">
                                            <Col className="m-auto text-center" lg="10" md="10" sm="10">
                                                <p className="h3 my-auto">New Minimal Left Group</p>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col lg="5" md="5" sm="5" className="h5 mt-0 ml-auto">New Channel ID<span className="float-right">:</span></Col>
                                            <Col lg="5" md="5" sm="5" className="h5 mt-0">
                                                <input type="number" disabled={this.state.new_minimal_left_group_loading} className='form-control square col-sm-10 col-lg-10 col-10 m-1' onChange={(e) => {this.setState({new_min_left_group_id: e.target.value})}} value={this.state.new_min_left_group_id} placeholder={this.state.new_min_left_group_id} />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col lg="5" md="5" sm="5" className="h5 mt-0 ml-auto">New Channel Link<span className="float-right">:</span></Col>
                                            <Col lg="5" md="5" sm="5" className="h5 mt-0">
                                                <input type="text" disabled={this.state.new_minimal_left_group_loading} className='form-control square col-sm-10 col-lg-10 col-10 m-1' onChange={(e) => {this.setState({new_min_left_group_link: e.target.value})}} value={this.state.new_min_left_group_link} placeholder={this.state.new_min_left_group_link} />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col className="my-auto mx-auto text-center">
                                                <button className="btn btn-outline-green my-auto" onClick={this.add_minimum_left_group} disabled={this.state.new_minimal_left_group_loading || (!this.state.new_min_left_group_id && !this.state.new_min_left_group_link)} style={{cursor: "pointer"}}>Add Link</button>
                                            </Col>
                                        </Row>
                                    </CardTitle>
                                </CardHeader>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mx-auto" lg="12" md="12" sm="12" >
                            <Card className="card-stats">
                                <CardHeader>
                                    <CardTitle>
                                        <Row className="mb-3">
                                            <Col className="m-auto text-center" lg="10" md="10" sm="10">
                                                <p className="h3 my-auto">Delete Minimal Left Group</p>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col lg="5" md="5" sm="5" className="h5 mt-0 ml-auto">Select Channel ID<span className="float-right">:</span></Col>
                                            <Col lg="5" md="5" sm="5" className="h5 mt-0">
                                                <select className='form-control col-sm-12 col-lg-12 col-12 my-2 m-1' onChange={(e) => {this.setState({delete_min_left_group_id: e.target.value})}} disabled={(this.state.edit_category) || (!this.state.categories) || (this.state.open_auto_leave)}>
                                                    <option value={0} >Select Channel</option>
                                                    {Object.keys(this.state.minimal_left_group).map( key => <option value={this.state.minimal_left_group[key]["channel_id"]} >{this.state.minimal_left_group[key]["target_url"]}</option>)}
                                                </select>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col className="my-auto mx-auto text-center">
                                                <button className="btn btn-outline-red my-auto" onClick={this.delete_minimum_left_group} disabled={this.state.minimal_left_group_loading || !this.state.delete_min_left_group_id} style={{cursor: "pointer"}}>Remove Link</button>
                                            </Col>
                                        </Row>
                                    </CardTitle>
                                </CardHeader>
                            </Card>
                        </Col>
                    </Row>
                </>:<></>}
            </div>
        </>);
    }
}
const mapStateToProps = state => ({
    auth: state.auth
});
export default connect(mapStateToProps)(JALLambdaAdmin);

