
import axios from "axios.js";
import React from "react";
import { connect } from "react-redux";
import 'react-dropzone-uploader/dist/styles.css'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import AdderForm from 'views/Adder_start_form.js'

// react plugin used to create charts
import { Line, Pie } from "react-chartjs-2";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
// core components
import {
  dashboard24HoursPerformanceChart,
  dashboardEmailStatisticsChart,
  dashboardNASDAQChart,
} from "variables/charts.js";
import Master_table from "./master_table.js"
import JsonTable from "views/Simple_table.js";

class Dashboard extends React.Component {
  constructor(){
    super()
    
    var chart
    this.state={
      data:{session_count: {
        muted_sessions: 0,
        dead_last_7_days: 0,
        json_session: 0,
         Ready_use: 0,
         currently_active: 0,
        }},
        
      table_summary:[
        {'loading':'loading'},
      ],
      adder_data:[]
    }

    am4core.useTheme(am4themes_dark);
  am4core.useTheme(am4themes_animated);
  }
  componentDidMount=()=>{
    console.log('-----------',this.props.auth)
    this.get_session_counts()
    this.get_adder_summary()
    this.get_adder_data()

    // this.interval1 = setInterval(() => this.get_adder_data(), 5000);


    // ###charts

    
}
componentWillUnmount() {
  // clearInterval(this.interval1);

}
  get_session_counts=()=>{

    axios.get(this.props.auth.BaseUrl+'Master_sessions/session_counts',this.props.auth.config).then(
        (e)=>{
          console.log(e.data)
            this.setState({
              data:e.data
            },function() { console.log("setState completed", this.state)})
        }
    )
  }
  get_adder_data=()=>{

    axios.get(this.props.auth.BaseUrl+'Adder/adder_dashboard',this.props.auth.config).then(
        (e)=>{
          this.setState({
            adder_data:[]
          },function(){
            this.setState({adder_data:e.data }) 
          })

        }
    )
  }
  get_adder_summary=()=>{

    axios.get(this.props.auth.BaseUrl+'Master_sessions/summary',this.props.auth.config).then(
        (e)=>{

          if (typeof e.data.datas != "undefined" && e.data.datas != null && e.data.datas.length != null
          && e.data.datas.length > 0){

          
            this.setState({
              table_summary:e.data.datas

            },function() { 
              
              var chart = am4core.create("chartdiv", am4charts.XYChart);
              chart.padding(40, 40, 40, 40);

              var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
              categoryAxis.renderer.grid.template.location = 0;
              categoryAxis.dataFields.category = "target_group";
              categoryAxis.renderer.minGridDistance = 1;
              categoryAxis.renderer.inversed = true;
              categoryAxis.renderer.grid.template.disabled = true;

              var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
              valueAxis.min = 0;

              var series = chart.series.push(new am4charts.ColumnSeries());
              series.dataFields.categoryY = "target_group";
              series.dataFields.valueX = "live_user";
              series.tooltipText = "{valueX.value}"
              series.columns.template.strokeOpacity = 0;
              series.columns.template.column.cornerRadiusBottomRight = 5;
              series.columns.template.column.cornerRadiusTopRight = 5;

              var labelBullet = series.bullets.push(new am4charts.LabelBullet())
              labelBullet.label.horizontalCenter = "left";
              labelBullet.label.dx = 10;
              labelBullet.label.text = "{values.valueX.workingValue.formatNumber('#.0as')}";
              labelBullet.locationX = 1;

              // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
              series.columns.template.adapter.add("fill", function(fill, target){
                return chart.colors.getIndex(target.dataItem.index);
              });

              // categoryAxis.sortBySeries = series;
              chart.data=this.state.table_summary

              chart.exporting.menu = new am4core.ExportMenu();
              chart.exporting.extraSprites.push({

                  "position": "bottom",
                  "marginTop": 2
              });
              // Set cell size in pixels
              let cellSize = 30;
              chart.events.on("datavalidated", function(ev) {

                // Get objects of interest
                let chart = ev.target;
                let categoryAxis = chart.yAxes.getIndex(0);

                // Calculate how we need to adjust chart height
                let adjustHeight = chart.data.length * cellSize - categoryAxis.pixelHeight;

                // get current chart height
                let targetHeight = chart.pixelHeight + adjustHeight;

                // Set it on chart's container
                chart.svgContainer.htmlElement.style.height = targetHeight + "px";
              });


            })
          }
          
        }
    )
  }


  get_export=()=>{
    axios({
      url: this.props.auth.BaseUrl+'Master_sessions/export_master',config:this.props.auth.config,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      console.log(response)
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
    });

  }
  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col lg="3" md="6" sm="6" >
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="3" xs="3">
                      <div className="icon-big text-center icon-warning">
                      <a><img className="join-joined-icon" /></a>
                      </div>
                    </Col>
                    <Col md="9" xs="9">
                      <div className="numbers">
                        <p className="card-category">Ready to Use JSON/CSV </p>
                        <CardTitle className="tle-joinleave" tag="p">{this.state.data.session_count.Ready_use }</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                
              </Card>
            </Col>
            
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="3" xs="3">
                      <div className="icon-big text-center icon-warning">
                      <a><img className="join-settings-icon" /></a>
                      </div>
                    </Col>
                    <Col md="9" xs="9">
                      <div className="numbers">
                        <p className="card-category">Muted Session</p>
                        <CardTitle className="tle-joinleave" tag="p">{this.state.data.session_count.muted_sessions }</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
               
              </Card>
            </Col>

            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="3" xs="3">
                      <div className="icon-big text-center icon-warning">
                      <a><img className="join-dead-icon" /></a>
                      </div>
                    </Col>
                    <Col md="9" xs="9">
                      <div className="numbers">
                        <p className="card-category">Alive JSON/CSV Session</p>
                        <CardTitle className="tle-joinleave" tag="p">{this.state.data.session_count.json_session }</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="3" xs="3">
                      <div className="icon-big text-center icon-warning">
                      <a><img className="join-dead-icon" /></a>
                      </div>
                    </Col>
                    <Col md="9" xs="9">
                      <div className="numbers">
                        <p className="card-category">Dead in last 7 days</p>
                        <CardTitle className="tle-joinleave" tag="p">{this.state.data.session_count.dead_last_7_days }</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
               
              </Card>
            </Col>
           
          </Row>
          
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h5">Adder Report</CardTitle>
                  <p className="card-category">All Users</p>
                </CardHeader>
                <CardBody>
                  {/* <button onClick={this.get_export}>Export backend</button> */}
                  <button className='btn btn-small btn-green' onClick={this.get_adder_summary}> <i className='fa fa-recycle'></i> Refresh data</button>
                  <JsonTable data={this.state.table_summary} />
                  <div id="chartdiv" style={{height:"800px",width:'100%'}}></div>
                  <button className='btn btn-sm  btn-outline-warning' onClick={this.get_adder_data}> <i className='fa fa-refresh'></i> Refresh adder</button>
                  <Row>
                  {this.state.adder_data.map((value, i) =>{
                        return(

                          <AdderForm data={value} refresh={this.get_adder_data}/>
                             
                        )

                      })      
                    }    

                  </Row>
                      {/* {this.props.auth.user.username=='san'?<Master_table config={this.props.auth.config}/>:''} */}
                      
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-history" /> Updated 3 minutes ago
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          <Row>


          </Row>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps)(Dashboard);

