import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import axios from "axios.js";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "assets/scss/paper-dashboard.scss?v=1.2.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "assets/fonts/font-awesome/css/font-awesome.css"
import "assets/css/paper-dashboard.css"
import "assets/css/bootstrap-extended.css";
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'assets/css/borderless.scss';
import "assets/css/colors.css";
import "assets/css/custom.css";

// axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
// axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.withCredentials = true;

ReactDOM.render(<App />, document.getElementById("root"));
