import {
  USER_LOADED,
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  REGISTER_SUCCESS,
  REGISTER_FAIL
} from "../actions/types";
 


const initialState = {
  isAuthenticated: null,
  token: localStorage.getItem("token"),
  user: null,
  group:null,
  isLoading: false,
  BaseUrl:`${process.env.REACT_APP_API_URL}/`,
  JALBaseUrl:`${process.env.REACT_APP_JAL_API_URL}/`,
  FJALBaseUrl:`${process.env.REACT_APP_FJAL_API_URL}/`,
  config:{}

};

export default function(state = initialState, action) {
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case USER_LOADED:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        user: action.payload,
        group: action.payload.group,
        config:{
          headers: {
          "Content-Type": "application/json",
          "Authorization":`JWT ${localStorage.getItem("token")}`,
          "Cookie":`Token=${localStorage.getItem("token")}`
        },
      }
      };
    case LOGIN_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        isAuthenticated: true,
        config:{
          headers: {
          "Content-Type": "application/json",
          "Authorization":`JWT ${localStorage.getItem("token")}`},
          "Cookie":`Token=${localStorage.getItem("token")}`
      }
    };
    case LOGOUT:
      return {
        ...state,
        isLoading: false,
        user: null,
        isAuthenticated: false,
        token: null,
        config:{}
      };
    case REGISTER_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        user: action.payload,
        group: action.payload.group,
        token: action.payload.token,
        config:{
          headers: {
          "Content-Type": "application/json",
          "Authorization":`JWT ${localStorage.getItem("token")}`},
          "Cookie":`Token=${localStorage.getItem("token")}`
      }
      };
    case AUTH_ERROR:
    case LOGIN_FAIL:
      return {
        ...state,
        isLoading: false,
        user: null,
        group:null,
        isAuthenticated: false,
        token: null,
        config:{}
      };
    case REGISTER_FAIL:
      localStorage.removeItem("token");
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        group:null,
        token: null,
        user: null,
        config:{}

      };
    default:
      return state;
  }
}
