
import axios from "axios.js";
import React from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
// import Dataviz from "@amcharts/amcharts5/themes/Dataviz";
// import * as am5map from "@amcharts/amcharts5/map";
// import am5themes_Micro from "@amcharts/amcharts5/themes/Micro";
import { connect } from "react-redux";
import 'react-dropzone-uploader/dist/styles.css'
// import AdderForm from 'views/Adder_start_form.js'
// import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
import Swal from 'sweetalert2'


// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
// core components
import {
  dashboard24HoursPerformanceChart,
  dashboardEmailStatisticsChart,
  dashboardNASDAQChart,
} from "variables/charts.js";
import Master_table from "./master_table.js"
import JsonTable from "views/Simple_table.js";

class Dashboard extends React.Component {
  constructor(){
    super()
    
    var chart
    this.state={
      data:{session_count: {
        muted_sessions: 0,
        json_session: 0,
         Ready_use: 0,
         currently_active: 0,
        }},
        summary_data:{
          "cost_wise": [
              
          ],
          "overall_count": {
              "dead": 639,
              "alive": 4039
          },
          "checked_unchecked_count": {
              "unchecked": 2708,
              "checked": 1970
          },
          "age_data": [
              
          ],
          "exported_count": {
              "exported": 'Loading..',
              "ready_to_export": 'Loading..'
          },
          "cost_total": {
              "dead_cost": 0,
              "export_cost": null,
              "alive_cost": 0
          },
          token_used:[]
      },
        
      cost_wise:[
        {'loading':'loading'},
      ],
      country_wise:[
        {'loading':'loading'},
      ],
      exported_count:'Loading..',
      checked_unchecked_count:[
        {'checked':0},
        {'unchecked':0},
        {'waiting':0}
      ],
      overall_count:{'dead': 'Loading...', 'alive': 'Loading...'},
      age_count:[{'day': '1', 'count': 0}, {'day': '2', 'count': 0}, {'day': '3', 'count': 0}, {'day': '4', 'count': 0}, {'day': '5', 'count': 0}, {'day': '+6', 'count': 0}],
      adder_data:[],
      
    }


  }
  componentDidMount=()=>{
    console.log('-----------',this.props.auth)

    this.get_reg_summary()


    // this.interval1 = setInterval(() => this.get_adder_data(), 5000);


    // ###charts

    
}
componentWillUnmount() {
  
  // clearInterval(this.interval1);

}

  get_reg_summary=()=>{
    
    axios.get(this.props.auth.BaseUrl+'Reg/get_session_data',this.props.auth.config).then(
        (e)=>{

          if (true){
            if (this.state.create_age_count_chart_root){
              this.state.create_cost_wise_chart_root.dispose()
              this.state.create_country_wise_chart_root.dispose()
              this.state.create_overall_count_chart_root.dispose()
              this.state.create_checker_count_chart_root.dispose()
              this.state.create_age_count_chart_root.dispose()
              this.state.token_wise_graph_root.dispose()
            }

          
            this.setState({
              summary_data:e.data,
              cost_wise:e.data.cost_wise,
              country_wise:e.data.country_wise,
              exported_count:e.data.exported_count.exported,
              overall_count:e.data.overall_count,
              checked_unchecked_count:e.data.checked_unchecked_count,
              age_count:e.data.age_data

            },function() {
              this.create_cost_wise_chart()
              this.create_country_wise_chart()
              this.create_overall_count_chart()
              this.create_checker_count_chart()
              this.create_age_count_chart()
              this.token_wise_graph()

            }
    )
  }})}
  create_cost_wise_chart=()=>{

  // Create root element
  // https://www.amcharts.com/docs/v5/getting-started/#Root_element
  var root = am5.Root.new("cost_wise_chart");
  this.setState({
    create_cost_wise_chart_root:root
  })
  
  
  // Set themes
  // https://www.amcharts.com/docs/v5/concepts/themes/
  root.setThemes([
    am5themes_Animated.new(root),
    am5themes_Dark.new(root)
  ]);
  
  
  // Create chart
  // https://www.amcharts.com/docs/v5/charts/xy-chart/
  var chart = root.container.children.push(am5xy.XYChart.new(root, {
    panX: false,
    panY: false,
    // wheelX: "panX",
    // wheelY: "zoomX",
    layout: root.verticalLayout
  }));
  chart.get("colors").set("colors", [
    am5.color('#39AEA9'),
    am5.color('#F56D91')
  ]);
  
  
  
  
  
  // Data
  var data =this.state.cost_wise;
  
  
  // Create axes
  // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
  var yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
    categoryField: "cost",
    
    renderer: am5xy.AxisRendererY.new(root, {
      inversed: true,
      cellStartLocation: 0.1,
      cellEndLocation: 0.9
    })
  }));
  
  yAxis.data.setAll(data);
  
  var xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
    
    renderer: am5xy.AxisRendererX.new(root, {}),
    min: 0
  }));
  
  
  // Add series
  // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
  function createSeries(field, name) {
    var series = chart.series.push(am5xy.ColumnSeries.new(root, {
      name: name,
      xAxis: xAxis,
      yAxis: yAxis,
      valueXField: field,
      categoryYField: "cost",
      sequencedInterpolation: true,
      tooltip: am5.Tooltip.new(root, {
        pointerOrientation: "horizontal",
        labelText: "[bold]{name}[/]\n{categoryY}: {valueX}"
      })
    }));
  
    series.columns.template.setAll({
      height: am5.p100
    });
  
  
    series.bullets.push(function() {
      return am5.Bullet.new(root, {
        locationX: 1,
        locationY: 0.5,
        sprite: am5.Label.new(root, {
          centerY: am5.p50,
          text: "{valueX}",
          populateText: true
        })
      });
    });
  
    // series.bullets.push(function() {
    //   return am5.Bullet.new(root, {
    //     locationX: 1,
    //     locationY: 0.5,
    //     sprite: am5.Label.new(root, {
    //       centerX: am5.p100,
    //       centerY: am5.p50,
    //       text: "{name}",
    //       fill: am5.color(0xffffff),
    //       populateText: true
    //     })
    //   });
    // });
  
    series.data.setAll(data);
    series.appear();
  
    return series;
  }
  
  createSeries("alive", "Alive");
  createSeries("dead", "Dead");
  
  
  // Add legend
  // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
  var legend = chart.children.push(am5.Legend.new(root, {
    centerX: am5.p50,
    x: am5.p50,
    layout:root.horizontalLayout
  }));
  
  legend.data.setAll(chart.series.values);
  
  
  // Add cursor
  // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
  var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {

  }));
  cursor.lineY.set("forceHidden", true);
  cursor.lineX.set("forceHidden", true);

  
  // Make stuff animate on load
  // https://www.amcharts.com/docs/v5/concepts/animations/
  chart.appear(1000, 100);

  
  }
  create_country_wise_chart=()=>{

    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    var root = am5.Root.new("country_wise_chart");
    this.setState({
      create_country_wise_chart_root:root
    })
    
    
    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([
      am5themes_Animated.new(root),
      am5themes_Dark.new(root)
    ]);
    
    
    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      // wheelX: "panX",
      // wheelY: "zoomX",
      layout: root.verticalLayout
    }));
    chart.get("colors").set("colors", [
      am5.color('#39AEA9'),
      am5.color('#F56D91')
    ]);
    
    
    
    
    
    // Data
    var data =this.state.country_wise;
    
    
    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    var yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: "phone_country",
      
      renderer: am5xy.AxisRendererY.new(root, {
        inversed: true,
        cellStartLocation: 0.1,
        cellEndLocation: 0.9
      })
    }));
    
    yAxis.data.setAll(data);
    
    var xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
      
      renderer: am5xy.AxisRendererX.new(root, {}),
      min: 0
    }));
    
    
    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    function createSeries(field, name) {
      var series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: name,
        xAxis: xAxis,
        yAxis: yAxis,
        valueXField: field,
        categoryYField: "phone_country",
        sequencedInterpolation: true,
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: "horizontal",
          labelText: "[bold]{name}[/]\n{categoryY}: {valueX}"
        })
      }));
    
      series.columns.template.setAll({
        height: am5.p100
      });
    
    
      series.bullets.push(function() {
        return am5.Bullet.new(root, {
          locationX: 1,
          locationY: 0.5,
          sprite: am5.Label.new(root, {
            centerY: am5.p50,
            text: "{valueX}",
            populateText: true
          })
        });
      });
    
      // series.bullets.push(function() {
      //   return am5.Bullet.new(root, {
      //     locationX: 1,
      //     locationY: 0.5,
      //     sprite: am5.Label.new(root, {
      //       centerX: am5.p100,
      //       centerY: am5.p50,
      //       text: "{name}",
      //       fill: am5.color(0xffffff),
      //       populateText: true
      //     })
      //   });
      // });
    
      series.data.setAll(data);
      series.appear();
    
      return series;
    }
    
    createSeries("alive", "Alive");
    createSeries("dead", "Dead");
    
    
    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    var legend = chart.children.push(am5.Legend.new(root, {
      centerX: am5.p50,
      x: am5.p50,
      layout:root.horizontalLayout
    }));
    
    legend.data.setAll(chart.series.values);
    
    
    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
  
    }));
    cursor.lineY.set("forceHidden", true);
    cursor.lineX.set("forceHidden", true);
  
    
    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 100);
  
    
    }
  create_checker_count_chart=()=>{

    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    var root = am5.Root.new("checker_count_chart");
    this.setState({
      create_checker_count_chart_root:root
    })
    
    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([
      am5themes_Animated.new(root),
      am5themes_Dark.new(root)
    ]);
    
    
    // Create chart
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
    var chart = root.container.children.push(am5percent.PieChart.new(root, {
      layout: root.verticalLayout,

      paddingLeft: 120,
      paddingRight: 100,


     
    }));

    
    // Create series
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
    var series = chart.series.push(am5percent.PieSeries.new(root, {
      alignLabels: true,
      calculateAggregates: true,
      valueField: "value",
      categoryField: "category",
      legendLabelText: "{category}",
      legendValueText: "{value}"
    }));
    
    // series.slices.template.setAll({
    //   strokeWidth: 3,
    //   stroke: am5.color(0xffffff)
    // });
    
    // series.labelsContainer.set("paddingTop", 30)
    
    
    // Set up adapters for variable slice radius
    // https://www.amcharts.com/docs/v5/concepts/settings/adapters/
    // series.slices.template.adapters.add("radius", function (radius, target) {
    //   var dataItem = target.dataItem;
    //   var high = series.getPrivate("valueHigh");
    
    //   if (dataItem) {
    //     var value = target.dataItem.get("valueWorking", 0);
    //     return radius * value / high
    //   }
    //   return radius;
    // });
    
    
    // Set data
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
    series.data.setAll([
      { value: this.state.checked_unchecked_count.checked, category: "Checked" },
      { value: this.state.checked_unchecked_count.unchecked, category: "UnChecked" },
      { value: this.state.checked_unchecked_count.Waiting, category: "Waiting" },
    ]);

    
    
    // Create legend
    // https://www.amcharts.com/docs/v5/charts/percent-charts/legend-percent-series/
    var legend = chart.children.push(am5.Legend.new(root, {
      centerX: am5.p50,
      x: am5.p50,
      marginTop: 15,
      marginBottom: 15,
      
      
    }));
    
    legend.data.setAll(series.dataItems);
    
    
    // Play initial series animation
    // https://www.amcharts.com/docs/v5/concepts/animations/#Animation_of_series
    series.appear(1000, 100);
    chart.appear(1000, 100);
    
    }
  create_overall_count_chart=()=>{

    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    var root = am5.Root.new("overall_count_chart");
    this.setState({
      create_overall_count_chart_root:root
    })
    
    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([
      am5themes_Animated.new(root),
      am5themes_Dark.new(root)
    ]);
    
    
    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      // wheelX: "panX",
      // wheelY: "zoomX",
      pinchZoomX:false
    }));
    
    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
    cursor.lineY.set("visible", false);
    
    
    
    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    var xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
    xRenderer.labels.template.setAll({
      rotation: -90,
      centerY: am5.p50,
      centerX: am5.p100,
      paddingRight: 15
    });
    
    var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
      maxDeviation: 0.3,
      extraMax:0.2,
      categoryField: "category",
      renderer: xRenderer,
      
      tooltip: am5.Tooltip.new(root, {})
    }));
    
    var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      maxDeviation: 0.3,
      extraMax:0.2,
      
      renderer: am5xy.AxisRendererY.new(root, {})
    }));
    
    
    // Create series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    var series = chart.series.push(am5xy.ColumnSeries.new(root, {
      name: "Over All Status",
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "value",
      sequencedInterpolation: true,
      categoryXField: "category",
      showTooltipOn: "always",
      tooltip: am5.Tooltip.new(root, {
        labelText:"{valueY}",
        
      })
    }));
    
    series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5});
    series.columns.template.adapters.add("fill", function(fill, target) {
      return chart.get("colors").getIndex(series.columns.indexOf(target));
    });
    
    series.columns.template.adapters.add("stroke", function(stroke, target) {
      return chart.get("colors").getIndex(series.columns.indexOf(target));
    });
    series.columns.template.setAll({
      tooltipText: "{name}, {categoryX}:{valueY}",
      width: am5.percent(50),
      tooltipY: 0,
      tooltipText: "{categoryX}: {valueY}",
      showTooltipOn: "always"
    });
    series.columns.template.setup = function(target) {
      target.set("tooltip", am5.Tooltip.new(root, {}));
    }
    chart.get("colors").set("colors", [
      am5.color('#39AEA9'),
      am5.color('#F56D91')
    ]);
    
    
    // Set data
    var data = [
                {"category":"alive",'value': this.state.overall_count.alive },
                {"category":"dead",'value': this.state.overall_count.dead }
              ]
    
    xAxis.data.setAll(data);
    series.data.setAll(data);
    
    
    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series.appear(1000);
    chart.appear(1000, 100);
    
    }

    create_age_count_chart=()=>{

      // Create root element
      // https://www.amcharts.com/docs/v5/getting-started/#Root_element
      var root = am5.Root.new("age_count_chart");
      this.setState({
        create_age_count_chart_root:root
      })
      
      // Set themes
      // https://www.amcharts.com/docs/v5/concepts/themes/
      root.setThemes([
        am5themes_Animated.new(root),
        am5themes_Dark.new(root)
      ]);
      
      
      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      var chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        // wheelX: "panX",
        // wheelY: "zoomX",
        pinchZoomX:false
      }));
      
      // Add cursor
      // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
      var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
      cursor.lineY.set("visible", true);
      cursor.lineX.set("visible", true);
      
      
      
      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      var xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
      xRenderer.labels.template.setAll({

        centerY: am5.p50,
        centerX: am5.p50,

      });
      
      var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        maxDeviation: 0.3,
        categoryField: "day",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {})
      }));
      
      var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        maxDeviation: 0.3,
        extraMax:0.2,

        renderer: am5xy.AxisRendererY.new(root, {})
      }));
      
      
      // Create series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      var series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: "Age Status in Day(s)",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "alive",
        sequencedInterpolation: true,
        categoryXField: "day",

        tooltip: am5.Tooltip.new(root, {
          labelText:"{valueY} Session,{valueX} days old",
          
        })
      }));
      
      series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5});
      series.columns.template.adapters.add("fill", function(fill, target) {
        return chart.get("colors").getIndex(series.columns.indexOf(target));
      });
      
      series.columns.template.adapters.add("stroke", function(stroke, target) {
        return chart.get("colors").getIndex(series.columns.indexOf(target));
      });
      series.columns.template.setAll({

        width: am5.percent(50),
        tooltipY: 0,
        tooltipText: "{categoryX} Day(s): {valueY}",
        // showTooltipOn: "always"
      });
      series.columns.template.setup = function(target) {
        target.set("tooltip", am5.Tooltip.new(root, {}));
      }
      chart.get("colors").set("colors", [
        am5.color('#EEF2FF'),
        am5.color('#CDDEFF'),
        am5.color('#676FA3'),
        am5.color('#78938A'),
        am5.color('#6EBF8B'),
        am5.color('#FF5959'),

      ]);
      
      
      // Set data
      var data = this.state.age_count
      
      xAxis.data.setAll(data);
      series.data.setAll(data);

      
      
      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      series.appear(1000);
      chart.appear(1000, 100);
      
      }

      token_wise_graph=()=>{

        // Create root element
        // https://www.amcharts.com/docs/v5/getting-started/#Root_element
        var root = am5.Root.new("token_wise_graph");
        this.setState({
          token_wise_graph_root:root
        })
        
        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([
          am5themes_Animated.new(root),
          am5themes_Dark.new(root),

        ]);
        
        
        // Create chart
        // https://www.amcharts.com/docs/v5/charts/xy-chart/
        var chart = root.container.children.push(am5xy.XYChart.new(root, {
          scrollX: true,
          panY: true,
          
        }));
        chart.set("scrollbarX", am5.Scrollbar.new(root, {
          orientation: "horizontal"
        }));
        
        
        // Add cursor
        // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
        var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
        cursor.lineY.set("visible", false);
        
        
        // Create axes
        // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
        var xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
        xRenderer.labels.template.setAll({
          rotation: -90,
          centerY: am5.p50,
          centerX: am5.p100,
          paddingRight: 15,
        });
        
        var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
          maxDeviation: 0.3,
          categoryField: "slug",
          renderer: xRenderer,
          tooltip: am5.Tooltip.new(root, {})
        }));
        
        var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
          maxDeviation: 0.3,
          renderer: am5xy.AxisRendererY.new(root, {})
        }));
        
        
        // Create series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
        var series = chart.series.push(am5xy.ColumnSeries.new(root, {
          name: "Token",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "Success_reg",
          sequencedInterpolation: true,
          categoryXField: "slug",
          showTooltipOn: "always",
          tooltip: am5.Tooltip.new(root, {
            labelText:"{valueY}",
            
          })
        }));
        
        series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5 });
        series.columns.template.adapters.add("fill", function(fill, target) {
          return chart.get("colors").getIndex(series.columns.indexOf(target));
        });
        
        series.columns.template.adapters.add("stroke", function(stroke, target) {
          return chart.get("colors").getIndex(series.columns.indexOf(target));
        });
        
        series.columns.template.setAll({
          tooltipText: "{valueY}",
          width: am5.percent(50),
          tooltipY: 0,
          showTooltipOn: "always"
        });
        series.columns.template.setup = function(target) {
          target.set("tooltip", am5.Tooltip.new(root, {}));
        }
        // Set data
        var data = this.state.summary_data.token_used;
        
        xAxis.data.setAll(data);
        series.data.setAll(data);
        
        
        
        // Make stuff animate on load
        // // https://www.amcharts.com/docs/v5/concepts/animations/
        // series.appear(1000);
        // chart.appear(1000, 100);
        
        }
      download_db=()=>{
        

        
        Swal.fire({
          title: 'Multiple inputs',
          html:`
            <label>Session Count</label>
            <input required type='number' id="reg-input1" class="form-control square">
            <label>Password to download Session</label>
            <input required type='password' id="reg-input2" class="form-control square">
            `,
          focusConfirm: true,
          showCancelButton: true,
          confirmButtonText: 'Get Session',
          preConfirm: () => {
            if (document.getElementById('reg-input1').value && document.getElementById('reg-input2').value){
              return {
                'count':document.getElementById('reg-input1').value,
                'password':document.getElementById('reg-input2').value
              }
            }
            else{
              Swal.showValidationMessage(
                `Count and Password are Required Field`
              )

            }
            
          }
        }).then((result) => {
          Swal.fire({title:"Making Request..", icon:'info'})
          Swal.showLoading()
          if (result.isDismissed){
            Swal.fire('Canceled', 'No Request sent', 'error')
            return 
          }
          if (result.value) {
            
            axios({
              url: this.props.auth.BaseUrl+`Reg/export_session_db?password=${result.value.password}&count=${result.value.count}`,
              headers:this.props.auth.config.headers,
              method: 'GET',
              data:{'password':result.value},
              responseType: 'blob', // important
            }).then((response) => {
              
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              console.log(response.headers['content-type'].split(';')[1])
              link.setAttribute('download', response.headers['content-type'].split(';')[1]); //or any other extension
              document.body.appendChild(link);
              link.click();
              Swal.fire({
                title: `Downloaded`,
                icon:'success'
              })
            }).catch(
              function (error) {
                console.log(error)
                Swal.fire({title:"Password is wrong,Can't get the session",closeButton:'ok',timer:3000, icon:'error',timerProgressBar:true})
                  
              }
          );


          }
          else{
            Swal.fire('No Password Found', 'Please add Password to get the session', 'error')

          }
        })
          
        
        
        
    
      }

  render() {
    return (
      <>
        <div className="content">
          <Row>
          <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="3" xs="3">
                      <div className="icon-big text-center icon-warning">
                        <i className="fa fa-hourglass-1"></i>
                      </div>
                      <br/>
                      <div className="icon-big text-center icon-warning">
                        <i className="fa fa-hourglass-end"></i>
                      </div>
                    </Col>
                    <Col md="9" xs="9">
                      <div className="numbers">
                        <p className="card-category">Alive Session</p>
                        <CardTitle className="tle-joinleave" tag="p">{this.state.overall_count.alive }</CardTitle>
                        <p className="card-category">Dead Session</p>
                        <CardTitle className="tle-joinleave" tag="p">{this.state.overall_count.dead }</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="3" xs="3">
                      <div className="icon-big text-center icon-warning">
                      <i className="fa fa-suitcase" />
                      </div>
                      <br/>
                      <div className="icon-big text-center icon-warning">
                        <i className="fa fa-plane" />
                      </div>
                    </Col>
                    <Col md="9" xs="9">
                      <div className="numbers">
                        <p className="card-category">Ready To Export</p>
                        <CardTitle className="tle-joinleave" tag="p">{this.state.summary_data.exported_count.ready_to_export }</CardTitle>
                        <p className="card-category">Exported</p>
                        <CardTitle className="tle-joinleave" tag="p">{this.state.summary_data.exported_count.exported }</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="3" xs="3">
                      <div className="icon-big mt-5 text-center icon-warning">
                        <i className="fa fa-money" />
                        </div>
                        
                    </Col>
                    <Col md="9" xs="9">
                      <div className="numbers">
                        <p className="card-category">Alive Session</p>
                        <CardTitle className="tle-joinleave font-medium-4" tag="p">{this.state.summary_data.cost_total.alive_cost |'-' }₽ <span className='font-small-3'>({Math.round(this.state.summary_data.cost_total.alive_cost * 0.015)}$)</span> </CardTitle>
                        <p className="card-category">Dead Session</p>
                        <CardTitle className="tle-joinleave font-medium-4" tag="p">{this.state.summary_data.cost_total.dead_cost | '-'}₽ <span className='font-small-3'>({Math.round(this.state.summary_data.cost_total.dead_cost * 0.015)}$)</span> </CardTitle>
                        <p className="card-category">Exported Sessions</p>
                        <CardTitle className="tle-joinleave font-medium-4" tag="p">{this.state.summary_data.cost_total.export_cost | '-'}₽ <span className='font-small-3'>({Math.round(this.state.summary_data.cost_total.export_cost * 0.015) }$)</span> </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              
                  <Row>
                    
                    <Col md="9" xs="9">
                      <br/>
                      
                      <button onClick={()=>this.download_db()}  className='btn btn-white black   w-100 '> <i className='fa fa-download'></i> Download Session</button>

                      <button className='btn btn-white black w-100' onClick={this.get_reg_summary}> <i className='fa fa-recycle'></i> Refresh data</button>
                      <br/>
                      <span className="font-medium-1"> <i className="fa fa-arrow-up"></i> REFRESH MANUALLY</span>

                    </Col>
                  </Row>
                
            </Col>
            <Col lg="3" md="6" sm="6" >
              <Card className="card-stats">
                <CardHeader>
                
                <CardTitle tag="h5">Over-All Summary</CardTitle>
                  <p className="card-category">All Tokens</p>
                    </CardHeader>
                <CardBody>
                <div id="overall_count_chart" style={{height:"300px",width:'100%'}}></div>
                </CardBody>
                
              </Card>
            </Col>
            <Col lg="4" md="6" sm="6" >
              <Card className="card-stats">
                <CardHeader>
                <CardTitle tag="h5">Age Summary in Day(s) </CardTitle>
                  <p className="card-category">Alive Session vs Days</p>
                    </CardHeader>
                <CardBody>
                <div id="age_count_chart" style={{height:"300px",width:'100%'}}></div>
                </CardBody>
                
              </Card>
            </Col>
            
            <Col lg="5" md="6" sm="6" >
              <Card className="card-stats">
                <CardHeader>
                <CardTitle tag="h5">Checker Status</CardTitle>
                  <p className="card-category">Excluding the dead sessions</p>
                    </CardHeader>
                <CardBody>
                <div id="checker_count_chart" style={{height:"300px",width:'100%'}}></div>
                </CardBody>
                
              </Card>
            </Col>

           
          </Row>
          
          <Row>
            <Col md="6">
              <Card>
                <CardHeader>
                  <CardTitle tag="h5">Cost vs Count</CardTitle>
                  <p className="card-category">All Tokens</p>
                </CardHeader>
                <CardBody>
                  <div id="cost_wise_chart" style={{height:"800px",width:'100%'}}></div>
                  

                  <Row>
                  

                  </Row>
                      {/* {this.props.auth.user.username=='san'?<Master_table config={this.props.auth.config}/>:''} */}
                      
                </CardBody>

              </Card>
            </Col>
            <Col md="6">
              <Card>
                <CardHeader>
                  <CardTitle tag="h5">Country vs Count</CardTitle>
                  <p className="card-category">All Tokens</p>
                </CardHeader>
                <CardBody>
                  <div id="country_wise_chart" style={{height:"800px",width:'100%'}}></div>
                  

                  <Row>
                  

                  </Row>
                      {/* {this.props.auth.user.username=='san'?<Master_table config={this.props.auth.config}/>:''} */}
                      
                </CardBody>
                
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h5">Token Wise Last 7 Days</CardTitle>
                  <p className="card-category">All Tokens</p>
                </CardHeader>
                <CardBody>
                  <div id="token_wise_graph" style={{height:"800px",width:'100%'}}></div>
                  

                  <Row>
                  

                  </Row>
                      {/* {this.props.auth.user.username=='san'?<Master_table config={this.props.auth.config}/>:''} */}
                      
                </CardBody>
                <CardFooter>
                  <hr />

                </CardFooter>
              </Card>
            </Col>
          </Row>
          <Row>


          </Row>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps)(Dashboard);

